/**
 * Defines the survey questions
 * @author mtownsend
 * @since August 16, 2021
 * @flow
 **/

import React from "react";

export type QuestionType = "frequency" | "amplitude";

export type Category = "depression" | "anxiety" | "impairment";

export type Question = {|
  category: Category,
  categoryData?: CategoryData,
  type: QuestionType,
  // text: string,
  // shortText: string,
  emergency?: number,
  starIndicator: Boolean,
  showBackButton: Boolean,
  showNextButton: Boolean,
  showFinishButton: Boolean,
|};

export type CategoryData = {|
  label: string,
  type: QuestionType,
|};

export const Categories: Map<Category, CategoryData> = new Map([
  ["depression", { label: "depressionsymptoms", type: "frequency" }],
  ["anxiety", { label: "anxietySymptoms", type: "frequency" }],
  ["impairment", { label: "personalAndSocialImpairment", type: "amplitude" }],
]);

const Questions: Array<Question> = [
  // Depression
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: false,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText: "I feel little interest or pleaseure in doing things",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText: "I feel down, depressed, or hopeless?",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText: "I couldn’t fall asleep, or I slept too much.",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText: "I feel tired, or have little energy",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText: "I didn’t want to eat, or I overate",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText:
      "I feel bad about myself — I am a failure or have let my family down",
  },

  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText:
      "I couldn’t concentrate on things, such as reading the paper or watching TV",
  },
  {
    category: "depression",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "depression_symptoms",
    ga_questionText:
      "I moved or spoke so slowly that other people could tell; or I was so restless that I was moving around a lot",
  },
  {
    category: "depression",
    type: "frequency",
    emergency: 3,
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: true,
    ga_section: "depression_symptoms",
    ga_questionText:
      "I thought that I would be better off dead or of hurting myself in some way",
  },

  // Anxiety
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: true,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I feel nervous, anxious, or on edge",
  },

  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I could not stop worrying",
  },
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I worried about different things",
  },
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I could not relax",
  },
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I was so restless that it was hard to sit still",
  },
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I was easily annoyed and irritatable",
  },
  {
    category: "anxiety",
    type: "frequency",
    starIndicator: false,
    showBackButton: true,
    showNextButton: false,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "anxiety_symptoms",
    ga_questionText: "I was afraid as if something awful might happen",
  },

  // Impairment
  {
    category: "impairment",
    type: "amplitude",
    starIndicator: true,
    showBackButton: true,
    showNextButton: true,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "personal_social_impairment",
    ga_questionText: "my ability to work is impaired",
  },
  {
    category: "impairment",
    type: "amplitude",
    starIndicator: false,
    showBackButton: true,
    showNextButton: true,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "personal_social_impairment",
    ga_questionText:
      "my home cleaning, tidying, shopping, cooking, looking after children, paying bills is impaired.",
  },
  {
    category: "impairment",
    type: "amplitude",
    starIndicator: false,
    showBackButton: true,
    showNextButton: true,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "personal_social_impairment",
    ga_questionText:
      "my social leisure activities with other people are impaired.",
  },
  {
    category: "impairment",
    type: "amplitude",
    starIndicator: false,
    showBackButton: true,
    showNextButton: true,
    showFinishButton: false,
    checkEmergencyBanner: false,
    ga_section: "personal_social_impairment",
    ga_questionText:
      "my private leisure activities  (such as reading, gardening, collecting, sewing, walking alone) are impaired.",
  },
  {
    category: "impairment",
    type: "amplitude",
    starIndicator: false,
    showBackButton: true,
    showNextButton: true,
    showFinishButton: true,
    checkEmergencyBanner: false,
    ga_section: "personal_social_impairment",
    ga_questionText:
      "my ability to have close relationships with others, including family, is impaired",
  },
].map((q: Question) => {
  q.categoryData = Categories.get(q.category);
  return q;
});

export default Questions;
