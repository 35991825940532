const RiskLevelOptionArray = {
  1: [
    { component: "ThoughfullPsychiatristOption" },
    { component: "ThoughfullPsychologistOption" },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },
  ],
  2: [
    {
      component: "ThoughfullPsychiatristOption",
    },
    { component: "ThoughfullPsychologistOption" },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },
  ],
  3: [
    {
      component: "ThoughfullPsychologistOption",
    },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },

  ],
  4: [
    {
      component: "ThoughfullPsychologistOption",
    },
    {
      component: "ThoughfullPsychiatristOption",
    },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },

  ],
  5: [
    {
      component: "SelfCareOption",
    },
    { component: "ThoughfullCoachOption" },
    {
      component: "ThoughfullPsychologistOption",
    },

  ],
  6: [
    {
      component: "SelfCareOption",
    },
    {
      component: "ThoughfullPsychologistOption",
    },
    { component: "ThoughfullCoachOption" },

  ],
};

export default RiskLevelOptionArray;
