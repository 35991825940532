const BackButtonSvgComponent = ({ fill = "#E87722" }) => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.353 16.5609L10.707 16.2074L4.4145 9.91537L19 9.91538L19 7.91537L4.4135 7.91537L10.7065 1.62187L10.353 1.26887C9.7675 0.682874 8.8175 0.682874 8.2315 1.26887L0.586001 8.91487L8.232 16.5609C8.8175 17.1464 9.7675 17.1464 10.353 16.5609Z"
        fill={fill}
      />
    </svg>
  );
};

export default BackButtonSvgComponent;
