import React, { useState } from 'react';

import PopupContainer from '../../layout/PopupContainer';
import PolicyValidationForm from './PolicyValidationForm';
import NewCustomerLeadForm from './NewCustomerLeadForm';
import { redeemFlowTypes } from '../../../constant/redeem';

const popupTypes = {
  policy: 'policy',
  lead: 'lead',
  resultValidationSuccess: 'resultValidationSuccess',
  resultTrial: 'resultTrial'
};

const initialPopupTypes = {
  [redeemFlowTypes.policyValidationOnly]: popupTypes.policy,
  [redeemFlowTypes.policyValidationOrTrialWithLead]: popupTypes.policy,
  [redeemFlowTypes.policyValidationOrTrialWithoutLead]: popupTypes.policy,
  [redeemFlowTypes.trialWithLeadOnly]: popupTypes.lead,
  [redeemFlowTypes.directShow]: popupTypes.resultTrial,
};

const RedeemFlowHandlerPopup = ({ isOpen, onClose, redeemFlowType = redeemFlowTypes.policyValidationOrTrialWithLead, renderResultViewValidationSuccess = (clientId, clientInfo) => null, renderResultViewTrial = () => null, onValidationSuccess = () => { }, providedBySection }) => {
  const initialPopupViewBasedOnRedeemFlowType = initialPopupTypes[redeemFlowType];
  const [showingPopupType, setShowingPopupType] = useState(initialPopupViewBasedOnRedeemFlowType);
  const [policyHolderClientId, setPolicyHolderClientId] = useState(null);
  const [policyHolderClientInfo, setPolicyHolderClientInfo] = useState(null);

  // When closed, always reset to initial page state
  if (!isOpen && showingPopupType !== initialPopupViewBasedOnRedeemFlowType) {
    setShowingPopupType(initialPopupViewBasedOnRedeemFlowType);
    setPolicyHolderClientId(null);
    setPolicyHolderClientInfo(null);
  }

  const onGoToPolicyValidation = () => {
    setShowingPopupType(popupTypes.policy);
  };

  const onGoToLeadForm = () => {
    setShowingPopupType(popupTypes.lead);
  };

  const onGoToResultValidationSuccess = ({ encryptedCustomerId, clientInfoObj, idNumber, dob }) => {
    setPolicyHolderClientId(encryptedCustomerId);
    setPolicyHolderClientInfo(clientInfoObj);
    onValidationSuccess({ encryptedCustomerId, clientInfoObj, idNumber, dob });
    setShowingPopupType(popupTypes.resultValidationSuccess);
  };

  const onGoToResultTrial = () => {
    setShowingPopupType(popupTypes.resultTrial);
  };

  // After close (isOpen=false), enforce component unmount to ensure clearing previous state
  // So that next time user is opening a fresh new popup again
  if (isOpen && redeemFlowType === redeemFlowTypes.policyValidationOnly) {
    return (
      <>
        <PopupContainer isOpen={showingPopupType === popupTypes.policy} onClickClose={onClose} >
          <PolicyValidationForm onSubmitSuccess={onGoToResultValidationSuccess} />
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultValidationSuccess} onClickClose={onClose} >
          {renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
        </PopupContainer>
      </>
    );
  }

  if (isOpen && redeemFlowType === redeemFlowTypes.policyValidationOrTrialWithLead) {
    return (
      <>
        <PopupContainer isOpen={showingPopupType === popupTypes.policy} onClickClose={onClose} >
          <PolicyValidationForm onSubmitSuccess={onGoToResultValidationSuccess} onClickIAmNewCustomer={onGoToLeadForm} />
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.lead} onClickClose={onClose} >
          <NewCustomerLeadForm onGoBack={onGoToPolicyValidation} onSubmitSuccess={onGoToResultTrial} providedBySection={providedBySection} />
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultValidationSuccess} onClickClose={onClose} >
          {renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultTrial} onClickClose={onClose} >
          {renderResultViewTrial()}
        </PopupContainer>
      </>
    );
  }

  if (isOpen && redeemFlowType === redeemFlowTypes.policyValidationOrTrialWithoutLead) {
    return (
      <>
        <PopupContainer isOpen={showingPopupType === popupTypes.policy} onClickClose={onClose} >
          <PolicyValidationForm onSubmitSuccess={onGoToResultValidationSuccess} onClickIAmNewCustomer={onGoToResultTrial} />
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultValidationSuccess} onClickClose={onClose} >
          {renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultTrial} onClickClose={onClose} >
          {renderResultViewTrial()}
        </PopupContainer>
      </>
    );
  }

  if (isOpen && redeemFlowType === redeemFlowTypes.trialWithLeadOnly) {
    return (
      <>
        <PopupContainer isOpen={showingPopupType === popupTypes.lead} onClickClose={onClose} >
          <NewCustomerLeadForm onSubmitSuccess={onGoToResultTrial} providedBySection={providedBySection} />
        </PopupContainer>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultTrial} onClickClose={onClose} >
          {renderResultViewTrial()}
        </PopupContainer>
      </>
    );
  }

  if (isOpen && redeemFlowType === redeemFlowTypes.directShow) {
    return (
      <>
        <PopupContainer isOpen={showingPopupType === popupTypes.resultTrial} onClickClose={onClose} >
          {renderResultViewTrial()}
        </PopupContainer>
      </>
    );
  }

  return null;
};

export default RedeemFlowHandlerPopup;
