import React from "react";
import { withStyles } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";
import { Button } from "@material-ui/core";
import copyIconSrc from '../../../assets/general/copy-white-2x.png';

const CopyTextButton = ({ classes, text, isShortText, buttonTitle, onClickButton }) => (
  <div className={classes.container}>
    <div className={classes.textContainer}>
      <div className={isShortText ? classes.shortText : classes.longText}>{text}</div>
    </div>
    <div className={classes.buttonContainer}>
      <Button className={classes.button} onClick={onClickButton}>
        <img className={classes.buttonIcon} src={copyIconSrc}></img>
        <div className={classes.buttonText}>{buttonTitle}</div>
      </Button>
    </div>
  </div>
);

const CopyTextButtonStyled = withStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: {
    height: 44,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.fwdOrangeLighter5,
    border: `1px dashed ${COLORS.fwdOrange}`,
    borderRight: 0,
    borderRadius: '4px 0px 0px 4px',
  },
  shortText: {
    fontWeight: 700,
    letterSpacing: 4,
  },
  longText: {
  },
  buttonContainer: {
  },
  button: {
    background: COLORS.fwdOrange,
    color: COLORS.white,
    height: 44,
    width: 120,
    borderRadius: '0px 4px 4px 0px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: COLORS.fwdOrange,
    },
    "&:disabled": {
      background: COLORS.fwdOrange20,
      color: COLORS.white,
    },
    "&.MuiButtonBase-root": {
      textTransform: 'unset',
    },
  },
  buttonIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
  },
})(CopyTextButton);

export default CopyTextButtonStyled;
