import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { COLORS } from "../../../constant/colors";
import SectionHeader, { sectionHeaderSizes, sectionHeaderAlignDirections } from "../../components/core-elements/SectionHeader";
import FormTextField from "../../components/core-elements/FormTextField";
import DatePicker from "../../components/core-elements/DatePicker";
import Button from "../../components/core-elements/Button";
import Spacer from "../../components/core-elements/Spacer";
import Divider from "../../components/core-elements/Divider";
import AppIcon from "../../components/core-elements/AppIcon";
import ActionIcon from "../../components/core-elements/ActionIcon";
import ClickableText from "../../components/core-elements/ClickableText";
import tfIconSrc from "../../../assets/thoughtfull/TF_Trademark_Stacked.png";
import { momentDateToBUDateStringUtils, momentDateToYearString } from "../../../util/datetime";
import env from "../../../env";
import { getClientPolicy } from "../../../api/godp";
import { fontSizes } from "../../../constant/font";
import { triggerGAOnClickGetFreeTrialInValidationPopup, triggerGAOnSuccessfulValidation, triggerGAOnClickNonCustomerSkipNow } from "../../../util/ga";
import ReactHTMLParser from "react-html-parser";
import clsx from "clsx";
import AcknowledgeCheckBox from "../../components/core-elements/AcknowledgeCheckBox";
import OTPValidationForm from "./OTPValidationForm";
import SendOTPErrorPopup from "./SendOTPErrorPopup";
import { color, fontWeight, lineHeight, textAlign } from "@material-ui/system";
import HtmlParser from "react-html-parser";

const dobInitialDate = new Date(1990, 0, 1);

const PolicyValidationForm = ({
  classes,
  onGoBack,
  onSubmitSuccess,
  onClickIAmNewCustomer,
  isFullPage = false,
  isEmbedComponent = false,
  componentName = "",
  isValidationBeforeResultPage = false,
  onClickSkipStep,
  skipButtonAlignLeft = false,
  title,
  caption,
  remark,
  displayConsentInValidation = false,
  newCustomerTitle,
  newCustomerActionLabel,
  idDocumentRemarks = false,
  redeemPageDisplayTFIcon = true
}) => {
  const [idNumber, setIdNumber] = useState("");
  const [dob, setDob] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedTnc1, setIsCheckedTnc1] = useState(false);
  const [isDisplayOTP, setIsDisplayOTP] = useState(false);
  const [clientInfoObject, setClientInfoObject] = useState(null);
  const [showOTPSendingError, setShowOTPSendingError] = useState(false);
  const { t, i18n } = useTranslation();
  const newCustomerActionIsHidden = process.env.REACT_APP_NEW_CUSTOMER_ACTION_IS_HIDDEN == 'true';

  const onSubmit = async () => {
    setIsLoading(true);
    const momentDate = moment(dob);
    const dobStringBuFormat = momentDateToBUDateStringUtils[env.currentBu](momentDate);

    try {
      const res = await getClientPolicy(idNumber, dobStringBuFormat);
      const clientInfoObj = res;
      if (clientInfoObj.code === 500) {
        //OTP sending error
        throw new Error("err500");
      } else if (!clientInfoObj.hasPolicy || !clientInfoObj.clientId) {
        throw new Error("Invalid customer or missing valid policy");
      }
      triggerGAOnSuccessfulValidation({ language: i18n.language, yearOfBirth: momentDateToYearString(momentDate) });
      console.log(env);
      setClientInfoObject(clientInfoObj);

      if (env.isOTPRequiredForValidation && clientInfoObj.mobileNumber) {
        setPhoneNumber(clientInfoObj.mobileNumber);
        setIsDisplayOTP(true);
        setIsLoading(false);
      } else {
        onSubmitSuccess({ encryptedCustomerId: clientInfoObj.clientId, clientInfoObj, idNumber, dob });
      }
    } catch (err) {
      if (err.message == "err500") {
        setShowOTPSendingError(true);
        setIsLoading(false);
      } else {
        setErrorMessage(t(`eTriageLanding.validation.${env.currentBu}.errorMsg`));
        setIsLoading(false);
      }
    }
  };

  const _onClickIAmNewCustomer = () => {
    triggerGAOnClickGetFreeTrialInValidationPopup({ language: i18n.language });
    onClickIAmNewCustomer();
  };

  const _onClickSkipStep = () => {
    triggerGAOnClickNonCustomerSkipNow({ language: i18n.language });
    onClickSkipStep();
  };

  const renderValidationForm = () => {
    console.log('newCustomerActionIsHidden =', newCustomerActionIsHidden);
    console.log('process.env.REACT_APP_NEW_CUSTOMER_ACTION_IS_HIDDEN =', process.env.REACT_APP_NEW_CUSTOMER_ACTION_IS_HIDDEN);
    return (
      <>
        {!!onGoBack && !isFullPage && !isEmbedComponent && (
          <div className={classes.backIconContainer}>
            <ActionIcon type="arrowLeft" onClick={onGoBack} />
          </div>
        )}
        <SectionHeader
          text={title ?? t("eTriageLanding.validation.pleaseValidateYourPolicyFirst")}
          size={isFullPage ? sectionHeaderSizes.large : isEmbedComponent ? sectionHeaderSizes.embedComponentTitle : sectionHeaderSizes.medium}
          alignDirection={isFullPage ? sectionHeaderAlignDirections.center : sectionHeaderAlignDirections.left}
          embedComponentTitle={true}
          onGoBack={!!(isFullPage || isEmbedComponent) && onGoBack}
        />
        {!!caption && <div className={clsx(classes.caption, isFullPage && classes.fullPageCaption)}> {HtmlParser(caption)}</div>}
        <div className={classes.formContainer}>
          <Spacer y={24} />
          <FormTextField
            title={ReactHTMLParser(t(`eTriageLanding.validation.${env.currentBu}.idDocument`))}
            value={idNumber}
            onValueChange={(value) => {
              setErrorMessage("");
              setIdNumber(value);
            }}
            isError={!!errorMessage}
          />
          {idDocumentRemarks && (<div className={classes.idRemarks}>{t(`eTriageLanding.validation.${env.currentBu}.idDocumentRemarks`)}</div>)}
          <Spacer y={16} />
          <DatePicker
            label={t("eTriageLanding.validation.dateOfBirth")}
            locale={i18n.language}
            value={dob}
            onChange={(value) => {
              setErrorMessage("");
              setDob(value);
            }}
            isError={!!errorMessage}
            errorMessage={errorMessage != "" && ReactHTMLParser(errorMessage)}
            initialFocusedDate={dobInitialDate}
          />
          <Spacer y={24} />

          {!!displayConsentInValidation && (
            <>
              <AcknowledgeCheckBox
                isChecked={isCheckedTnc1}
                onCheckUncheck={setIsCheckedTnc1}
                description={t(`eTriageLanding.validation.${env.currentBu}.consent`)}
              />
              <Spacer y={16} />
            </>
          )}

          <Button
            title={isValidationBeforeResultPage ? t("eTriageLanding.validation.seeMyResult") : t("eTriageLanding.validation.go")}
            isDisabled={isLoading || (!!displayConsentInValidation && !isCheckedTnc1)}
            onClick={onSubmit}
          />
          {!!onClickIAmNewCustomer && (
            <>
              <Spacer y={24} />
              <Divider />
              <Spacer y={24} />
              <div className={classes.newCustomerContainer}>
                {redeemPageDisplayTFIcon && (
                  <div>
                    <AppIcon imageSrc={tfIconSrc} />
                  </div>
                )}
                {
                  !newCustomerActionIsHidden && (
                      <div className={classes.newCustomerTextContainer}>
                  <div className={classes.newCustomerTitle}>{newCustomerTitle ? HtmlParser(newCustomerTitle) : t("eTriageLanding.validation.newCustomer")}</div>
                  <Spacer y={6} />
                  <ClickableText
                    text={newCustomerActionLabel ? newCustomerActionLabel : t("eTriageLanding.validation.getAFreeTrail")}
                    isShowArrow={true}
                    onClick={_onClickIAmNewCustomer}
                  />
                </div>
                  )
                }
              
              </div>
            </>
          )}
          {isValidationBeforeResultPage && !newCustomerActionIsHidden && (
            <>
              <Spacer y={24} />
              <Divider />
              <Spacer y={24} />
              <div className={classes.newCustomerContainer}>
                <div className={clsx(classes.newCustomerTextContainer, (skipButtonAlignLeft ? classes.alignLeft : classes.alignCenter))}>
                  <ClickableText
                    text={ReactHTMLParser(t(`eTriageLanding.validation.${env.currentBu}.notCustomerSkipNow`))}
                    isShowArrow={false}
                    onClick={_onClickSkipStep}
                    isShowChevronRight
                    isCenter
                  />
                </div>
              </div>
            </>
          )}

          {!!remark && <div className={classes.remark}> {ReactHTMLParser(remark)}</div>}
        </div>
      </>
    );
  };
  return (
    <div className={classes.container}>
      {isDisplayOTP ? (
        <OTPValidationForm
          isEmbedComponent={isEmbedComponent}
          isFullPage={isFullPage}
          phoneNumber={phoneNumber}
          onSubmitSuccess={() => {
            onSubmitSuccess({ encryptedCustomerId: clientInfoObject.clientId, clientInfoObject, idNumber, dob });
          }}
          onGoBack={() => {
            setIsDisplayOTP(false);
          }}
        />
      ) : showOTPSendingError ? (
        <SendOTPErrorPopup
          onGoBack={() => {
            setShowOTPSendingError(false);
          }}
        />
      ) : (
        renderValidationForm()
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backIconContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 16,
  },
  newCustomerContainer: {
    display: "flex",
    alignItems: "center",
  },
  newCustomerTextContainer: {
    flex: 1,
  },
  newCustomerTitle: {
    fontSize: fontSizes.contentMedium,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdDarkGreen,
  },
  formContainer: {
    width: "100%",
    maxWidth: 355,
  },
  caption: {
    marginTop: 16,
    fontSize: fontSizes.contentSmall,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdDarkGreen,
  },
  fullPageCaption: {
    textAlign: "center",
  },
  remark: {
    marginTop: 24,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "16px",
    color: COLORS.fwdGreyDarkest,
    "& ul": {
      paddingInlineStart: 15,
      marginBlockStart: 5,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineEnd: 0,
      paddingBlockStart: 0,
      paddingBlockEnd: 0,
    },
    "& a": {
      color: COLORS.fwdOrange,
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  alignLeft: {
    textAlign: "left",
  },
  idRemarks: {
    fontSize: 12,
    fontWeight: 450,
    lineHeight: "16px",
    color: COLORS.fwdGreyDarker,
    paddingLeft: 10
  }
};

export default withStyles(styles)(PolicyValidationForm);
