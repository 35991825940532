import React, { useState, useEffect } from "react";

import Button, { buttonTypes } from "./Button";

const TransitionButton = ({ postActionText, postActionResetDurationMs = 5000, onClick, title, ...props}) => {
  const [isPostAction, setIsPostAction] = useState(false);

  useEffect(() => {
    if (isPostAction) {
      const timer = setTimeout(() => setIsPostAction(false), postActionResetDurationMs);
      return () => timer && clearTimeout(timer);
    }
  }, [isPostAction]);

  const onClickStartTransition = () => {
    setIsPostAction(true);
    onClick();
  };

  return (
    <Button onClick={onClickStartTransition} title={isPostAction ? postActionText : title} type={isPostAction ? buttonTypes.filledLight : buttonTypes.filled} {...props} />
  )
}

export default TransitionButton;
