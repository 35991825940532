import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import "moment/locale/zh-hk";
import "moment/locale/th";
import "moment/locale/vi";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import { updateMomentLocaleWithLang } from './util/datetime';

const options = {
  order: [
    "path",
    // "querystring",
    // "cookie",
    // "localStorage",
    // "sessionStorage",

    // "subdomain",
    // "htmlTag",
  ],
  lookupQuerystring: "lng",
};

const handleMissingKey = (lngs, ns, key) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    throw new Error(`Missing i18n key "${key}" at namespace "${ns}" for locale ${lngs}`);
  }
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    ns: ["translation", "resultPageLongText", "landingPageLongText"],
    defaultNS: "translation",
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
    supportedLngs: process.env.REACT_APP_LANG?.split(","),
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    missingKeyHandler: handleMissingKey,
  });

i18n.on('languageChanged', (lng) => {
  console.debug(`Language has changed to ${lng}`);
  document.documentElement.setAttribute('lang', lng);
  updateMomentLocaleWithLang(lng);
})

export default i18n;
