import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as nextgen from "@fwd-dep/nextgen-ui-lib";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  FWDHeaderContainer: {},
}));

function FWDHeaderContainer({ }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  const [params, setParams] = useState();

  useEffect(() => {
    try {
      const paramsFile = require(`../../constant/headerJson/headerJson_${currentBu}_${i18n.language}`);
      setParams(paramsFile["headerJson"]);
    } catch (err) { }
  }, [i18n.language]);

  const [headerState, setHeaderState] = useState({
    headerTransitionStatus: false,
    state: {},
    visible: true,
    logoLoaded: false,
  });

  const setHeaderTransitionStatus = (status) => {
    setHeaderState({
      ...headerState,
      headerTransitionStatus: status,
    });
  };

  const setHeaderVisible = (visible) => {
    setHeaderState({
      ...headerState,
      visible: visible,
    });
  };

  const navigate = (href, isSelf) => {
    if (isSelf) {
      window.open(href, "_self");
    } else {
      window.open(href);
    }
  };

  return (
    <>
      <div className={classes.FWDHeaderContainer}>
        {params && (
          <nextgen.Header
            {...params}
            headerState={headerState}
            setHeaderTransitionStatus={setHeaderTransitionStatus}
            setHeaderVisible={setHeaderVisible}
            notificationExist={false}
            navigate={navigate}
            onLanguageChange={(changeLanguage) => {
              i18next.changeLanguage(changeLanguage, (err, t) => {
                document.title = t(`tabTitle.programme.${currentBu}`) + " | " + t(`tabTitle.${currentBu}`);

                const currentPath = window.location.pathname;
                const firstSectionOfPath = currentPath.split("/")[1] ?? currentPath;
                const supportLang = process.env.REACT_APP_LANG.split(",");
                const newPath = supportLang.indexOf(firstSectionOfPath) >= 0 ? currentPath.replace(firstSectionOfPath, changeLanguage) : "/" + changeLanguage + currentPath;
                window.history.replaceState(null, "", newPath);

              });
            }}
            gtmpushevent={(event) => {
              console.log(event);
            }}
          />
        )}
      </div>
    </>
  );
}

export default FWDHeaderContainer;
