const env = {
  currentBu: process.env.REACT_APP_BU ?? "hk",
  isStorePolicyDate: process.env.REACT_APP_STORE_POLICY_DATA === "true",
  isDisplaySelectLangPopup: process.env.REACT_APP_SELECT_LANG_POPUP === 'true',
  isDisplayValidationPopupBeforeResultPage: process.env.REACT_APP_DISPLAY_VALIDATION_POPUP_BEFORE_RESULT === 'true',
  isDisplayConsentInValidationForm: process.env.REACT_APP_DISPLAY_CONSENT_IN_VALIDATION_FORM === 'true',
  isOTPRequiredForValidation: process.env.REACT_APP_OTP_REQUIRED_FOR_VALIDATION === 'true',
  salesforceNameCode: process.env.REACT_APP_LEAD_FORM_API_SALESFORCE_NAME_CODE ? process.env.REACT_APP_LEAD_FORM_API_SALESFORCE_NAME_CODE.toString() : null,
  salesforceCampaignCode: process.env.REACT_APP_LEAD_FORM_API_SALESFORCE_CAMPAIGN_CODE ? process.env.REACT_APP_LEAD_FORM_API_SALESFORCE_CAMPAIGN_CODE.toString() : null,
  isDisplayCopyResultLinkButton: process.env.REACT_APP_HIDE_DISPLAY_COPY_RESULT_LINK_BUTTON ? process.env.REACT_APP_HIDE_DISPLAY_COPY_RESULT_LINK_BUTTON.toString() !== 'true' : true,
  newCustomerActionIsHidden: process.env.REACT_APP_NEW_CUSTOMER_ACTION_IS_HIDDEN ?process.env.REACT_APP_NEW_CUSTOMER_ACTION_IS_HIDDEN.toString() !== 'true': false,
};


export default env;
