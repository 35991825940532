const RiskLevelOptionArray = {
  1: [
    { component: "DoctorAnywherePsychiatristOption", recommended: false },
    {
      component: "DoctorAnywherePsychologistOption",
      recommended: false,
    },
    { component: "SelfCareOption", recommended: false },
  ],
  2: [
    {
      component: "DoctorAnywherePsychiatristOption",
      recommended: false,
    },
    {
      component: "DoctorAnywherePsychologistOption",
      recommended: false,
    },
    { component: "SelfCareOption", recommended: false },
    { component: "EmergencyOption", recommended: false },
  ],
  3: [
    {
      component: "DoctorAnywherePsychologistOption",
      recommended: false,
    },
    { component: "SelfCareOption", recommended: false },
    {
      component: "SimplifiedPsychiatristOption",
      recommended: false,
      desktopWidth50: true,
    },
    { component: "EmergencyOption", recommended: false, desktopWidth50: true },
  ],
  4: [
    {
      component: "DoctorAnywherePsychologistOption",
      recommended: false,
    },
    {
      component: "SimplifiedPsychiatristOption",
      recommended: false,
      desktopWidth50: false,
    },
    { component: "SelfCareOption", recommended: false },

    { component: "EmergencyOption", recommended: false, desktopWidth50: false },
  ],
  5: [
    // { component: "ChatbotBanner" },
    {
      component: "SelfCareOption",
      recommended: false,
    },
    { component: "DoctorAnywhereCounsellingOption", recommended: false },
    {
      component: "SimplifiedPsychiatristOption",
      recommended: false,
      desktopWidth50: true,
    },
    { component: "EmergencyOption", recommended: false, desktopWidth50: true },
  ],
  6: [
    // { component: "ChatbotBanner" },
    {
      component: "SelfCareOption",
      recommended: false,
    },
    {
      component: "DoctorAnywherePsychologistOption",
      recommended: false,
    },
    {
      component: "SimplifiedPsychiatristOption",
      recommended: false,
      desktopWidth50: true,
    },
    { component: "EmergencyOption", recommended: false, desktopWidth50: true },
  ],
};

export default RiskLevelOptionArray;
