import { Checkbox, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constant/colors";

import OptionsModal from "./components/OptionsModel";
import Questions from "../../constant/etriage/questions";
import QuestionBannerAndLabelSection from "./components/QuestionBannerAndLabelSection";
import DesktopQuestionPagination from "./components/DesktopQuestionPagination";
import DesktopQuestionsBannerWithOptions from "./components/DesktopQuestionsBannerWithOptions";
import TagManager from "react-gtm-module";
import { FrequencyOptions } from "../../constant/etriage/options";
import EmergencyBanner from "./components/EmergencyBanner";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    flex: 1,
    background: COLORS.fwdOrangeLighter5,
    display: "flex",
    flexDirection: "column",
  },
  headerbanner: {
    height: 66,
    width: "100%",
  },
  mobile: {
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktop: {
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  desktopContainer: {
    width: 856,
    display: "flex",
    flexDirection: "column",
  },
  emergencyBanner: {
    background: COLORS.fwdOrange20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

// const EMPTY_SURVEY = Array(Questions.length).fill(null);

function QuestionsPage({ changePage }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showEmergency, setShowEmergency] = useState(false);

  const nextPage = () => {
    if (questionIndex < Questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    } else {
      changePage(answers);
    }

    document
      .getElementById("root")
      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const previousPage = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    }
    document
      .getElementById("root")
      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onAnswerSelected = (questionIndex, answerIndex) => {
    const relatedQuestions = Questions[questionIndex];
    //GA
    const tagEventArgs = {
      dataLayer: {
        event: "e_triage",
        eTriageSection: relatedQuestions.ga_section,
        eTriageQuestionNum: questionIndex + 1,
        eTriageQuestion: relatedQuestions.ga_questionText,
        eTriageAnswer:
          relatedQuestions.ga_section == "personal_social_impairment"
            ? answerIndex
            : FrequencyOptions[answerIndex].icon,
        pageUrl: window.location.href,
        language: i18n.language,
      },
    };

    TagManager.dataLayer(tagEventArgs);
    if (relatedQuestions.checkEmergencyBanner && answerIndex > 0) {
      setShowEmergency(true);
    } else if (relatedQuestions.checkEmergencyBanner && answerIndex == 0) {
      setShowEmergency(false);
    }
    answers[questionIndex] = answerIndex;
    setAnswers([...answers]);
    nextPage();
  };

  return (
    <>
      <div
        component="section"
        className={clsx([classes.pageContainer, classes.mobile])}
      >
        {showEmergency ? (
          <div className={classes.emergencyBanner}>
            <div className={classes.headerbanner}></div>
            <EmergencyBanner
              closeEmergencyBanner={() => {
                setShowEmergency(false);
              }}
            />
          </div>
        ) : (
          <div className={classes.headerbanner}></div>
        )}

        <QuestionBannerAndLabelSection
          questionIndex={questionIndex}
          key={questionIndex}
        />

        <OptionsModal
          questionIndex={questionIndex}
          questionsDetail={Questions[questionIndex]}
          selectedAnswer={answers}
          onAnswerSelect={(answerIndex) => {
            onAnswerSelected(questionIndex, answerIndex);
          }}
          nextPage={() => {
            nextPage();
          }}
          previousPage={() => {
            previousPage();
          }}
        />
      </div>
      <div
        component="section"
        className={clsx([classes.pageContainer, classes.desktop])}
      >
        {showEmergency ? (
          <div className={classes.emergencyBanner}>
            <div className={classes.headerbanner}></div>
            <EmergencyBanner
              closeEmergencyBanner={() => {
                setShowEmergency(false);
              }}
            />
          </div>
        ) : (
          <div className={classes.headerbanner}></div>
        )}

        <div className={classes.desktopContainer}>
          <DesktopQuestionPagination
            questionIndex={questionIndex}
            questionsDetail={Questions[questionIndex]}
            previousPage={() => {
              previousPage();
            }}
            selectedAnswer={answers}
            onAnswerSelect={(answerIndex) => {
              onAnswerSelected(questionIndex, answerIndex);
            }}
          />
          <DesktopQuestionsBannerWithOptions
            questionIndex={questionIndex}
            questionsDetail={Questions[questionIndex]}
            selectedAnswer={answers}
            onAnswerSelect={(answerIndex) => {
              onAnswerSelected(questionIndex, answerIndex);
            }}
            nextPage={() => {
              nextPage();
            }}
            previousPage={() => {
              previousPage();
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default QuestionsPage;
