import React from "react";
import { withStyles } from "@material-ui/core";

import { COLORS } from "../../../constant/colors";
import Button from "./Button";
import iconSrcTriangle from '../../../assets/customization/bottom-left-triangle-orange-light.svg';
import iconSrcTriangleDisabled from '../../../assets/customization/bottom-left-triangle-orange-lighter.svg';
import clsx from "clsx";

const RevealButton = ({ classes, title, isDisabled, onClick }) => (
  <div className={classes.container} onClick={isDisabled ? () => {} : onClick}>
    <Button title={title} isDisabled={isDisabled} onClick={() => {}} />
    <div className={clsx(classes.revealBackground, !isDisabled && classes.clickable, isDisabled && classes.revealBackgroundDisabled)}></div>
    <img src={isDisabled ? iconSrcTriangleDisabled : iconSrcTriangle} className={clsx(classes.revealForeground, !isDisabled && classes.clickable)}></img>
  </div>
);

const styles = {
  container: {
    position: 'relative',
  },
  clickable: {
    cursor: 'pointer',
  },
  revealBackground: {
    position: 'absolute',
    right: 0,
    top: 0,

    width: 22,
    height: 22,
    borderWidth: '1px 1px 0 0',
    borderStyle: 'dashed',
    borderColor: COLORS.fwdOrange,
    borderRadius: '0 4px 0 8px',
    backgroundColor: COLORS.fwdOrangeLighter5,
  },
  revealBackgroundDisabled: {
    borderColor: COLORS.fwdOrange50,
  },
  revealForeground: {
    position: 'absolute',
    right: -1,
    top: -1,

    width: 24,
    height: 24,
  },
};

export default withStyles(styles)(RevealButton);
