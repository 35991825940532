export const landingVideoJson = {
  assessmentVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltea4284ee19b95fd7/638463a4e72c60105ee2d56c/FWD_CHI_HK_25Nov.mp4",
  },
  learningVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
  },
  learningCenterVideoList: [
    {
      videoKey: 'blte46993c807b09948',
      presenterName: "李允丰醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt469825fb3e9c976e/635f5f9cff7b405f6b3f4737/MicrosoftTeams-image_(2).png",
      caption: "小朋友是怕醜還是社交焦慮？",
      videoLength: "6:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt563fac53dd3a847c/blte46993c807b09948/6361d245ae3c75582190b642/01.FWD_DrLee_Topic2_17.mp4",
    },
    {
      videoKey: 'blta47c04f2275752af',
      presenterName: "梁重皿博士",
      presenterTitle: "註冊臨床心理學家",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt8feb956f1324e76e/635f5f9ce63a425d5dc5bf85/MicrosoftTeams-image_(4).png",
      caption: "移民潮下，留下來的人如何自處？",
      videoLength: "5:30",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blta47c04f2275752af/635f413ea86a565857b1e9fd/FWD_MAX_Content_Video_Dr_Leung_Topic_3_Immigration_v7_final_OP_2_(1).mp4",
    },

    {
      videoKey: 'blt6c67be4311411516',
      presenterName: "張漢奇醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt1fa8375920486fa8/6384605ab71d091081d11873/MicrosoftTeams-image_(5).png",
      caption: "「躺平」也是一種怎樣的生活態度?",
      videoLength: "4:22",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt6c67be4311411516/6384637b7140e510ae4aa84a/FWD_MAX_Content_Video_Dr_Cheung_Topic_1_Lying_Flat_v7_final_OP.mp4",
    },
    {
      videoKey: 'blt125443fc89837a5f',
      presenterName: "麥棨諾醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/bltcdba5f9aeb9f0d29/6384605e5bbc641099350efa/MicrosoftTeams-image_(3).png",
      caption: "如何不讓工作繁忙的雙職父母將工作上的負面情緒帶回家?",
      videoLength: "6:46",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt125443fc89837a5f/6384614ced757210a0896495/FWD_MAX_Content_Video_Dr_Mak_Topic_3_Negative_emotion_from_work_v8_final_OP.mp4",
    },
    {
      videoKey: 'bltf2c261a7ac19005c',
      presenterName: "李允丰醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt984af58d15df28be/63c513a57d45377a0778e39d/Dr_Lee_Topic_1_messy_room_thumbnail_2.jpg",
      caption:
        "混亂或收藏過多物品的房間能透視精神狀態？",
      videoLength: "5:13",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltf2c261a7ac19005c/63c7631f3377ed3ff87d944b/FWD_MAX_Content_Video_Dr_Lee_Topic_1_messy_room_v5_final.mp4",
    },
    {
      videoKey: 'blt8b77963a6c2ecb1a',
      presenterName: "張漢奇醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt4d9db0d334da79e8/63c5139bca4ee4418bab9566/Dr_Cheung_Topic_2_highly_sensitive_children_thumbnail_1.jpg",
      caption:
        "了解高敏寶寶",
      videoLength: "4:16",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt8b77963a6c2ecb1a/63c50b4ee5cf0162d281d28a/FWD_MAX_Content_Video_Dr_Cheung_Topic_2_highly_sensitive_children_v6_final.mp4",
    },
    {
      videoKey: 'bltb13df446575c1977',
      presenterName: "梁重皿博士",
      presenterTitle: "註冊臨床心理學家",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt8b456f556b206dad/63c513a0e5cf0162d281d2c3/Dr.leung_Topic2_holiday_mood_thumbnail_1.jpg",
      caption:
        "掃走長假症候群，專家教您點令子女冇咁心散",
      videoLength: "5:22",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltb13df446575c1977/63c50bab184b72418acdc996/FWD_Max_Content_Video_Dr.leung_Topic2_holiday_mood_v3_final.mp4",
    },
    {
      videoKey: 'blt295960ace6e714b4',
      presenterName: "麥棨諾醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt481e45e01dd5be87/63c513aad1f34304558a32ab/Dr_Mak_Topic_1_Night_Shift_thumbnail_2.jpg",
      caption:
        "返夜更會影響親子關係?",
      videoLength: "5:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt295960ace6e714b4/63c50b932cbcfb0454a2b63d/FWD_MAX_Content_Video_Dr_Mak_Topic_1_Night_Shift_v2_final.mp4",
    },
    {
      videoKey: 'bltcd81ef6f1964549d',
      presenterName: "麥棨諾醫生",
      presenterTitle: "精神科專科醫生",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt2a3b5a20a4b02d5e/63c50e811779f2026027c828/DrMak_Topic2_thumbnail_2.jpg",
      caption:
        "用得多手機會「腦霧」?",
      videoLength: "5:34",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltcd81ef6f1964549d/63c514bf4c103f10a5569c57/FWD_MAX_Content_Video_Dr_Mak_Topic_2_addicted_to_phone_v4_final_OP.mp4",
    },
    {
      videoKey: 'blt65f3fa1f10344fd0',
      presenterName: "梁重皿博士",
      presenterTitle: "註冊臨床心理學家",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt67a1ee46b9132747/63c50e81e65e7910bab89bd0/Dr.leung_Topic1_thumbnail_1.jpg",
      caption:
        "正向心理學如何幫助家庭渡過逆境?",
      videoLength: "5:44",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt65f3fa1f10344fd0/63c514ce21560710c8e88b44/FWD_Max_Content_Video_Dr.leung_Topic1_positive_mind_v4_final_OP.mp4",
    },

  ],
};
