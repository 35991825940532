import React from "react";
import {
  TextField,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import { COLORS } from "../../../constant/colors";
import ErrorMessage from "./ErrorMessage";

const CustomerCSSTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      backgroundColor: COLORS.white,
      fontSize: 16,
      fontWeight: 450,
    },
    "& label": {
      color: COLORS.fwdGreyDarker,
    },
    "& label.Mui-focused": {
      color: COLORS.fwdDarkGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.fwdGrey,
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'unset', // Prevent being overriden by default MUI fonts
    },
    "& .MuiTypography-body1": {
      fontFamily: 'unset', // Prevent being overriden by default MUI fonts
      color: COLORS.fwdGreyDarker,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&:hover fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.fwdGrey,
      },
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: COLORS.fwdAlertRed,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.fwdAlertRed,
    },
  },
})(TextField);

const FormTextField = ({ title, type = 'text', value, onValueChange, isError, errorMessage, inputPrefix = '' }) => (
  <>
    <CustomerCSSTextField
      label={title}
      type={type}
      variant="outlined"
      value={value}
      onChange={(e) => {
        onValueChange(e.target.value);
      }}
      error={isError}
      InputProps={
        (!!value && !!inputPrefix) ? { startAdornment: <InputAdornment position="start">{inputPrefix}</InputAdornment> }
        : null
      }
    />
    {!!errorMessage && <ErrorMessage text={errorMessage} />}
  </>
);

export default FormTextField;
