import React from "react";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core";

import { COLORS } from "../../constant/colors";
import backgroundCloudSvg from "../../assets/eTriage/result/newEtriageWithPopup/backgroundCloud.svg";
import HeaderSpacer from "./HeaderSpacer";

const pageBackgroundTypes = {
  cloud: 'cloud',
  orange: 'orange',
}

const PageContainer = ({ classes, children, customBannerComponent = <HeaderSpacer/>, backgroundType = pageBackgroundTypes.cloud }) => (
  <div component="section" className={clsx(classes.pageContainer, backgroundType === pageBackgroundTypes.cloud && classes.backgroundGrey, backgroundType === pageBackgroundTypes.orange && classes.backgroundOrange)}>
    {customBannerComponent}
    <div className={clsx(classes.pageContentContainer, backgroundType === pageBackgroundTypes.cloud && classes.backgroundCloud)}>
      <>{children}</>
    </div>
  </div>
);

const styles = {
  pageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backgroundGrey: {
    background: COLORS.fwdBackgroundGrey,
  },
  backgroundOrange: {
    background: COLORS.fwdOrangeLighter5,
  },
  pageContentContainer: {
    maxWidth: 856 + 32,
    width: "100%",
    padding: "0px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backgroundCloud: {
    backgroundImage: `url(${backgroundCloudSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: 25,
    backgroundPositionX: "center",
    backgroundSize: "100%",
    minHeight: 600,
  },
};

export default withStyles(styles)(PageContainer);
