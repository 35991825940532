import { makeStyles, TextField, Button, withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import depressionSvg from "../../../assets/eTriage/landing/depression.svg";
import anxietySvg from "../../../assets/eTriage/landing/anxiety.svg";
import personalAndSocialImpairmentSvg from "../../../assets/eTriage/landing/personalAndSocialImpairment.svg";
import DialogCloseButtonRow from "../../components/DialogCloseButtonRow";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
    validationContainer: {
        backgroundColor: COLORS.white,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: "10px 40px",
        display: "flex",
        flexDirection: "column",
        padding: 40,
        [theme.breakpoints.down("sm")]: {
            padding: "20px 10px",
        },
    },
    title: {
        fontSize: 25,
        fontWeight: 700,
        lineHeight: "31px",
        color: COLORS.fwdDarkGreen,
        marginBottom: 24,
    },
    descriptionHeader: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "20px",
        color: COLORS.fwdDarkGreen,
    },
    descriptionCaption: {
        fontWeight: 450,
        fontSize: 16,
        lineHeight: "24px",
        color: COLORS.fwdDarkGreen,
    },
    descriptionPointsGroup: {
        marginTop: 24,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    descriptionPoint: {
        flex: 1,
        border: `1px solid ${COLORS.fwdGrey}`,
        borderRadius: 16,
        margin: 8,
        padding: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        "&:nth-child(1)": {
            marginLeft: 0,
        },
        "&:last-child": {
            marginRight: 0,
        },
        [theme.breakpoints.down("sm")]: {
            margin: 4,
            flexDirection: "row",
        },
    },
    descriptionPointIcon: {
        width: 80,
        height: 80,
        [theme.breakpoints.down("sm")]: {
            width: 56,
            height: 56,
            marginRight: 8,
        },
    },
    symptomsLabel: {
        display: "inline",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "20px",
        color: COLORS.fwdDarkGreen,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: "18px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
        },
    },
    symptomsQuestionsNumber: {
        whiteSpace: "nowrap",
        fontSize: 16,
        fontWeight: 450,
        lineHeight: "24px",
        color: COLORS.fwdDarkGreen,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            fontWeight: 450,
            lineHeight: "21px",
        },
    },
    consentSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        fontSize: 14,
        fontWeight: 450,
        lineHeight: "21px",
        color: COLORS.fwdDarkGreen,
        "& span": {
            cursor: "pointer",
            color: COLORS.fwdOrange,
            fontSize: 14,
            fontWeight: 700,
            lineHeight: "18px",
            paddingLeft: 0,
        },
    },
    itemRow: {
        paddingTop: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& *": {
            borderColor: COLORS.fwdGrey,
        },
    },
    inputTextfield: {
        width: "230px",
        marginRight: 10,
        [theme.breakpoints.down("sm")]: {
            flex: 1,
        },
    },
    goButtonText: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "20px",
        margin: "12px 43px",
        textTransform: "none",
    },
    goButton: {
        background: COLORS.fwdOrange,
        color: COLORS.white,
        height: 50,
        // width: "100%",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            background: COLORS.fwdOrange20,
        },
        "&:disabled": {
            background: COLORS.fwdOrange20,
            color: COLORS.white,
        },
    },
    errorMsgContainer: {
        display: "flex",
        flexDirection: "row",
        paddingTop: 10,
    },
    errorSvg: {
        width: 24,
        height: 24,
        marginRight: 7,
    },
    errorMsg: {
        color: COLORS.fwdAlertRed,
        fontSize: 14,
        fontWeight: 450,
        lineHeight: "21px",
    },

    checkbox: {
        color: COLORS.fwdOrange,
        borderColor: COLORS.fwdOrange,
        "& .MuiSvgIcon-root": {
            width: 24,
            height: 24,
        },
        "& .Mui-checked": {
            color: COLORS.fwdOrange,
        },
    },
    validationCustomerCaption: {
        marginTop: 24,
        marginBottom: 10,
        color: COLORS.fwdDarkGreen,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "20px",
    },
    notCusomterSkipText: {
        color: COLORS.fwdOrange,
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "20px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 20,
        },
    },
    emergencyHint: {
        marginTop: 20,
        marginBottom: 10,
        color: COLORS.fwdDarkGreen,
        "& a": {
            color: COLORS.fwdDarkGreen,
        },
        [theme.breakpoints.down("sm")]: {
            color: COLORS.fwdDarkGreen,
            "& a": {
                color: COLORS.fwdDarkGreen,
            },
        },
    },
    dialogContent: {
        color: COLORS.fwdDarkGreen,
    },
}));

function NewLandingPopup({ startWithConsent }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [id, setId] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tncOpen, setTncOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const currentBu = process.env.REACT_APP_BU ?? "hk";

    const handleTncClickOpen = () => {
        setTncOpen(true);
    };

    const handleTncClose = () => {
        setTncOpen(false);
    };

    const handlePrivacyPolicyGroupClick = () => {
        const groupPrivacyPolicyUrl = i18n.language == "tc" ? process.env.REACT_APP_PRIVACY_POLICY_GROUP_TC : process.env.REACT_APP_PRIVACY_POLICY_GROUP_EN;
        window.open(groupPrivacyPolicyUrl);
    };

    const handlePrivacyPolicyBuClick = () => {
        const buPrivacyPolicyUrl = i18n.language == "tc" ? process.env.REACT_APP_PRIVACY_POLICY_BU_HK_TC : process.env.REACT_APP_PRIVACY_POLICY_BU;
        window.open(buPrivacyPolicyUrl);
    };

    const handleTHPrivacyPolicyClick = () => {
        const buPrivacyPolicyUrl = i18n.language === "en" ? process.env.REACT_APP_TH_PRIVACY_POLICY_EN : process.env.REACT_APP_TH_PRIVACY_POLICY_TH;
        window.open(buPrivacyPolicyUrl);
    };

    const handleVNDisclaimerClick = () => {
        const disclaimerUrl = i18n.language === "en" ? process.env.REACT_APP_VN_DISCLAIMER_URL_EN : process.env.REACT_APP_VN_DISCLAIMER_URL_VI;
        window.open(disclaimerUrl);
    };

    const handleIDPrivacyPolicyClick = () => {
        const buPrivacyPolicyUrl = i18n.language === "en" ? process.env.REACT_APP_ID_PRIVACY_POLICY_EN : process.env.REACT_APP_ID_PRIVACY_POLICY_ID;
        window.open(buPrivacyPolicyUrl);
    };

    const handleVNOnlineSecurityClick = () => {
        const onlineSecurityUrl = i18n.language == "en" ? process.env.REACT_APP_VN_ONLINE_SECURITY_URL_EN : process.env.REACT_APP_VN_ONLINE_SECURITY_URL_VN;

        window.open(onlineSecurityUrl);
    };

    const handlMYPersonalDataNoticeClick = () => {
        const personalDataNoticeUrl = process.env.REACT_APP_MY_PERSONAL_DATA_NOTICE_URL;
        window.open(personalDataNoticeUrl);
    };

    const handlePHPrivacyPolicyClick = () => {
        const buPrivacyPolicyUrl = process.env.REACT_APP_PH_PRIVACY_POLICY_EN;
        window.open(buPrivacyPolicyUrl);
    };

    const _renderStartNowButton = () => {
        return (
            <>
                <div className={classes.itemRow}>
                    <Button
                        disabled={!checked || isLoading}
                        className={classes.goButton}
                        onClick={() => {
                            startWithConsent();
                        }}>
                        <div className={classes.goButtonText}>{t("eTriageLanding.validation.startNow")} </div>
                    </Button>
                </div>
            </>
        );
    };

    const _renderIntroductionSection = () => {
        return (
            <>
                <div>
                    {currentBu !== "hk" && (<div className={classes.title}>{t("eTriageLanding.beforeYouProceed")}</div>)}
                    <div className={classes.descriptionHeader}>{t(`eTriageLanding.newETriage.title.${currentBu}`)}</div>
                    {currentBu !== "hk" && (<div className={classes.descriptionCaption}>{t("eTriageLanding.newETriage.description")}</div>)}
                    <div className={classes.descriptionPointsGroup}>
                        <div className={classes.descriptionPoint}>
                            <img src={depressionSvg} className={classes.descriptionPointIcon} />
                            <div className={classes.symptomsLabelContainer}>
                                <div className={classes.symptomsLabel}>
                                    <span>{t("eTriageLanding.depressionsymptoms")}</span>
                                    <span className={classes.symptomsQuestionsNumber}> (9 {t("eTriageLanding.questions")})</span>
                                </div>
                            </div>
                        </div>
                        <div className={classes.descriptionPoint}>
                            <img src={anxietySvg} className={classes.descriptionPointIcon} />
                            <div className={classes.symptomsLabelContainer}>
                                <div className={classes.symptomsLabel}>
                                    <span>{t("eTriageLanding.anxietySymptoms")}</span>
                                    <span className={classes.symptomsQuestionsNumber}> (7 {t("eTriageLanding.questions")})</span>
                                </div>
                            </div>
                        </div>
                        <div className={classes.descriptionPoint}>
                            <img src={personalAndSocialImpairmentSvg} className={classes.descriptionPointIcon} />
                            <div className={classes.symptomsLabelContainer}>
                                <div className={classes.symptomsLabel}>
                                    <span> {t("eTriageLanding.personalAndSocialImpairment")} </span>
                                    <span className={classes.symptomsQuestionsNumber}> (5 {t("eTriageLanding.questions")})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.consentSection}>
                        <div>
                            <Checkbox
                                color="default"
                                checked={checked}
                                className={`${classes.checkbox}`}
                                onChange={(e) => {
                                    setChecked(e.target.checked);
                                }}
                            />
                        </div>
                        {currentBu === "my"
                            ? renderConsentMY()
                            : currentBu === "th"
                                ? renderConsentTH()
                                : currentBu === "vn"
                                    ? renderConsentVN()
                                    : currentBu === "id"
                                        ? renderConsentID()
                                        : currentBu === "ph"
                                            ? renderConsentPH()
                                            : renderConsent()}{" "}
                    </div>
                </div>
            </>
        );
    };

    const renderConsent = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}{" "}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyGroupClick();
                    }}>
                    {t("eTriageLanding.FWDGroupPrivacyNotice")}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyBuClick();
                    }}>
                    {t(`eTriageLanding.${currentBu}`)}
                    {t(`eTriageLanding.privacyPolicy`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}>{ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))} </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    const renderConsentTH = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}{" "}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyGroupClick();
                    }}>
                    {t("eTriageLanding.FWDGroupPrivacyNotice")}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTHPrivacyPolicyClick();
                    }}>
                    {t(`eTriageLanding.${currentBu}`)}
                    {t(`eTriageLanding.privacyPolicy`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}>{ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))}</div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    const renderConsentMY = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyGroupClick();
                    }}>
                    {t("eTriageLanding.FWDGroupPrivacyNotice")}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyBuClick();
                    }}>
                    {t(`eTriageLanding.${currentBu}`)}
                    {t(`eTriageLanding.privacyPolicy`)}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlMYPersonalDataNoticeClick();
                    }}>
                    {t(`eTriageLanding.MYPersonalDataProtectionNotice`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}>{ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))}</div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    const renderConsentID = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}{" "}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyGroupClick();
                    }}>
                    {t("eTriageLanding.FWDGroupPrivacyNotice")}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleIDPrivacyPolicyClick();
                    }}>
                    {t(`eTriageLanding.${currentBu}`)}
                    {t(`eTriageLanding.privacyPolicy`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}> {ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))}</div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    const renderConsentPH = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}{" "}
                </span>
                {t("eTriageLanding.consentComma")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePrivacyPolicyGroupClick();
                    }}>
                    {t("eTriageLanding.FWDGroupPrivacyNotice")}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handlePHPrivacyPolicyClick();
                    }}>
                    {t(`eTriageLanding.${currentBu}`)}
                    {t(`eTriageLanding.privacyPolicy`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}> {ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))}</div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    const renderConsentVN = () => {
        return (
            <div>
                {t(`eTriageLanding.consent.${currentBu}`)}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleTncClickOpen();
                    }}>
                    {t("eTriageLanding.tNc")}
                </span>
                {t("eTriageLanding.consentComma")}

                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleVNDisclaimerClick();
                    }}>
                    {t(`eTriageLanding.disclaimer`)}{" "}
                </span>
                {t("eTriageLanding.consentAnd")}
                <span
                    className={classes.clickableSpan}
                    onClick={() => {
                        handleVNOnlineSecurityClick();
                    }}>
                    {t(`eTriageLanding.onlineSecurity`)}
                </span>
                {t("eTriageLanding.consentEnd")}

                <Dialog open={tncOpen} onClose={handleTncClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <DialogCloseButtonRow handleClose={handleTncClose} />
                    <DialogTitle id="scroll-dialog-title">{t(`tNcTitle.${currentBu}`, { ns: "landingPageLongText" })} </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                            <div className={classes.dialogContent}>{ReactHtmlParser(t(`tNcDetails.${currentBu}`, { ns: "landingPageLongText" }))}</div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    return (
        <>
            <div component="popupover" className={classes.validationContainer}>
                {_renderIntroductionSection()}
                {_renderStartNowButton()}
                <div className={classes.emergencyHint}>{ReactHtmlParser(t(`eTriageLanding.emergencyHint.${currentBu}`))}</div>
            </div>
        </>
    );
}

export default NewLandingPopup;
