export const headerJson = {
  uid: "abaca3eb-6579-47ef-b88f-c9e713f1ec17",
  componentName: "Header",
  dataSource: "{CCCFE6F7-1169-409A-AB37-3CAE30D24488}",
  params: {
    backgroundColor: "white-orange",
    paddingTop: "None",
    paddingBottom: "None",
  },
  fields: {
    displayMode: {
      id: "c81d8d8f-e8d9-468f-acfc-bbdce30d66ec",
      fieldType: "Droplink",
      fieldName: "displayMode",
      url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/carouselmode/mobile",
      fields: {
        value: { value: "Mobile" },
        key: { value: "Mobile" },
      },
    },
    countrySelector: [
      {
        id: "0f8ee116-4b66-4b7e-81c5-275ee4c64978",
        name: "Group",
        displayName: "Group",
        fields: {
          isDefault: { value: false },
          title: { value: "FWD Group" },
          link: {
            value: {
              href: "https://www.fwd.com/",
              text: "FWD Group",
              linktype: "external",
              url: "https://www.fwd.com/",
              anchor: "",
              title: "FWD Group",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "c8790a90-c83f-4e0e-9337-d4b42e0f634b",
        name: "Cambodia",
        displayName: "Cambodia",
        fields: {
          isDefault: { value: false },
          title: { value: "Cambodia" },
          link: {
            value: {
              href: "https://www.fwd.com.kh/",
              text: "Cambodia",
              linktype: "external",
              url: "https://www.fwd.com.kh/",
              anchor: "",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "705fb6bf-8708-4aff-aba3-843de80e7577",
        name: "Hong Kong",
        displayName: "Hong Kong",
        fields: {
          isDefault: { value: false },
          title: { value: "Hong Kong" },
          link: {
            value: {
              href: "https://www.fwd.com.hk/",
              text: "FWD Hong Kong",
              linktype: "external",
              url: "https://www.fwd.com.hk/",
              anchor: "",
              title: "FWD Hong Kong",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "4c2ac688-4db2-44c0-a2a3-df7eee3f311b",
        name: "Macau",
        displayName: "Macau",
        fields: {
          isDefault: { value: false },
          title: { value: "Macau" },
          link: {
            value: {
              href: "https://www.fwd.com.mo/",
              text: "FWD Macau",
              linktype: "external",
              url: "https://www.fwd.com.mo/",
              anchor: "",
              title: "FWD Macau",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "d6ec9fe1-6716-41f9-b260-777a3a0df396",
        name: "Indonesia",
        displayName: "Indonesia",
        fields: {
          isDefault: { value: false },
          title: { value: "Indonesia" },
          link: {
            value: {
              href: "https://www.fwd.co.id/",
              text: "FWD Indonesia",
              linktype: "external",
              url: "https://www.fwd.co.id/",
              anchor: "",
              title: "FWD Indonesia",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "1c474efb-12a7-46c4-b789-9dac52771e92",
        name: "Japan",
        displayName: "Japan",
        fields: {
          isDefault: { value: false },
          title: { value: "Japan" },
          link: {
            value: {
              href: "http://www.fwdlife.co.jp",
              text: "FWD Japan",
              linktype: "external",
              url: "http://www.fwdlife.co.jp",
              anchor: "",
              title: "FWD Japan",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "7819367f-f443-4cc4-8500-853d174d5931",
        name: "Malaysia",
        displayName: "Malaysia",
        fields: {
          isDefault: { value: false },
          title: { value: "Malaysia" },
          link: {
            value: {
              href: "https://www.fwd.com.my/",
              text: "FWD Malaysia",
              linktype: "external",
              url: "https://www.fwd.com.my/",
              anchor: "",
              title: "FWD Malaysia",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "3cbf0795-1368-4bfa-823f-992a5e34ef0d",
        name: "Philippines",
        displayName: "Philippines",
        fields: {
          isDefault: { value: false },
          title: { value: "Philippines" },
          link: {
            value: {
              href: "https://www.fwd.com.ph/",
              text: "FWD Philippines",
              linktype: "external",
              url: "https://www.fwd.com.ph/",
              anchor: "",
              title: "FWD Philippines",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "38e2032c-59d9-45f0-b485-98c30b212638",
        name: "Singapore",
        displayName: "Singapore",
        fields: {
          isDefault: { value: false },
          title: { value: "Singapore" },
          link: {
            value: {
              href: "https://www.fwd.com.sg/",
              text: "FWD Singapore",
              linktype: "external",
              url: "https://www.fwd.com.sg/",
              anchor: "",
              title: "FWD Singapore",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "66cfbe84-bc11-4565-9e91-63a71e5e33aa",
        name: "Thailand",
        displayName: "Thailand",
        fields: {
          isDefault: { value: true },
          title: { value: "Thailand" },
          link: {
            value: {
              href: "https://www.fwd.co.th/",
              text: "FWD Thailand ",
              linktype: "external",
              url: "https://www.fwd.co.th/",
              anchor: "",
              title: "FWD Thailand",
              target: "",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "35530397-2c2a-4175-9914-35dc9ed3b057",
        name: "Vietnam",
        displayName: "Vietnam",
        fields: {
          isDefault: { value: false },
          title: { value: "Vietnam" },
          link: {
            value: {
              href: "https://www.fwd.com.vn/",
              text: "FWD Vietnam",
              linktype: "external",
              url: "https://www.fwd.com.vn/",
              anchor: "",
              title: "FWD Vietnam",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
    ],
    mobileLogo: {
      value: {
        src: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-mobile-optimized.gif?rev=215115ffd9cd4e15a5fd3cbd96590b76",
        alt: "Mobile Logo",
        x1: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-mobile-optimized.gif?rev=215115ffd9cd4e15a5fd3cbd96590b76&cx=0&cy=0&cw=160&ch=55&hash=C1547FAFA72A2EA9DCDC135D539F2216",
        x2: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-mobile-optimized.gif?rev=215115ffd9cd4e15a5fd3cbd96590b76&cx=0&cy=0&cw=320&ch=110&hash=FD8168F6C1EE3522F54EA9CCE3F89B13",
      },
    },
    logo: {
      value: {
        src: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-desktop_optimized.gif?rev=559bde7dae054a4e950d313ccc58d7cf",
        alt: "Desktop Logo",
        x1: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-desktop_optimized.gif?rev=559bde7dae054a4e950d313ccc58d7cf&cx=0.34&cy=0.49&cw=420&ch=145&hash=7855B921A799ABE97AE84A6FC3401D96",
        x2: "https://www.fwd.co.th/-/media/images/logo/fwd_logo-desktop_optimized.gif?rev=559bde7dae054a4e950d313ccc58d7cf&cx=0.34&cy=0.49&cw=840&ch=290&hash=4DFFAB64D3ED58826209023B89AAACFF",
      },
    },
    logoLink: {
      value: {
        href: "https://www.fwd.co.th/en/",
        text: "Home",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "",
        querystring: "",
        id: "{D9C6779B-43E9-4106-8357-AD0510A8547C}",
      },
    },
    headerLogo: {
      value: {
        src: "https://images.contentstack.io/v3/assets/blt331c1aa12dcfd37a/blt79ac781550a28c01/623bcec5811c13262b56a298/FWDLogoD.svg",
        alt: "FWD Desktop Logo",
      },
    },
    headerNavigationLinks: [
      {
        id: "696e7720-7007-4515-baa2-f923545c5f67",
        name: "personal-insurance",
        displayName: "Personal Insurance",
        fields: {
          title: { value: "Personal insurance" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/products/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: "{528D2C44-05E6-4E13-8C4C-0A7FFF35B120}",
            },
          },
          buttonIcon: null,
          pageType: {
            id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
            fieldType: "Droptree",
            fieldName: "pageType",
            url: "https://www.fwd.co.th/en/components/pagetype/individual",
            fields: {
              value: { value: "Individual" },
              key: { value: "individual" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
        Children: [
          {
            id: "14c54add-25ec-4897-bdc9-425a41b6d53f",
            name: "Life insurance",
            displayName: "Life insurance",
            fields: {
              title: { value: "Life insurance" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/products/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{528D2C44-05E6-4E13-8C4C-0A7FFF35B120}",
                },
              },
              buttonIcon: {
                id: "19fcb2e6-0897-424f-9d15-b35209e646ed",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/products",
                fields: {
                  value: { value: "products" },
                  key: { value: "products" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "b7783aa2-a416-48b2-9b4c-688441d846f1",
            name: "General insurance",
            displayName: "General insurance",
            fields: {
              title: { value: "General insurance" },
              link: {
                value: {
                  href: "http://www.fwdgi.co.th/",
                  text: "ประกันวินาศภัย",
                  linktype: "external",
                  url: "http://www.fwdgi.co.th/",
                  anchor: "",
                  target: "_blank",
                },
              },
              buttonIcon: {
                id: "19fcb2e6-0897-424f-9d15-b35209e646ed",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/products",
                fields: {
                  value: { value: "products" },
                  key: { value: "products" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "a4cabda7-dbb3-492f-8868-2f1a9f346e93",
            name: "Claims",
            displayName: "Claims",
            fields: {
              title: { value: "Claims" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/claims/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{69567D54-9813-4873-A169-DDE101852FAE}",
                },
              },
              buttonIcon: {
                id: "8ba0c144-7d03-426d-84a8-3329cca6784a",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/claims",
                fields: {
                  value: { value: "claims" },
                  key: { value: "claims" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "626bffb7-d02b-4ff0-ae45-7d71f96b28a4",
            name: "Support",
            displayName: "Support",
            fields: {
              title: { value: "Support" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/support/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{A09F0B08-17C3-43BA-A3B1-FD62BA1E29C9}",
                },
              },
              buttonIcon: {
                id: "327d49e8-0d7f-4133-b8eb-543e657d781b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/live-chat",
                fields: {
                  value: { value: "live-chat" },
                  key: { value: "liveChat" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "5aa022af-607b-4bb6-ad85-28ce83b26ac6",
            name: "Rewards",
            displayName: "Rewards",
            fields: {
              title: { value: "Rewards" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/rewards/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{37296FD4-8CF2-45BA-88E0-FAAEC51215A7}",
                },
              },
              buttonIcon: {
                id: "7a571888-b9c6-4778-973b-828ec009c8b0",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/rewards",
                fields: {
                  value: { value: "rewards" },
                  key: { value: "Rewards" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "cd4f081e-a1ac-4ac6-a004-6aac01be8614",
            name: "Visit Country site",
            displayName: "Visit Country site",
            fields: {
              title: { value: "Visit [Country site]" },
              link: { value: {} },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
        ],
      },
      {
        id: "94dcacbe-4314-4464-ba5b-6b74cb22020d",
        name: "business-insurance",
        displayName: "Business Insurance",
        fields: {
          title: { value: "Group insurance" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/group-insurance/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{0D08A74E-81B5-4DFD-9B53-B8AD7413F555}",
            },
          },
          buttonIcon: null,
          pageType: {
            id: "186f12b8-f721-489f-8df9-67d5f10c69d5",
            fieldType: "Droptree",
            fieldName: "pageType",
            url: "https://www.fwd.co.th/en/components/pagetype/business",
            fields: {
              value: { value: "Business" },
              key: { value: "business" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
        Children: [
          {
            id: "46efd563-0e58-4c65-a967-f1838d26aaf4",
            name: "Products",
            displayName: "Products",
            fields: {
              title: { value: "Products" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/group-insurance/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{0D08A74E-81B5-4DFD-9B53-B8AD7413F555}",
                },
              },
              buttonIcon: {
                id: "19fcb2e6-0897-424f-9d15-b35209e646ed",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/products",
                fields: {
                  value: { value: "products" },
                  key: { value: "products" },
                },
              },
              pageType: {
                id: "186f12b8-f721-489f-8df9-67d5f10c69d5",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/business",
                fields: {
                  value: { value: "Business" },
                  key: { value: "business" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "f7d7f2d9-b99f-4ac6-b715-a948f7037272",
            name: "Support",
            displayName: "Support",
            fields: {
              title: { value: "Support" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/en/group-insurance/support/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{3CAD5672-1B79-4F04-8637-17B34ED987E9}",
                },
              },
              buttonIcon: {
                id: "fbdc2dbb-311b-47d3-929d-214d7b812ba5",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/supportoutline---obsolete",
                fields: {
                  value: { value: "support-outline" },
                  key: { value: "supportOutline" },
                },
              },
              pageType: {
                id: "186f12b8-f721-489f-8df9-67d5f10c69d5",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/business",
                fields: {
                  value: { value: "Business" },
                  key: { value: "business" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "941ed75c-de31-4f2e-bf74-0142d64eeee6",
            name: "Visit Country site",
            displayName: "Visit Country site",
            fields: {
              title: { value: "Visit [Country site]" },
              link: { value: {} },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
        ],
      },
      {
        id: "286baa52-ced2-4202-bacc-40773b2e2545",
        name: "Max",
        displayName: "Max",
        fields: {
          title: { value: "FWD MAX" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/fwdmax/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: "{B06FCBBE-D82F-45E6-8CF6-9907C875B081}",
            },
          },
          buttonIcon: null,
          pageType: {
            id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
            fieldType: "Droptree",
            fieldName: "pageType",
            url: "https://www.fwd.co.th/en/components/pagetype/individual",
            fields: {
              value: { value: "Individual" },
              key: { value: "individual" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "61f3e4bb-10b2-4c27-8459-85671780188a",
        name: "about-us",
        displayName: "About Us",
        fields: {
          title: { value: "About us" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/about-us/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: "{49F0D81D-2E91-44B2-9F6E-3267BABAFA41}",
            },
          },
          buttonIcon: {
            id: "368ba79d-8cae-435c-a781-fe6fb2f3725d",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/collection---obsolete",
            fields: {
              value: { value: "collection" },
              key: { value: "Collection" },
            },
          },
          pageType: {
            id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
            fieldType: "Droptree",
            fieldName: "pageType",
            url: "https://www.fwd.co.th/en/components/pagetype/individual",
            fields: {
              value: { value: "Individual" },
              key: { value: "individual" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
    ],
    iconNavigationLinks: [
      {
        id: "b478fc8e-7b49-4eb4-8635-d62df58d5e86",
        name: "Login",
        displayName: "Login",
        fields: {
          enableDropdown: { value: true },
          title: { value: "Login" },
          link: { value: {} },
          buttonIcon: null,
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "Login" },
        },
        Children: [
          {
            id: "b6d18fad-2d17-4066-a3a5-0a13fe737c5e",
            name: "Customer",
            displayName: "Customer",
            fields: {
              title: { value: "Customer" },
              link: {
                value: {
                  href: "https://fwdlink.fwd.co.th/fwd/login?utm_source=nexgen&utm_medium=referral&utm_campaign=20210201-nexgenreferral",
                  text: "Customer",
                  linktype: "external",
                  url: "https://fwdlink.fwd.co.th/fwd/login?utm_source=nexgen&utm_medium=referral&utm_campaign=20210201-nexgenreferral",
                  anchor: "",
                  target: "",
                },
              },
              buttonIcon: {
                id: "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/link",
                fields: {
                  value: { value: "arrow-right" },
                  key: { value: "Link" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "customer" },
            },
          },
          {
            id: "a00211c6-37f4-4978-9153-7a4711bc5a96",
            name: "Hospital",
            displayName: "Hospital",
            fields: {
              title: { value: "Hospital" },
              link: {
                value: {
                  href: "https://hospitalportal.fwd.co.th/",
                  linktype: "external",
                  url: "https://hospitalportal.fwd.co.th/",
                  anchor: "",
                  target: "",
                },
              },
              buttonIcon: {
                id: "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/link",
                fields: {
                  value: { value: "arrow-right" },
                  key: { value: "Link" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "hospital" },
            },
          },
          {
            id: "440f8161-d414-45b1-99fd-6277f3f1e199",
            name: "Agent",
            displayName: "Agent",
            fields: {
              title: { value: "Agent" },
              link: {
                value: {
                  href: "https://www.fwd.in.th/logon.aspx?",
                  linktype: "external",
                  url: "https://www.fwd.in.th/logon.aspx?",
                },
              },
              buttonIcon: {
                id: "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/link",
                fields: {
                  value: { value: "arrow-right" },
                  key: { value: "Link" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "agent" },
            },
          },
        ],
      },
      {
        id: "5d801d94-570a-437f-a238-0fd2101dce5d",
        name: "Support",
        displayName: "Support",
        fields: {
          enableDropdown: { value: true },
          title: { value: "" },
          link: { value: {} },
          buttonIcon: {
            id: "79cc843a-2212-40b5-b09f-2018d04c72a9",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/contact",
            fields: {
              value: { value: "contact" },
              key: { value: "Contact" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "Support" },
        },
        Children: [
          {
            id: "1575f6cd-317f-4bc7-9f9d-30ec68035f18",
            name: "Call 1351",
            displayName: "Call 1351",
            fields: {
              title: { value: "Call 1351" },
              link: {
                value: {
                  href: "http://tel:1351",
                  text: "โทร. 1351",
                  linktype: "external",
                  url: "tel:1351",
                  anchor: "",
                  target: "",
                },
              },
              buttonIcon: {
                id: "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/call",
                fields: {
                  value: { value: "call" },
                  key: { value: "Call" },
                },
              },
              pageType: {
                id: "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
                fieldType: "Droptree",
                fieldName: "pageType",
                url: "https://www.fwd.co.th/en/components/pagetype/individual",
                fields: {
                  value: { value: "Individual" },
                  key: { value: "individual" },
                },
              },
              listType: {
                id: "fd670504-d972-4fee-9582-656b0d6ba342",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
                fields: {
                  value: { value: "Call" },
                  key: { value: "call" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
        ],
      },
      {
        id: "a06aeaa3-83e8-498d-91ab-2c715b18186c",
        name: "Search",
        displayName: "Search",
        fields: {
          enableDropdown: { value: true },
          title: { value: "" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/search/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: "{564D4C9C-BEEB-4497-9BB7-74A9EBAEF812}",
            },
          },
          buttonIcon: {
            id: "8e6f2179-2d7b-420d-9d47-d13c408bb3c5",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/linkvariant/search",
            fields: {
              value: { value: "search" },
              key: { value: "Search" },
            },
          },
          listType: {
            id: "b91e87f2-99cc-4a93-a0ba-3ee8c14f36a4",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/search",
            fields: {
              value: { value: "Search" },
              key: { value: "search" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
    ],
    searchPageLink: {
      value: {
        href: "https://www.fwd.co.th/en/search/",
        text: "",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "",
        target: "",
        querystring: "",
        id: "{564D4C9C-BEEB-4497-9BB7-74A9EBAEF812}",
      },
    },
    pastSearchRecordsCount: { value: "8" },
    title: { value: "I'm looking for..." },
    placeholderText: { value: "Please enter a keyword or phrase" },
    languageModalDescription: {
      value:
        "We currently working to have this content in your language. You can choose to stay on this page, or proceed to explore other relevant content.",
    },
    languageModalLink: {
      value: {
        href: "https://www.fwd.co.th/en/",
        text: "Explore",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "Okie",
        querystring: "",
        id: "{D9C6779B-43E9-4106-8357-AD0510A8547C}",
      },
    },
    languageModalSecondaryLink: { value: "Stay on page" },
    languageModalTitle: {
      value: "This content might not be in your language.",
    },
    languageList: [
      {
        id: "65e62323-9da1-4172-b472-7443ebbbfbee",
        fields: {
          languageName: { value: "EN" },
          languageCode: { value: "en" },
          defaultLanguage: { value: "Th" },
        },
      },
      {
        id: "5f8cb340-ec7f-4b71-bb4a-c950856d3495",
        fields: {
          languageName: { value: "ไทย" },
          languageCode: { value: "th" },
          defaultLanguage: { value: "En" },
        },
      },
    ],
    enableLanguageToggle: { value: true },
  },
  sitecoreContext: {
    language: "EN",
    languageCode: "en",
    availableLanguages: ["th", "en"],
  },
};
