import {
  Checkbox,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { COLORS } from "../../../../constant/colors";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./progressProviders";
import DialogCloseButtonRow from "../../../components/DialogCloseButtonRow";

const useStyles = makeStyles((theme) => ({
  resultCircularContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& .CircularProgressbar ": {
      maxWidth: 150,
      maxHeight: 150
    },
    "& .CircularProgressbar .CircularProgressbar-trail": {
      strokeWidth: 5,
    },
  },

  resultCategoryColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 250,
  },
  resultCategoryColumnTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 150,
    width: "100%",
    flex: 1
  },
  resultCategoryColumnIcon: {
    width: "68%",
    // width: '100px'
  },
  resultCategoryColumnLabel: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdDarkGreen,
    margin: "8px 8px",
    textAlign: "center",
    flex: 1
  },
  resultCategoryColumnScoreLabel: {
    background: COLORS.fwdLightGreen20,
    padding: "8px 10px",
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    color: COLORS.fwdDarkGreen,
    // marginBottom: 20,
    animation: "fadeIn 5s",
    height: '100%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 5px'
  },
  resultCategoryColumnScoreLabelLevel: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "18px"
  },
  learnMoreButton: {
    color: COLORS.fwdOrange,
    fontSize: 14,
    fontWeight: 17,
    lineHeight: "18px",
    cursor: "pointer",
  },
}));

function CategoryResultItemLabelComponent({ item, scores, i18n }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "resultPageLongText"]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSymptomsScorePercentage = (category: String) => {
    const score = scores.get(category).score / scores.get(category).maxScore;
    return score;
  };

  const getSymptomsLevel = (category: String) => {
    return scores.get(category).level.levelScore;
    // return scores.get(category).level.level;
  };

  const symptomsLevelLabel = {
    0: {
      text: "eTriageResultPage.level.noRisk",
      backgroundColor: COLORS.fwdLightGreen20,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdLightGreen50
    },
    1: {
      text: "eTriageResultPage.level.mild",
      backgroundColor: COLORS.fwdBlue50,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdBlue50
    },
    2: {
      text: "eTriageResultPage.level.moderate",
      backgroundColor: COLORS.fwdYellow,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdYellow,
    },
    3: {
      text: "eTriageResultPage.level.severe",
      backgroundColor: COLORS.fwdAlertRed,
      textColor: COLORS.white,
      circularPathRailColor: COLORS.fwdAlertRed,
    },
  };

  return (
    <>
      <div className={classes.resultCategoryColumn}>
        <div className={classes.resultCategoryColumnTop}>
          <ProgressProvider valueStart={0} valueEnd={1}>
            {(value) => (
              <div
                className={classes.resultCategoryColumnScoreLabel}
                style={{
                  opacity: value,
                  background: `${symptomsLevelLabel[getSymptomsLevel(item.category)]
                    .backgroundColor
                    }`,
                  color: `${symptomsLevelLabel[getSymptomsLevel(item.category)]
                    .textColor
                    }`,
                }}
              >
                <span className={classes.resultCategoryColumnScoreLabelLevel}>
                  {`${t(
                    symptomsLevelLabel[getSymptomsLevel(item.category)].text
                  )}`}
                </span>
              </div>
            )}
          </ProgressProvider>
          <div className={classes.resultCircularContainer}>

          </div>


        </div>
      </div>
    </>
  );
}

export default withTranslation()(CategoryResultItemLabelComponent);
