import { Checkbox, makeStyles } from "@material-ui/core";
import Questions, {
  Categories,
  Category,
} from "../../../constant/etriage/questions";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import DotSvgComponent from "../../../assets/eTriage/questions/progressBar/DotSvgComponent";
import StarSvgComponent from "../../../assets/eTriage/questions/progressBar/StarSvgComponent";
import BackButtonSvgComponent from "../../../assets/eTriage/questions/pagination/backButton";
import NextButtonSvgComponent from "../../../assets/eTriage/questions/pagination/nextButton";
// import BackButtonSvg from "../../../assets/eTriage/questions/pagination/backButton.svg";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0px",
    marginTop: "16px",
  },
  paginationContainerRight: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backAndNextButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
  },

  backButton: {
    width: 17,
    height: 19,
    cursor: "pointer",
  },

  nextButton: {
    width: 17,
    height: 19,
    cursor: "pointer",
    marginLeft: 20,
    
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  finishLabel: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdOrange,
    marginRight: 10,
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: COLORS.fwdDarkGreen,
    padding: "0px 10px",
    textAlign: "right",
  },
  progressBar: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 15px 0px 15px",
    justifyContent: "space-between",
  },

  sectionAndPaginationGroup: {
    display: "flex",
    flexDirection: "column",
    width: 500,
  },
}));

function DesktopQuestionPagination({
  questionIndex,
  questionsDetail,
  previousPage,
  selectedAnswer,
  onAnswerSelect,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const categoriesData = Categories.get(questionsDetail.category);

  const shouldShowNextButton =
    categoriesData.type != "amplitude" && selectedAnswer[questionIndex] >= 0;

  return (
    <>
      <div component="modal" className={classes.paginationContainer}>
        <div className={classes.paginationContainerRight}>
          {shouldShowNextButton && (
            <div
              className={clsx(classes.nextButton)}
              onClick={() => {
                onAnswerSelect(selectedAnswer[questionIndex]);
              }}
            >
              <NextButtonSvgComponent fill={COLORS.fwdOrange} />
            </div>
          )}

          <div className={classes.sectionAndPaginationGroup}>
            <div className={classes.sectionTitle}>
              {t(`eTriageQuestionsPage.${categoriesData.label}`)}
            </div>
            <div className={classes.progressBar}>
              {Questions.map((item: Question, index) => {
                const filledColor =
                  questionIndex == index
                    ? COLORS.fwdOrange
                    : questionIndex > index
                      ? COLORS.fwdDarkGreen
                      : COLORS.fwdOrange20;
                return (
                  <div key={index}>
                    {item.starIndicator == true ? (
                      <StarSvgComponent fill={filledColor} key={index} />
                    ) : (
                      <DotSvgComponent fill={filledColor} key={index} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {questionsDetail.showBackButton && (
          <div className={classes.backButton} onClick={previousPage}>
            <BackButtonSvgComponent fill={COLORS.fwdOrange} />
          </div>
        )}
      </div>
    </>
  );
}

export default DesktopQuestionPagination;
