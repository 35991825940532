import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import PageContainer from '../../layout/PageContainer';
import Spacer from "../../components/core-elements/Spacer";
import RedeemFlowHandlerFullPage from "../../containers/redeemFlow/RedeemFlowHandlerFullPage";
import { redeemFlowTypes } from "../../../constant/redeem";
import { useParams } from "react-router-dom";
import asyncComponent from "./components/asyncComponent";
import i18next from "i18next";

function RedeemFullPage() {
  const { t, i18n } = useTranslation(["translation"]);
  const params = useParams();
  const [purpose, setPurpose] = useState('');

  useEffect(() => {
    if (params.lang && process.env.REACT_APP_LANG.split(",").indexOf(params.lang) >= 0) {
      i18next.changeLanguage(params.lang);
      document.title =
        t(`tabTitle.programme.${currentBu}`) +
        " | " +
        t(`tabTitle.${currentBu}`);
    }
    if (params.purpose) {
      setPurpose(params.purpose);
    }


    return;
  }, [params]);


  const currentBu = process.env.REACT_APP_BU ?? "hk";



  const RendeRedeemPageOptions = asyncComponent(() => {
    return import(
      `./components/nextStepOptions/${currentBu}/RedeemPageOptionRender`
    );
  });


  return (
    <PageContainer>
      <Spacer y={72} />
      <RendeRedeemPageOptions renderOption={purpose} />
    </PageContainer>
  );
}

export default withTranslation()(RedeemFullPage);
