export const landingVideoJson = {
  assessmentVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltcc7e99b7c640199e/638841d13aa3e030e0e59263/FWD_Mind_Strength_Assessment_Test_English_for_TH_20221130_v2.mp4",
  },
  learningVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
  },
  learningCenterVideoList: [
    {
      videoKey: 'blt819490bf6bce7c7b',
      presenterName: "Khun Surichaya Habanananda",
      presenterTitle: "Yoga Instructor",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt4e68911f4629a846/63b3ceae5e9d430c0f495dbd/MicrosoftTeams-image_(4).png",
      thumbnailObjectPosition: "0",
      caption: "Strengthen your mind in 10 minutes",
      videoLength: "3:23",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt819490bf6bce7c7b/63b3d0237335502078c03468/%E0%B9%80%E0%B8%AA%E0%B8%A3%E0%B8%B4%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B9%88%E0%B8%87%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B9%83%E0%B8%88%E0%B9%83%E0%B8%99_10_%E0%B8%99%E0%B8%B2%E0%B8%97%E0%B8%B5_Mind_Strength_Final_VDO_20221223.mp4",
    },
    {
      videoKey: 'blt74d56f14193f955f',
      presenterName: "Khun Boonyawat Somtas and Khun Krittaya Pakanant",
      presenterTitle: "Founders of Krodyle Mindfulness House",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/bltfc68a9fffb05198f/63b3ceaec4bb1011c9ca6b68/MicrosoftTeams-image_(3).png",
      caption: "4 Shortcuts to Mental Shield",
      thumbnailObjectPosition: "0",
      videoLength: "4:27",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt74d56f14193f955f/63b3d04bbe2db12f870562bf/4_%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B8%A5%E0%B8%B1%E0%B8%94_%E0%B9%80%E0%B8%AA%E0%B8%A3%E0%B8%B4%E0%B8%A1%E0%B9%80%E0%B8%81%E0%B8%A3%E0%B8%B2%E0%B8%B0%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B9%83%E0%B8%88_Mind_Strength_Final_VDO_20221223.mp4",
    },
  ],
};
