import {
  makeStyles, ThemeProvider, createTheme,
  withStyles, TextField,
  Button,

} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllSurveyList } from "../api/survey";
import { COLORS } from "../constant/colors";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/zh-hk";
import "moment/locale/th";
import "moment/locale/vi";
import jwt from "jsonwebtoken";
import { forEach } from "lodash";
import sampleGif from "../assets/video-to-gif-sample.gif";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    margin: 100
  },
  section: {
    marginBottom: 50
  },
  itemRow: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-evenly',
    "& *": {
      borderColor: COLORS.fwdGrey,
    },
  },
  datepickerItem: {
    width: '35%'
  },
  inputTextfield: {
    width: "100%",
    paddingRight: 50
  },
  goButtonText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
  },
  goButton: {
    background: COLORS.fwdOrange,
    color: COLORS.white,
    height: 50,
    width: "20%",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: COLORS.fwdOrange20,
    },
    "&:disabled": {
      background: COLORS.fwdOrange20,
      color: COLORS.white,
    },
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
  },
  errorSvg: {
    width: 24,
    height: 24,
    marginRight: 7,
  },
  errorMsg: {
    color: COLORS.fwdAlertRed,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
  },
  result: {
    fontSize: 20,
    fontWeight: 700,
    color: COLORS.fwdDarkGreen
  }

}));



const keyboardDatePickerTheme = createTheme({
  palette: {
    primary: { main: COLORS.fwdOrange, contrastText: "#fff" },
    secondary: { main: COLORS.fwdOrange20 },
  },
});
const CustomerCSSKeyboardDatePicker = withStyles({
  root: {
    "& label.Mui-focused": {
      color: COLORS.fwdDarkGreen,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.fwdGrey,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&:hover fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.fwdGrey,
      },
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: COLORS.fwdDarkGreen,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.fwdAlertRed,
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.fwdOrange,
    },
  },
})(DatePicker);

const getThoughtFullUrlForID = (clientIds) => {
  const clientIdList = clientIds.split(',');
  var resultUrlList = [];
  clientIdList.forEach((clientId) => {

    const jwtToken = generate_token_with_365(
      clientId,
      "FWDID",
      "0tkLhdCEh7fTaz0z"
    );
    const partnerId = "542466";
    resultUrlList.push(`https://thoughtfull.app.link/Srn94M9kuub?tf_partner_id=${partnerId}&token=${jwtToken}`);
  });

  return resultUrlList;
};


const getThoughtFullJWTForID = (clientIds) => {
  const clientIdList = clientIds.split(',');
  var resultJWTList = [];
  clientIdList.forEach((clientId) => {

    const jwtToken = generate_token_with_365(
      clientId,
      "FWDID",
      "0tkLhdCEh7fTaz0z"
    );
    const partnerId = "542466";
    resultJWTList.push(`${jwtToken}`);
  });

  return resultJWTList;
};


export const generate_token_with_365 = (uid, organizationCode, secret) => {
  const expiresIn = "365d";
  return jwt.sign({ uid: uid, organization_code: organizationCode }, secret, {
    algorithm: "HS256",
    expiresIn: expiresIn,
  });
};


function Admin() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [surveyLength, setAllSurveyLength] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clientIdForJWT, setClientIdForJWT] = useState();
  const [tfLinkForID, setTFLinkForID] = useState([]);
  return (
    <div className={classes.content} >

      <p>
        Admin Page
      </p>

      <div className={classes.section}>
        <p>Number of assessment test record by date range : <span className={classes.result}>{surveyLength}</span></p>

        <div className={classes.itemRow}>
          <div className={classes.datepickerItem}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={i18n.language}
            >
              {" "}
              <ThemeProvider theme={keyboardDatePickerTheme}>
                <CustomerCSSKeyboardDatePicker
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label={"From"}
                  disableFuture
                  value={startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(date) => {
                    setIsError(false);
                    setStartDate(date);
                  }}
                  error={isError}
                  format="DD/MM/yyyy"
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.datepickerItem}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={i18n.language}
            >
              {" "}
              <ThemeProvider theme={keyboardDatePickerTheme}>
                <CustomerCSSKeyboardDatePicker
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label={"To"}
                  disableFuture
                  value={endDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(date) => {
                    setIsError(false);
                    setEndDate(date);
                  }}
                  format="DD/MM/yyyy"
                  error={isError}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
          <Button
            className={classes.goButton}
            onClick={async () => {
              setAllSurveyLength(await getAllSurveyList(moment(startDate).format('yyyy-MM-DD'), moment(endDate).add(1, 'd').format('yyyy-MM-DD')))
            }}
          >
            <div className={classes.goButtonText}>
              {"Search"}
            </div>
          </Button>

        </div>
      </div>

      <div className={classes.section}> <div>Gerenate TF link with JWT for ID</div>
        <div className={classes.itemRow}>
          <TextField className={classes.inputTextfield} onChange={(e) => {
            setClientIdForJWT(e.target.value
            )
          }} />

          <Button
            className={classes.goButton}
            onClick={async () => {
              setTFLinkForID(getThoughtFullJWTForID(clientIdForJWT));
            }}
          >
            <div className={classes.goButtonText}>
              {"Generate"}
            </div>
          </Button>

        </div>
        <div>
          {tfLinkForID.map((item) => {

            return (
              <div>{item}</div>

            );
          })}
        </div>



      </div>

      <div>
        <div>Testing gif in asset folder</div>
        <img src={sampleGif} />

        <div>Testing gif in cloudfront</div>
        <img src={'https://d3n3ubazdauzol.cloudfront.net/temp/video-to-gif-sample.gif'} />

        <div>Testing video in cloudfront</div>

        <ReactPlayer
          className={"videoClass"}
          url={"https://d3n3ubazdauzol.cloudfront.net/temp/sample-5s.mp4"}
          controls={true}
          width={"100%"}
          height={"auto"}
          playsinline={true}
          playing={true}

        />
      </div>

    </div>
  );
}

export default Admin;
