const RiskLevelOptionArray = {
  1: [
    { component: "ThoughfullPsychiatristOption" },
    { component: "ThoughfullPsychologistOption" },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },
  ],
  2: [
    {
      component: "ThoughfullPsychiatristOption",
    },
    { component: "ThoughfullPsychologistOption" },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },
    { component: "EmergencyOption" },
  ],
  3: [
    {
      component: "ThoughfullPsychologistOption",
    },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },
    {
      component: "SimplifiedPsychiatristOption",

      desktopWidth50: true,
    },
    { component: "EmergencyOption", desktopWidth50: true },
  ],
  4: [
    {
      component: "ThoughfullPsychologistOption",
    },
    {
      component: "SimplifiedPsychiatristOption",

      desktopWidth50: false,
    },
    { component: "ThoughfullCoachOption" },
    { component: "SelfCareOption" },

    { component: "EmergencyOption", desktopWidth50: false },
  ],
  5: [
    {
      component: "SelfCareOption",
    },
    { component: "ThoughfullCoachOption" },
    {
      component: "ThoughfullPsychologistOption",
    },
    {
      component: "SimplifiedPsychiatristOption",

      desktopWidth50: true,
    },
    { component: "EmergencyOption", desktopWidth50: true },
  ],
  6: [
    {
      component: "SelfCareOption",
    },
    {
      component: "ThoughfullPsychologistOption",
    },
    { component: "ThoughfullCoachOption" },
    {
      component: "SimplifiedPsychiatristOption",

      desktopWidth50: true,
    },
    { component: "EmergencyOption", desktopWidth50: true },
  ],
};

export default RiskLevelOptionArray;
