export const landingVideoJson = {
  assessmentVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt0e728e835ca9191b/638463a17a64f810a2988f02/FWD_EN_HK_25Nov.mp4",
  },
  learningVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
  },
  learningCenterVideoList: [
    {
      videoKey: 'blt0478dc5691661969',
      presenterName: "Dr. Keith Hariman",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt469825fb3e9c976e/635f5f9cff7b405f6b3f4737/MicrosoftTeams-image_(2).png",
      caption:
        "Shyness vs. Socially Anxious: helping your socially-anxious child cope interacting with others",
      videoLength: "6:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt0478dc5691661969/635f60b8da572d57ecd273e2/V_FWD_MAX_Content_Video_Dr_Lee_Topic_2_Social_Anxiety_en_v1_final_OP_2_(1).mp4",
    },
    {
      videoKey: 'blt88d2272f8c9f3072',
      presenterName: "Dr. Leung Chung Ming",
      presenterTitle: "Registered Clinical Psychologist",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt8feb956f1324e76e/635f5f9ce63a425d5dc5bf85/MicrosoftTeams-image_(4).png",
      caption:
        "Families abroad: mind strong together with families that stayed behind",
      videoLength: "5:30",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt88d2272f8c9f3072/635f3d871012fd770008576f/FWD_MAX_Content_Video_Dr_Leung_Topic_3_Immigration_en_v3_final_OP_2_(1)_(1).mp4",
    },

    {
      videoKey: 'blte1e0aabcd64b6ff1',
      presenterName: "Dr. Cheung Hon Kee",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt1fa8375920486fa8/6384605ab71d091081d11873/MicrosoftTeams-image_(5).png",
      caption:
        "Lying flat: helping adult children get back their motivation to find new life goals and dreams",
      videoLength: "4:22",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blte1e0aabcd64b6ff1/638460f276567a10a7cbd0d1/FWD_MAX_Content_Video_Dr_Cheung_Topic_1_Lying_Flat_EN_v4_final_OP.mp4",
    },

    {
      videoKey: 'blt5f6eba497fbba0a7',
      presenterName: "Dr. Mak Kai Lok",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/bltcdba5f9aeb9f0d29/6384605e5bbc641099350efa/MicrosoftTeams-image_(3).png",
      caption:
        "Don't take work stress home with you! How to manage negative emotions at work.",
      videoLength: "6:46",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt5f6eba497fbba0a7/638460ee7140e510ae4aa840/FWD_MAX_Content_Video_Dr_Mak_Topic_3_Negative_emotion_from_work_EN_v5_final_OP.mp4",
    },
    {
      videoKey: 'blt8c0a58eeba748976',
      presenterName: "Dr. Keith Hariman",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt984af58d15df28be/63c513a57d45377a0778e39d/Dr_Lee_Topic_1_messy_room_thumbnail_2.jpg",
      caption:
        "What a messy room can tell you about your loved one’s mental health?",
      videoLength: "5:13",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt8c0a58eeba748976/63c763228862ba419d3bc756/FWD_MAX_Content_Video_Dr_Lee_Topic_1_messy_room_EN_v4_final.mp4",
    },
    {
      videoKey: 'blt1dcf9753a8ee8806',
      presenterName: "Dr. Cheung Hon Kee",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt4d9db0d334da79e8/63c5139bca4ee4418bab9566/Dr_Cheung_Topic_2_highly_sensitive_children_thumbnail_1.jpg",
      caption:
        "Learn more about what highly sensitive children are thinking",
      videoLength: "4:16",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt1dcf9753a8ee8806/63c50b720f5896167c71c821/FWD_MAX_Content_Video_Dr_Cheung_Topic_2_highly_sensitive_children_EN_v4_final.mp4",
    },
    {
      videoKey: 'blte4374ea1a8f4672a',
      presenterName: "Dr. Leung Chung Ming",
      presenterTitle: "Registered Clinical Psychologist",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt8b456f556b206dad/63c513a0e5cf0162d281d2c3/Dr.leung_Topic2_holiday_mood_thumbnail_1.jpg",
      caption:
        "Helping your loved ones refocus and rediscover their motivation after the holidays",
      videoLength: "5:22",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blte4374ea1a8f4672a/63c50b994977081097259b52/FWD_Max_Content_Video_Dr.leung_Topic2_holiday_mood_EN_v2_final.mp4",
    },
    {
      videoKey: 'blt2ac91553d6efbb1f',
      presenterName: "Dr. Mak Kai Lok",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt481e45e01dd5be87/63c513aad1f34304558a32ab/Dr_Mak_Topic_1_Night_Shift_thumbnail_2.jpg",
      caption:
        "How night-shift work affects family dynamics and their mind strength?",
      videoLength: "5:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt2ac91553d6efbb1f/63c50b7148ef12105ee3a1df/FWD_MAX_Content_Video_Dr_Mak_Topic_1_Night_Shift_EN_v1_final.mp4",
    },
    {
      videoKey: 'bltde49be557f4066aa',
      presenterName: "Dr. Mak Kai Lok",
      presenterTitle: "Specialist in Psychiatry",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt2a3b5a20a4b02d5e/63c50e811779f2026027c828/DrMak_Topic2_thumbnail_2.jpg",
      caption:
        "Would excessive use of mobile phone cause brain damage or memory impairment?",
      videoLength: "5:34",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/bltde49be557f4066aa/63c514c97f7baa7b8ce5b0a8/FWD_MAX_Content_Video_Dr_Mak_Topic_2_addicted_to_phone_EN_v4_final_OP.mp4",
    },
    {
      videoKey: 'blt905effa22f8a89fe',
      presenterName: "Dr. Leung Chung Ming",
      presenterTitle: "Registered Clinical Psychologist",
      thumbnail:
        "https://images.contentstack.io/v3/assets/blt982dbc2422e29419/blt67a1ee46b9132747/63c50e81e65e7910bab89bd0/Dr.leung_Topic1_thumbnail_1.jpg",
      caption:
        "How resilience in positive psychology help families bounce back and staying mind strong after a crisis?",
      videoLength: "5:44",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt905effa22f8a89fe/63c514b81864e26d0032aada/FWD_Max_Content_Video_Dr.leung_Topic1_positive_mind_EN_v2_final_OP.mp4",
    },


  ],
};
