export const footerJson = {

  "uid": "72915c72-e238-42cd-b55c-292749d539fd",
  "componentName": "Footer",
  "dataSource": "{84A1FFCB-5E06-4FEB-A796-6715B00B1320}",
  "params": {
    "backgroundColor": "orangeLighter",
    "paddingTop": "Medium",
    "paddingBottom": "None"
  },
  "fields": {
    "siteNavigationLinks": [
      {
        "id": "d2a01e53-2a01-487a-a08a-7af417401ed8",
        "name": "column-1",
        "displayName": "Column 1",
        "fields": {},
        "Children": [
          {
            "id": "b0948026-924c-431a-94c0-cf8117a48fed",
            "name": "Products",
            "displayName": "Products",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "Products"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com/mys/products",
                  "linktype": "external",
                  "url": "https://www.fwd.com/mys/products",
                  "anchor": "",
                  "target": ""
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            },
            "Children": [
              {
                "id": "f7a3be45-7210-46de-9c90-67905bce0345",
                "name": "Health Protection",
                "displayName": "Health Protection",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Health protection"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/products/health-protection/",
                      "text": "Health Protection",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "querystring": "",
                      "id": "{26AFBCBE-83A6-4251-8420-7A297BBCB680}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "1cb83034-9403-4687-b0a0-136b1d5ab3e6",
                "name": "Loss of life",
                "displayName": "Loss of life",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Loss of life"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/products/loss-of-life/",
                      "text": "Life Protection",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "querystring": "",
                      "id": "{9C8ABF60-7F14-480C-8302-2881B06F0263}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "7db1bc42-b6d9-4d0e-ab24-904195499792",
                "name": "Investments",
                "displayName": "Investments",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Investments"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/products/investments/",
                      "text": "Invest",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "querystring": "",
                      "id": "{F45CA4C1-CC7F-4D6A-8A27-3BE0EAC87298}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "6b05a68f-993b-4100-a906-9f3948475a6b",
                "name": "Savings",
                "displayName": "Savings",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Savings"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/products/savings/",
                      "text": "Savings",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "querystring": "",
                      "id": "{80749FD6-67FC-4974-9D4F-614B8307DA7B}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "id": "fb39b3dc-9a41-413b-8756-cf6127c7eea8",
        "name": "column-2",
        "displayName": "Column 2",
        "fields": {},
        "Children": [
          {
            "id": "5f48c1ac-06cd-4da5-a38e-48d980ed225d",
            "name": "Claims",
            "displayName": "Claims",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "Claims"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/claims/",
                  "linktype": "internal",
                  "id": "{176F0964-27C7-40B6-9DA5-54DA1C6CB59E}"
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            },
            "Children": [
              {
                "id": "030204f5-0c86-4387-afbe-5eb378c409a5",
                "name": "Find a form",
                "displayName": "Find a form",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Find a form"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/support/forms/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{D4F47ADA-BF79-497F-BBDF-2B269CF6001D}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "a5b17d4c-5900-48f4-85a9-c3a1f7e8422e",
                "name": "Critical illness claims",
                "displayName": "Critical illness claims",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Critical illness claims"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/claims/critical-illness-claims/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{E6CF0878-BFBC-48C5-A62C-F4AB6AD8EB4F}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "96ca9364-61d8-4ab6-b6d9-956f9e16958e",
                "name": "Loss of life claims",
                "displayName": "Loss of life claims",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Loss of life claims"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/claims/loss-of-life-claims/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{EE4A38DD-1F45-4977-A07D-6F4133540E07}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "a5e54b73-6035-4b6c-b0c4-155fb76b8d4f",
                "name": "Medical allowances claims",
                "displayName": "Medical allowances claims",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Medical allowance claims"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/claims/medical-allowance-claims/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{E8BCF460-E374-4B19-BF90-C513C0B41215}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "688a1bac-32ed-44a4-88e5-c853997fa4b9",
                "name": "Medical reimbursement claims",
                "displayName": "Medical reimbursement claims",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Medical reimbursement claims"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/claims/medical-reimbursement-claims/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{DA0BC868-E117-496F-A1A5-90532FA099DA}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "7f4831fd-fcd6-4d98-bcac-8a8acce1b962",
                "name": "Total permanent disability claims",
                "displayName": "Total permanent disability claims",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Total permanent disability claims"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/claims/total-permanent-disability-claims/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{62C2E501-B48D-490F-8786-D050FAB1DB51}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "id": "5cbb10b9-89a9-4b0a-a7c3-26c1fabe0d14",
        "name": "column-3",
        "displayName": "Column 3",
        "fields": {},
        "Children": [
          {
            "id": "ecf6529e-8462-41f2-92ae-f8aafe6d9843",
            "name": "Support",
            "displayName": "Support",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "Support"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/support/",
                  "text": "",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "",
                  "querystring": "",
                  "id": "{7D817917-32F5-4221-9CD4-88CFE7E7C4C0}"
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            },
            "Children": [
              {
                "id": "c7196112-394c-42a2-a2ef-c94d01e29edc",
                "name": "FAQs",
                "displayName": "FAQs",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "FAQs"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/support/faq/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{BDF0BAEC-3CFA-4A33-B63C-E241EF0661DA}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "a3477909-605d-41a3-8b7a-404e4592e4c8",
                "name": "Call 1300 13 7988",
                "displayName": "Call 1300 13 7988",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Call 1300 13 7988"
                  },
                  "link": {
                    "value": {
                      "href": "javascript:window.open('tel:1300137988');;return false;",
                      "text": "Call 1300 13 7988",
                      "linktype": "javascript",
                      "url": "javascript:window.open('tel:1300137988');",
                      "anchor": ""
                    }
                  },
                  "listType": {
                    "id": "fd670504-d972-4fee-9582-656b0d6ba342",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
                    "fields": {
                      "value": {
                        "value": "Call"
                      },
                      "key": {
                        "value": "call"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "be58e7ca-ae8a-4dad-95dc-a57ca59479ee",
                "name": "Online enquiries",
                "displayName": "Online enquiries",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Online enquiries"
                  },
                  "link": {
                    "value": {
                      "text": "Online enquiries",
                      "linktype": "form",
                      "querystring": "bookAnAgent",
                      "target": "",
                      "id": "{61A3597B-2BEE-4AD4-9473-92ECD5DBF4D0}",
                      "href": "https://www.fwd.com.my/formslist/general-enquiry-form"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "7608d76b-d787-47d9-946b-9c916880ca3b",
                "name": "Feedback",
                "displayName": "Feedback",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Feedback"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/support/feedback/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{80A2A725-D956-4D3D-BBE2-9CB95C2FAAF2}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "dd767d0a-f60a-44e9-abaf-92a0fc23078c",
                "name": "Locate us",
                "displayName": "Locate us",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Locate us"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/about-us/brand-story/#our-offices",
                      "text": "",
                      "anchor": "our-offices",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{C1ACA1F8-078E-4937-A8DA-B81802375BE7}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "2d8bb492-2394-4dc4-b1f4-8083ff90028f",
                "name": "Our hospital network",
                "displayName": "Our hospital network",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Our hospital network"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/hospital-locator/",
                      "text": "Our hospital network",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{3B8170E0-F577-42A6-875A-C756E83ABF6E}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              },
              {
                "id": "a17ba21b-3b9d-490c-b009-9b702d027fcd",
                "name": "Fund information",
                "displayName": "Fund information",
                "fields": {
                  "buttonIcon": {
                    "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    "fieldType": "CustomDroplink",
                    "fieldName": "buttonIcon",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    "fields": {
                      "value": {
                        "value": "chevron-right"
                      },
                      "key": {
                        "value": "chevronRight"
                      }
                    }
                  },
                  "title": {
                    "value": "Fund information"
                  },
                  "link": {
                    "value": {
                      "href": "https://www.fwd.com.my/products/invest/fund-valuation/",
                      "text": "",
                      "anchor": "",
                      "linktype": "internal",
                      "class": "",
                      "title": "",
                      "target": "",
                      "querystring": "",
                      "id": "{A01A3DBB-E764-4C26-921D-674D39A9019C}"
                    }
                  },
                  "listType": {
                    "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    "fieldType": "Droplink",
                    "fieldName": "listType",
                    "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    "fields": {
                      "value": {
                        "value": "Generic Link"
                      },
                      "key": {
                        "value": "generic_link"
                      }
                    }
                  },
                  "gaLabelName": {
                    "value": ""
                  },
                  "gaCategoryName": {
                    "value": ""
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "id": "cfd3aabd-0c83-4120-977e-7e7cd8f854c1",
        "name": "column-4",
        "displayName": "Column 4",
        "fields": {},
        "Children": [
          {
            "id": "91beb8ff-a15a-40de-a714-4bd517326f45",
            "name": "About us",
            "displayName": "About us",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "About us"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/about-us/",
                  "text": "",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "",
                  "querystring": "",
                  "id": "{E29AE3B4-0EF7-4A19-94D0-5A773DA078BF}"
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          },
          {
            "id": "9481e12b-94d9-454a-acd7-2c9f4cdf2a7c",
            "name": "Join us",
            "displayName": "Join us",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "Join us"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/careers/",
                  "text": "",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "",
                  "target": "",
                  "querystring": "",
                  "id": "{A61D2946-60B9-4D8B-8BDC-003EE572A248}"
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          },
          {
            "id": "7645f830-98e7-47e7-96c3-6d5c79f49d6e",
            "name": "News releases",
            "displayName": "News releases",
            "fields": {
              "buttonIcon": {
                "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                "fields": {
                  "value": {
                    "value": "chevron-right"
                  },
                  "key": {
                    "value": "chevronRight"
                  }
                }
              },
              "title": {
                "value": "News releases"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/press/",
                  "text": "",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "",
                  "target": "",
                  "querystring": "",
                  "id": "{9C7F0FF7-348D-4B78-8E7B-88321D26DC76}"
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          }
        ]
      }
    ],
    "countrySelector": [
      {
        "id": "fc1eb478-c5ad-47a7-8aea-864133ac63db",
        "name": "FWD Group",
        "displayName": "FWD Group",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "FWD Group"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com/",
              "text": "FWD Group",
              "linktype": "external",
              "url": "https://www.fwd.com/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "2a8c3247-1efb-4c5c-9188-46aff2bc4af2",
        "name": "Cambodia",
        "displayName": "Cambodia",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Cambodia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.kh/",
              "text": "Cambodia",
              "linktype": "external",
              "url": "https://www.fwd.com.kh/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "f281af72-fa4b-4f87-98f1-4b9d77180e60",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "6155fb51-0c83-4f83-81a0-d3cc4c6915c1",
        "name": "Hong Kong",
        "displayName": "Hong Kong",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Hong Kong"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.hk/en/",
              "text": "Hong Kong",
              "linktype": "external",
              "url": "https://www.fwd.com.hk/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "f281af72-fa4b-4f87-98f1-4b9d77180e60",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "b9a14c94-20c4-47c8-96da-d4ef066f66ec",
        "name": "Indonesia",
        "displayName": "Indonesia",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Indonesia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.co.id/",
              "text": "Indonesia",
              "linktype": "external",
              "url": "https://www.fwd.co.id/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "c6a85dde-9c2e-40a0-b81a-b772285b262f",
        "name": "Japan",
        "displayName": "Japan",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Japan"
          },
          "link": {
            "value": {
              "href": "http://www.fwdlife.co.jp",
              "linktype": "external",
              "url": "http://www.fwdlife.co.jp",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "68fb5672-9cfe-4c6e-b047-8837286a4d16",
        "name": "Macau",
        "displayName": "Macau",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Macau"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.vn/en/",
              "text": "Macau",
              "linktype": "external",
              "url": "https://www.fwd.com.vn/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "6f4be9dc-4cfa-4ec3-bbe2-1f690c2342cc",
        "name": "Malaysia",
        "displayName": "Malaysia",
        "fields": {
          "isDefault": {
            "value": true
          },
          "buttonIcon": null,
          "title": {
            "value": "Malaysia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/",
              "text": "",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "",
              "querystring": "",
              "id": "{ED1C1C9C-BAFE-45EF-ABDD-C1BDE7D278BF}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "2efe1c70-1706-48dd-a7b6-13185fde4ecc",
        "name": "Philipines",
        "displayName": "Philipines",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Philipines"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.ph/",
              "text": "Philipines",
              "linktype": "external",
              "url": "https://www.fwd.com.ph/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "875e163c-ffe8-4f80-be37-48221f095a82",
        "name": "Singapore",
        "displayName": "Singapore",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Singapore"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.sg/",
              "text": "Singapore",
              "linktype": "external",
              "url": "https://www.fwd.com.sg/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "28ff0713-bc7c-49b1-b59b-dc5bd28e3c3c",
        "name": "Thailand",
        "displayName": "Thailand",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Thailand"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.co.th/",
              "text": "Thailand",
              "linktype": "external",
              "url": "https://www.fwd.co.th/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "44505fa1-98a3-4bf8-bfc3-d14ac8b51a48",
        "name": "Vietnam",
        "displayName": "Vietnam",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Vietnam"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.vn/en/",
              "text": "Vietnam",
              "linktype": "external",
              "url": "https://www.fwd.com.vn/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "socialMediaLinks": [
      {
        "id": "bd521692-b585-4b4c-bd29-356108e1c90e",
        "name": "Facebook",
        "displayName": "Facebook",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Facebook"
          },
          "link": {
            "value": {
              "href": "https://www.facebook.com/fwd.takaful/",
              "text": "facebook",
              "linktype": "external",
              "url": "https://www.facebook.com/fwd.takaful/",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "a10aba02-15b6-4c9e-912e-39b75a9dbe2f",
        "name": "Instagram",
        "displayName": "Instagram",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Instagram"
          },
          "link": {
            "value": {
              "href": "https://instagram.com/fwdtakaful",
              "text": "twitter",
              "linktype": "external",
              "url": "https://instagram.com/fwdtakaful",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "78da369e-a10e-4521-ae6a-9686e4960995",
        "name": "WhatsApp",
        "displayName": "WhatsApp",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "WhatsApp"
          },
          "link": {
            "value": {}
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "626d30ca-e478-4c89-956d-f7726de0f8bb",
        "name": "YouTube",
        "displayName": "YouTube",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Youtube"
          },
          "link": {
            "value": {
              "href": "https://www.youtube.com/channel/UChNt9gRA2f1w49ZZQWjLhjQ?view_as=subscriber",
              "text": "youtube",
              "linktype": "external",
              "url": "https://www.youtube.com/channel/UChNt9gRA2f1w49ZZQWjLhjQ?view_as=subscriber",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "footerQuickLinks": [
      {
        "id": "2a6ee04f-9d16-4465-98b2-e1e1f585cd43",
        "name": "Sitemap",
        "displayName": "Sitemap",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Sitemap"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/sitemap/",
              "text": "Sitemap",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Sitemap",
              "querystring": "",
              "id": "{2C9306E1-B426-49E2-B2F7-26D552F238A3}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "f2923fc8-51f9-48f3-a0ad-088dd71aa431",
        "name": "Online security",
        "displayName": "Online security",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Online security"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/online-security/",
              "text": "Online security",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Data Protection Policy",
              "target": "",
              "querystring": "",
              "id": "{B77B47F5-0206-49EE-A6F2-8D6B609EAA40}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "fa98bdca-3a55-406d-9027-c697c3b0f70f",
        "name": "Terms and conditions",
        "displayName": "Terms and conditions",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Terms & Conditions"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/terms-conditions/",
              "text": "Terms and conditions",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Data Protection Policy",
              "target": "",
              "querystring": "",
              "id": "{CDF8D4C9-32B0-48A7-8F48-72A3FA2B679B}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "2055cc99-5701-41fc-a1b3-fc278619f0bf",
        "name": "Privacy policy",
        "displayName": "Privacy policy",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Privacy policy"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/tkfl-privacy-policy/",
              "text": "Privacy policy",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Privacy Policy",
              "target": "",
              "querystring": "",
              "id": "{FDFB7B45-FF89-42E7-B0AD-ACBF25D61708}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "366b4ecd-4ed2-4a7c-b99b-93967b805582",
        "name": "Cookie policy",
        "displayName": "Cookie policy",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Cookie policy"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/cookie-policy/",
              "text": "Cookie policy",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Cookie Policy",
              "target": "",
              "querystring": "",
              "id": "{BC4C1B52-43D2-4796-A417-ED4BA9FF25CD}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "1c030eed-5359-4808-bcaa-b6a8575f634f",
        "name": "Personal data protection policy",
        "displayName": "Personal data protection policy",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Personal data protection policy"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/tkfl-personal-data-protection-notice/",
              "text": "Personal data protection policy",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Personal data protection policy",
              "target": "",
              "querystring": "",
              "id": "{A3889559-EE87-4039-A814-8515D709856F}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "948b847d-4e66-45b8-902f-12b779caadcd",
        "name": "Common reporting standard",
        "displayName": "Common reporting standard",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Common reporting standard"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/important-notice/crs-customer-faq/",
              "text": "Common reporting standard",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "Common reporting standard",
              "target": "",
              "querystring": "",
              "id": "{3D693352-6607-47A1-AC7C-5655E31670D4}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "b663fa09-adb0-48e4-a28a-c02ff18ff4dc",
        "name": "Important notice",
        "displayName": "Important notice",
        "fields": {
          "buttonIcon": {
            "id": "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            "fields": {
              "value": {
                "value": "chevron-right"
              },
              "key": {
                "value": "chevronRight"
              }
            }
          },
          "title": {
            "value": "Important notice"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/important-notice/",
              "text": "",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "",
              "querystring": "",
              "id": "{BE103AFE-950D-4576-AA2B-5A46A20B8E04}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "copyrightLabel": {
      "value": "<p>Copyright 2022 FWD Takaful Berhad. All rights reserved.<br />\nFWD Takaful Berhad, Registration No. 200601011780 (731530-M)</p>"
    },
    "logo": {
      "value": {
        "src": "https://www.fwd.com.my/-/media/global/images/fwd-logo_colour.svg?rev=77f0e92219254f9caea8bbe5bba16478",
        "alt": "FWD logo svg"
      }
    },
    "logoLink": {
      "value": {
        "href": "https://www.fwd.com/mys/home",
        "linktype": "external",
        "url": "https://www.fwd.com/mys/home",
        "anchor": "",
        "target": ""
      }
    },
    "icon": {
      "id": "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
      "fieldType": "CustomDroplink",
      "fieldName": "icon",
      "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/call",
      "fields": {
        "value": {
          "value": "call"
        },
        "key": {
          "value": "Call"
        }
      }
    },
    "call": {
      "value": "1300 13 7988"
    },
    "enableCallingHoursText": {
      "value": false
    },
    "callingHoursTextBeforeTooltip": {
      "value": ""
    },
    "callingHoursTooltip": {
      "value": ""
    },
    "callingHoursTextAfterTooltip": {
      "value": ""
    },
    "defaultComplianceAccreditation": null,
    "disableComplianceAccreditation": {
      "value": false
    },
    "gaLabelName": {
      "value": ""
    },
    "gaCategoryName": {
      "value": ""
    },
    "enableLanguageToggle": {
      "value": true
    },
    "languageList": [
      {
        "id": "440955d7-719b-4fe5-9d42-37fd2c4a9083",
        "fields": {
          "languageName": {
            "value": "EN"
          },
          "defaultLanguage": {
            "value": "Bm"
          },
          "languageCode": {
            "value": "en"
          }
        }
      }
    ],
    "languageModalDescription": {
      "value": ""
    },
    "languageModalLink": {
      "value": {}
    },
    "languageModalSecondaryLink": {
      "value": ""
    },
    "languageModalTitle": {
      "value": ""
    }
  },
  sitecoreContext: {
    language: "EN",
    languageCode: "en",
    availableLanguages: ["en"],
  },
};
