import { Dialog, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import learningCenterIconSvg from "../../../assets/landing/learningCenterIcon.svg";
import ReactPlayer from "react-player";
import videoPlaySvg from "../../../assets/landing/videoPlay.svg";

import LearningCenterVideoListSections from "./LearningCenterVideoListSections";
import { MaxWidth } from "../../../constant/stylesSize";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";
import DialogCloseButtonRow from "../../components/DialogCloseButtonRow";

const useStyles = makeStyles((theme) => ({
  learningCenterContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    background: COLORS.fwdOrange20,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      alignItems: "center",
    },
  },
  contentContainer: {
    maxWidth: MaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "flex-start",
    },
  },

  sectionHeaderRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: "center",
  },
  sectionHeader: {
    display: "flex",
    flexGrow: 1,
    color: COLORS.fwdDarkGreen,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
    marginLeft: 20,
  },
  caption: {
    color: COLORS.fwdDarkGreen,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    marginBottom: 15,
    paddingTop: 20,
  },
  details: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
  },
  detailsWithVideo: {
    [theme.breakpoints.up("md")]: {
      flex: 3,
      // padding: 20,
      // paddingLeft: 40,
    },
  },
  videoContainer: {
    marginTop: 20,
    marginBottom: 20,
    position: "relative",

    "& .videoClass video": {
      borderRadius: 16,
      overflow: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      flex: 2,
      marginRight: 20,
    },
  },
  videoPlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  videoWithDetails: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
    },
  },
  remarksSection: {
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
  },
  linkText: {
    color: COLORS.fwdOrange,
    cursor: "pointer",
  },
  dialogContent: {
    color: COLORS.fwdDarkGreen,
  },
}));

function LearningCenterContainer() {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  const [videoPlaying, setVideoPlaying] = useState(false);

  const paramsFile = require(`../../../constant/landingJson/landingVideoJson_${currentBu}_${i18n.language}`);

  const params = paramsFile["landingVideoJson"];

  const currentVideo = params.learningVideo;

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.learningCenterContainer} id="learningCenter">
        <div className={classes.contentContainer}>
          <div className={classes.sectionHeaderRow}>
            <img src={learningCenterIconSvg} alt={"Learning Center Icon"} />
            <div className={classes.sectionHeader}>{t("productLanding.learningCenter.sectionHeader")}</div>
          </div>
          <div className={classes.caption}>{t("productLanding.learningCenter.getProactiveSectionCaption")}</div>
          <div className={classes.videoWithDetails}>
            <div className={clsx([classes.details, classes.detailsWithVideo])}>
              {t(`productLanding.learningCenter.getProactiveSectionDetails.${currentBu}`)}
            </div>

            {/* <div className={classes.videoContainer}>
              <ReactPlayer
                className={"videoClass"}
                url={currentVideo.videoUrl}
                controls={true}
                width={"100%"}
                height={"auto"}
                playsinline={true}
                playing={videoPlaying}
                onPlay={() => {
                  setVideoPlaying(true);
                }}
              />
              {!videoPlaying && (
                <img
                  alt={"Video Play Button Icon"}
                  src={videoPlaySvg}
                  className={classes.videoPlayIcon}
                  onClick={() => {
                    setVideoPlaying(true);
                  }}
                />
              )}
            </div> */}
          </div>

          <div className={classes.caption}>{t("productLanding.learningCenter.learnFromBestCaption")}</div>
          <div className={classes.details}>{ReactHtmlParser(t(`productLanding.learningCenter.learnFromBestDetails.${currentBu}`))}</div>

          <LearningCenterVideoListSections />

          <div className={classes.remarksSection}>
            <div>{t("productLanding.learningCenter.remarksTitle")}</div>
            <ol>
              <li>
                <span>{t(`productLanding.learningCenter.remark1_1.${currentBu}`)}</span>

                <span className={classes.linkText} onClick={handleClickOpen}>
                  {t("productLanding.learningCenter.remark1_2")}
                </span>
                <span>{t("productLanding.learningCenter.remark1_3")}</span>
              </li>
              <li>
                <span>{t(`productLanding.learningCenter.remark2_1.${currentBu}`)}</span>
              </li>

            </ol>

          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogCloseButtonRow handleClose={handleClose} />
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}>
            <div className={classes.dialogContent}>{ReactHtmlParser(t(`poweredBySection.koaHealthIntro.${currentBu}`))}</div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LearningCenterContainer;
