import React from "react";
import { withStyles } from "@material-ui/core";

const AppIcon = ({ classes, imageSrc, additionalClass }) => (
  <img className={`${classes.defaultClass} ${additionalClass}`} src={imageSrc}></img>
);

const styles = {
  defaultClass: {
    height: 'auto',
    width: 64,
    objectFit: 'contain'
  },
};

export default withStyles(styles)(AppIcon);
