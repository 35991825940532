import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import TagManager from "react-gtm-module";

Amplify.configure(awsExports);

console.log(`process.env.REACT_APP_GTM_ID = ${process.env.REACT_APP_GTM_ID}`);
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ?? "",
};
TagManager.initialize(tagManagerArgs);

const UnsecuredPage: FunctionComponent = (): ReactElement => (
  <div style={{ color: "red" }}>
    If you see this page, Webb App link you have clicked on is under
    Clickjacking security attack.
  </div>
);

// Fix pen test Clickjacking attack
// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top) {
  // ReactDOM.render(<WrappedApp />, document.getElementsByClassName('app')[0]);
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<UnsecuredPage />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
