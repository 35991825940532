const NextButtonSvgComponent = ({ fill = "#E87722" }) => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.647 0.439124L8.293 0.792624L14.5855 7.08462L0 7.08462L0 9.08462L14.5865 9.08462L8.2935 15.3781L8.647 15.7311C9.2325 16.3171 10.1825 16.3171 10.7685 15.7311L18.414 8.08512L10.768 0.439124C10.1825 -0.146376 9.2325 -0.146376 8.647 0.439124Z"
        fill={fill}
      />
    </svg>
  );
};

export default NextButtonSvgComponent;
