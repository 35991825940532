import TagManager from "react-gtm-module";

const triggerGAOnSuccessfulValidation = ({ language, yearOfBirth }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'validate',
      buttonTitle: "go",
      yearOfBirth,
      pageUrl: window.location.href,
      language,
      componentName: "button",
      customer_type: "customer"
    },
  });
};

const triggerGAOnClickNonCustomerSkipNow = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'validate',
      buttonTitle: "not customer skip now",
      pageUrl: window.location.href,
      language,
      componentName: "button",
      customer_type: "non-customer"
    },
  });
};

const triggerGAOnClickGetFreeTrialInValidationPopup = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: "get one month free trial",
      pageUrl: window.location.href,
      language,
      componentName: "validation pop-up",
    },
  });
};

const triggerGAOnClickDownloadTfPopupValidated = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: "Thoughtfull",
      pageUrl: window.location.href,
      language,
      componentName: "validation pop-up",
    },
  });
};

const triggerGAOnClickDownloadTfPopupTrial = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: "Thoughtfull",
      pageUrl: window.location.href,
      language,
      componentName: "free trial pop-up",
    },
  });
};


const triggerGAOnClickDownloadDAAppLink = ({ language, componentName = "lead form success page" }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: "Doctor Anywhere app",
      pageUrl: window.location.href,
      language,
      componentName: componentName,
    },
  });
};



const triggerGAOnClickDownloadOmneIos = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: 'Omne',
      pageUrl: window.location.href,
      language,
      componentName: 'omne ios appstore',
    },
  });
};

const triggerGAOnClickDownloadOmneAndroid = ({ language }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: 'Omne',
      pageUrl: window.location.href,
      language,
      componentName: 'omne android google play',
    },
  });
};

const triggerGAOnClickLearningCenter = ({ language, destination, buttonTitle = "Learn from the experts", customerType = "non-customer" }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "buttonClick",
      buttonTitle: buttonTitle,
      option: "Selfcare learning center",
      pageUrl: window.location.href,
      language,
      componentName: "button",
      destinationURL: destination,
      customer_type: customerType
    }
  });
};

const triggerGAOnCardButtonClick = ({ language, buttonTitle, option }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: buttonTitle,
      option: option,
      pageUrl: window.location.href,
      language,
      componentName: "button",
    },
  });
};

const triggerGAOnLeadFormSubmitClick = ({ language, componentName }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      pageUrl: window.location.href,
      buttonTitle: 'go',
      language,
      componentName: componentName
    },
  });
};

const triggerGAOnProductCardClick = ({ language, customerType = "non-customer", destinationURL, productName }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buttonClick',
      buttonTitle: 'product card learn more',
      option: 'see our mind strength products',
      pageUrl: window.location.href,
      componentName: 'product page link',
      language,
      productName,
      destinationURL,
      customer_type: customerType
    },
  });
};

export {
  triggerGAOnSuccessfulValidation,
  triggerGAOnClickGetFreeTrialInValidationPopup,
  triggerGAOnClickDownloadTfPopupValidated,
  triggerGAOnClickDownloadTfPopupTrial,
  triggerGAOnClickDownloadOmneIos,
  triggerGAOnClickDownloadOmneAndroid,
  triggerGAOnClickLearningCenter,
  triggerGAOnCardButtonClick,
  triggerGAOnLeadFormSubmitClick,
  triggerGAOnClickDownloadDAAppLink,
  triggerGAOnClickNonCustomerSkipNow,
  triggerGAOnProductCardClick
};
