import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import SectionHeader, { sectionHeaderSizes, sectionHeaderAlignDirections } from "../../components/core-elements/SectionHeader";
import Spacer from "../../components/core-elements/Spacer";
import ActionIcon from "../../components/core-elements/ActionIcon";
import { fontSizes } from "../../../constant/font";
import ReactHTMLParser from "react-html-parser";
import OTPInput from "react-otp-input";
import Button from "../../components/core-elements/Button";
import { resendOtp, validateOtp } from "../../../api/godp";
import ErrorMessageStyled from "../../components/core-elements/ErrorMessage";
import CommonIcon from "../../components/core-elements/CommonIcon";
import { maskPhoneNumber } from "../../../util/form-validation";
import clsx from "clsx";
import { display, textAlign } from "@material-ui/system";

const OTPValidationForm = ({ classes, onGoBack, onSubmitSuccess, isFullPage = false, isEmbedComponent = false, title, phoneNumber }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [resendErrorMessage, setResendErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const [remainingTime, setRemainingTime] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  const maskedPhoneNumber = maskPhoneNumber(phoneNumber);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendDisabled(false);
      setResendErrorMessage("");
    }
  }, [remainingTime]);

  const handleResendClick = async () => {
    setErrorMessage("");
    setOtp("");
    setResendErrorMessage("");
    try {
      const response = await resendOtp(phoneNumber);
      if (response.isSendOTPSuccess) {
        setResendErrorMessage("");
        setRemainingTime(60);
        setIsResendDisabled(true);
      } else {
        setResendErrorMessage(t(`eTriageLanding.otpValidation.${currentBu}.failToResendOtp`));
        setRemainingTime(60);
        setIsResendDisabled(true);
      }
    } catch (err) {
      setResendErrorMessage(t(`eTriageLanding.otpValidation.${currentBu}.failToResendOtp`));
      setRemainingTime(60);
      setIsResendDisabled(true);
    }
  };

  const onSubmitValidateOtp = async () => {
    setIsLoading(true);
    setResendErrorMessage("");
    try {
      const response = await validateOtp(phoneNumber, otp);
      if (response.isValidateOTPSuccess) {
        onSubmitSuccess();
      } else {
        setIsLoading(false);
        setErrorMessage(t(`eTriageLanding.otpValidation.${currentBu}.incorrectOTPError`));
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(t(`eTriageLanding.otpValidation.${currentBu}.incorrectOTPError`));
    }
  };

  return (
    <div className={classes.container}>
      {isEmbedComponent && (
        <div className={classes.backIconContainer}>
          <ActionIcon type="arrowLeft" onClick={onGoBack} />
        </div>
      )}
      <SectionHeader
        text={title ?? t(`eTriageLanding.otpValidation.${currentBu}.title`)}
        size={isFullPage ? sectionHeaderSizes.large : sectionHeaderSizes.medium}
        alignDirection={isFullPage ? sectionHeaderAlignDirections.center : sectionHeaderAlignDirections.left}
        onGoBack={isFullPage && onGoBack}
      />
      <div className={classes.caption}>
        {t(`eTriageLanding.otpValidation.${currentBu}.caption`) + process.env.REACT_APP_LEAD_FORM_API_COUNTRY_CALLING_CODE + ' ' + maskedPhoneNumber}
      </div>

      <div className={classes.formContainer}>
        <Spacer y={24} />
        <OTPInput
          shouldAutoFocus
          inputType="tel"
          value={otp}
          onChange={(event) => {
            setOtp(event);
            setIsLoading(false);
            setErrorMessage("");
          }}
          renderInput={(props) => <input {...props} />}
          numInputs={6}
          inputStyle={{
            width: 50,
            height: 72,
            fontSize: 20,
            borderRadius: "8px",
            borderStyle: "solid",
            color: COLORS.fwdDarkGreen,
            borderColor: COLORS.fwdDardGreen20,
            outlineColor: COLORS.fwdOrange,
            ...(errorMessage && { borderColor: COLORS.fwdAlertRed }),
          }}
          containerStyle={{ display: "flex", justifyContent: "space-between" }}
        />

        {!!errorMessage && (
          <>
            <Spacer y={5} />
            <ErrorMessageStyled text={errorMessage} />
          </>
        )}
        <div className={clsx([classes.resendOTPContainer, isFullPage && classes.resendOTPContainerCenter])}>
          <div>
            {isResendDisabled ? (
              <>
                <Spacer y={20} />
                <div className={classes.resendOTPCountdown}>{t(`eTriageLanding.otpValidation.${currentBu}.resendCaption`, { remainingTime: remainingTime })}</div>
              </>
            ) : (
              <>
                <Spacer y={20} />
                <div onClick={handleResendClick} className={classes.refreshButton}>
                  <CommonIcon size={20} type="refreshOrange" />
                  <span>{t(`eTriageLanding.otpValidation.${currentBu}.resendButton`)}</span>
                </div>
              </>
            )}</div></div>

        {!!resendErrorMessage && <ErrorMessageStyled text={resendErrorMessage} />}
        <Spacer y={20} />

        <Button title={t(`eTriageLanding.otpValidation.${currentBu}.verifyButton`)} isLoading={isLoading} isDisabled={isLoading || otp.length < 6} onClick={onSubmitValidateOtp} />
        <Spacer y={20} />
        <div className={clsx([classes.remarkContainer, isFullPage && classes.remarkContainerCenter])}>   <div>   <div className={classes.remark}> {ReactHTMLParser(t(`eTriageLanding.otpValidation.${currentBu}.remarks`))}</div></div></div>

        <Spacer y={20} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backIconContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 16,
  },
  formContainer: {
    width: "100%",
    maxWidth: 355,
  },
  caption: {
    marginTop: 16,
    fontSize: fontSizes.contentMedium,
    fontWeight: 450,
    lineHeight: "24px",
    color: COLORS.fwdDarkGreen,
    textAlign: "left",
  },
  remark: {
    fontSize: fontSizes.contentSmall,
    fontWeight: 450,
    lineHeight: "21px",
    color: COLORS.fwdGreyDarkest,
    "& a": {
      color: COLORS.fwdOrange,
      textDecoration: "none",
      cursor: "pointer",
      fontWeight: 700,
      lineHeight: "18px",
    },
  },
  refreshButton: {
    padding: 5,
    margin: "0px 0px 10px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      marginLeft: 8,
      fontSize: fontSizes.contentSmall,
      fontWeight: 700,
      lineHeight: "17.5px",
      color: COLORS.fwdOrange,
    },
  },
  resendOTPCountdown: {
    fontSize: fontSizes.contentSmall,
    fontWeight: 450,
    lineHeight: "18px",
    color: COLORS.fwdGreyDarkest,
  },
  resendOTPContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  resendOTPContainerCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
  },
  remarkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  remarkContainerCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
  },

};

export default withStyles(styles)(OTPValidationForm);
