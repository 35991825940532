export class SymptomsLevel {
  min: number;
  max: ?number;
  description: string;
  isEmergency: boolean;
  levelScore: number;
  level: string;
  constructor(dto: SymptomsLevelDto) {
    this.number = dto.number;
    this.min = dto.min;
    this.max = dto.max;
    this.description = dto.description;
    this.isEmergency = dto.isEmergency;
    this.levelScore = dto.levelScore;
    this.level = dto.level;
  }
  matches(score: number): boolean {
    const ok = score >= this.min && (!this.max || score <= this.max);
    return ok;
  }
}

export type SystomsLevelDto = {|
  min: number,
  max: number,
  description: string,
  isEmergency: boolean,
  levelScore: number,
  level: string,
|};
