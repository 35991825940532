export const footerJson = {
  uid: "d6134fb5-960b-4784-8ae2-7231655f27dc",
  componentName: "Footer",
  dataSource: "{882A58F8-DDD7-4BA9-8EFC-D8D561AEC4F1}",
  params: {
    backgroundColor: "orangeLighter",
    paddingTop: "Medium",
    paddingBottom: "None",
  },
  fields: {
    logo: {
      value: {
        src: "https://www.fwd.co.th/images/v3/assets/blt331c1aa12dcfd37a/blt79ac781550a28c01/623bcec5811c13262b56a298/FWDLogoD.svg",
        alt: "FWD logo svg",
      },
    },
    logoLink: {
      value: {
        href: "https://www.fwd.co.th/th/",
        text: "Home",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "",
        querystring: "",
        id: "{D9C6779B-43E9-4106-8357-AD0510A8547C}",
      },
    },
    call: { value: "1351" },
    icon: {
      id: "166421b9-3ce6-4378-ae85-9cc48875088f",
      fieldType: "CustomDroplink",
      fieldName: "icon",
      url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/call-dual-tone---obsolete",
      fields: {
        value: { value: "call-dual-tone" },
        key: { value: "Call Dual Tone" },
      },
    },
    callingHoursTextAfterTooltip: { value: "" },
    callingHoursTextBeforeTooltip: { value: "" },
    callingHoursTooltip: { value: "" },
    enableCallingHoursText: { value: false },
    siteNavigationLinks: [
      {
        id: "b6a4a857-4afe-4885-b9c3-d3d6738e621a",
        name: "column-1",
        displayName: "column-1",
        fields: {},
        Children: [
          {
            id: "7ef68fd3-c87e-4e5f-9b9c-483934dfec17",
            name: "Product",
            displayName: "Product",
            fields: {
              title: { value: "ผลิตภัณฑ์" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/products/",
                  text: "ผลิตภัณฑ์",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{528D2C44-05E6-4E13-8C4C-0A7FFF35B120}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
            Children: [
              {
                id: "8a896737-457f-4728-93b3-f18099d75092",
                name: "Health",
                displayName: "Health",
                fields: {
                  title: { value: "สุขภาพ" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/health/",
                      text: "สุขภาพ",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{CC3F0F9C-2F1D-4151-9D50-8817D8833A0A}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "67e1efb3-aeda-48b9-9abc-6bb215c3c25c",
                name: "Protection",
                displayName: "Protection",
                fields: {
                  title: { value: "คุ้มครองชีวิต" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/life-protection/",
                      text: "คุ้มครองชีวิต",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{31C8200E-34EF-4DB6-BDF6-8B913C89B67D}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "5f16a651-dd81-4ceb-95d1-a8b2accba76f",
                name: "Savings",
                displayName: "Savings",
                fields: {
                  title: { value: "สะสมทรัพย์" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/savings/",
                      text: "ออมทรัพย์",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{5D51E9D2-C137-4A1C-9264-34AD4ACD4A5E}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "cad57476-729e-4916-921a-2f65d21fb0ec",
                name: "Retirement",
                displayName: "Retirement",
                fields: {
                  title: { value: "เกษียณ" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/retirement/",
                      text: "เกษียณ",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{93901AC3-0039-487C-B069-ACC1543BF234}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "0e168952-5248-4f3c-b139-bf2b21a5d4c4",
                name: "Legacy",
                displayName: "Legacy",
                fields: {
                  title: { value: "มรดก" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/legacy/",
                      text: "มรดก",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{4A49126C-4B5E-46A7-9ECC-562F3A84D188}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "2f577974-2257-4640-bd46-c88ac10bb112",
                name: "Accident",
                displayName: "Accident",
                fields: {
                  title: { value: "อุบัติเหตุ" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/accident/",
                      text: "อุบัติเหตุ",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{203A797B-8E26-40B2-8B79-11EA13B002FD}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "30bd944c-268a-4146-af1a-630af2106951",
                name: "Investments",
                displayName: "Investments",
                fields: {
                  title: { value: "การลงทุน" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/investments/",
                      text: "การลงทุน",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{D0FDA387-C773-4046-9DCD-BFA0E0A4363A}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "c3fbd081-a57e-4ad0-be56-49ce57c4f3c9",
                name: "General insurance",
                displayName: "General insurance",
                fields: {
                  title: { value: "ประกันวินาศภัย" },
                  link: {
                    value: {
                      href: "http://www.fwdgi.co.th/th/",
                      text: "ประกันวินาศภัย",
                      linktype: "external",
                      url: "http://www.fwdgi.co.th/th/",
                      anchor: "",
                      target: "_blank",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "e1218d56-f214-44e3-be35-2dd3d9cdbb72",
        name: "column-2",
        displayName: "column-2",
        fields: {},
        Children: [
          {
            id: "6c17bfb7-d526-45b3-a8b6-ec8b32c9d45c",
            name: "Claims",
            displayName: "Claims",
            fields: {
              title: { value: "การเรียกร้องสินไหม" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/claims/",
                  text: "การเรียกร้องสินไหม",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{69567D54-9813-4873-A169-DDE101852FAE}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
            Children: [
              {
                id: "1f66df00-095b-4c41-9e70-3188c99920f0",
                name: "Find a form",
                displayName: "Find a form",
                fields: {
                  title: { value: "ค้นหาแบบฟอร์ม" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/support/forms/",
                      text: "ค้นหาแบบฟอร์ม",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{6EDDED5F-86D9-4C1B-82ED-846BB127475D}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "88965a10-6265-4ca1-baa3-c94f57abaebc",
                name: "Accident and health claims",
                displayName: "Accident and health claims",
                fields: {
                  title: {
                    value: "เรียกร้องสินไหมสุขภาพและอุบัติเหตุ",
                  },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/accident-and-health/",
                      text: "เรียกร้องสินไหมอุบัติเหตุและสุขภาพ",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{645F9677-0A72-4985-BA13-AFC14647AE5F}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "21857281-1532-4ce2-849f-801a59173477",
                name: "Critical illness claims",
                displayName: "Critical illness claims",
                fields: {
                  title: { value: "เรียกร้องสินไหมโรคร้ายแรง" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/critical-illness/",
                      text: "Critical illness claims",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "Critical illness claims",
                      querystring: "",
                      id: "{ABC09A1E-7445-4F45-B811-3B75522C30DB}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "759104b1-d6f4-4c89-8b14-b6bc69225d50",
                name: "Cancer claims",
                displayName: "Cancer claims",
                fields: {
                  title: { value: "เรียกร้องสินไหมโรคมะเร็ง" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/cancer/",
                      text: "Cancer claims",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "Cancer claims",
                      querystring: "",
                      id: "{433BFB79-6580-44CA-8FDF-7DB422C1215F}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "9277ace0-aced-45ee-b30a-02b7d3ab4344",
                name: "Death claims",
                displayName: "Death claims",
                fields: {
                  title: { value: "เรียกร้องสินไหมมรณกรรม" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/death/",
                      text: "Death",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{472E1AF9-7793-4CB8-AF80-8F68B7BE9CE9}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "2d32a3fb-4a3f-4344-afdb-06d19c79203c",
                name: "FWD Care Card",
                displayName: "FWD Care Card",
                fields: {
                  title: { value: "เรียกร้องสินไหมทุพพลภาพ" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/total-permanent-disability/",
                      text: "เรียกร้องสินไหมทุพพลภาพ",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{D2287612-7D43-4F33-BAEA-A141E381B89D}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "cdcb3fa9-ba09-40eb-aadf-fc3cf607913c",
                name: "FWD Card",
                displayName: "FWD Card",
                fields: {
                  title: { value: "เอฟดับบลิวดี การ์ด" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/claims/fwd-card/",
                      text: "FWD Care Card claims",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "FWD Care Card claims",
                      querystring: "",
                      id: "{F2FE3494-85FA-4D1F-8429-E75E14968F75}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "7ac15a62-29cc-4f9d-9188-9f2c8a8e4f69",
        name: "column-3",
        displayName: "column-3",
        fields: {},
        Children: [
          {
            id: "ebbb4309-d07f-4dcc-a15c-4861a5c3baab",
            name: "Support",
            displayName: "Support",
            fields: {
              title: { value: "บริการลูกค้า" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/support/",
                  text: "บริการลูกค้า",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{A09F0B08-17C3-43BA-A3B1-FD62BA1E29C9}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
            Children: [
              {
                id: "8afdbf39-5c61-4981-8c49-1e24a5604842",
                name: "FAQs",
                displayName: "FAQs",
                fields: {
                  title: { value: "คำถามที่พบบ่อย" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/support/faq/",
                      text: "FAQs",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{4D410CCC-DDD8-456C-B317-FD8D00637F32}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "f09ec3cf-41f2-419d-82f4-6ee7f2d00023",
                name: "Live chat",
                displayName: "Live chat",
                fields: {
                  title: { value: "ไลฟ์แชท" },
                  link: { value: {} },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "1eed96f8-20e1-4179-84a9-5497a8a82055",
                name: "Call 1351",
                displayName: "Call 1351",
                fields: {
                  title: { value: "โทร.1351" },
                  link: {
                    value: {
                      href: "http://tel:1351",
                      text: "โทร.1351",
                      linktype: "external",
                      url: "tel:1351",
                      anchor: "",
                      target: "",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "fd670504-d972-4fee-9582-656b0d6ba342",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
                    fields: {
                      value: { value: "Call" },
                      key: { value: "call" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "dd51f4b7-9985-49f8-8d5f-2ae3e7a90ce2",
                name: "Online enquiry",
                displayName: "Online enquiry",
                fields: {
                  title: { value: "สอบถามออนไลน์" },
                  link: {
                    value: {
                      text: "",
                      linktype: "form",
                      querystring: "bookAnAgent",
                      target: "",
                      id: "{90D7A8DC-2EBC-4E16-8D0F-E162C7D01EC5}",
                      href: "https://www.fwd.co.th/th/formslist/general-enquiry-form",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "2048ab07-103c-4f6d-96e2-01c2cd790ff7",
                name: "Locate us",
                displayName: "Locate us",
                fields: {
                  title: { value: "ที่ตั้งสำนักงาน" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/branch-locator/",
                      text: "ที่ตั้งสำนักงาน",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{AAD27929-2ECF-455F-9D17-7FBE1554B510}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "835afd47-d362-491a-b9ce-779208b3116a",
                name: "Our hospital and clinic network",
                displayName: "Our hospital and clinic network",
                fields: {
                  title: { value: "โรงพยาบาลในเครือข่ายของเรา" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/hospital-network/",
                      text: "โรงพยาบาลในเครือข่ายของเรา",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{EE338D6E-F74B-4A3C-AD12-A3E1CE871156}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "f98bad30-663a-401a-8d46-2f3a41ee1664",
                name: "Fund information",
                displayName: "Fund information",
                fields: {
                  title: { value: "ข้อมูลกองทุน" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/products/investments/fund-information/",
                      text: "",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      target: "",
                      querystring: "",
                      id: "{5504F779-9CCF-4A7D-9EF3-DAF930E507C8}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "" },
                  gaLabelName: { value: "" },
                },
              },
              {
                id: "4f0bb82a-8f73-4226-ad5f-909740a924d4",
                name: "Announcement",
                displayName: "Announcement",
                fields: {
                  title: { value: "ประกาศบริษัท" },
                  link: {
                    value: {
                      href: "https://www.fwd.co.th/th/announcements/?contenttype=Article&subtype=Announcements",
                      text: "",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      target: "",
                      querystring: "contenttype=Article&subtype=Announcements",
                      id: "{3BD9A9AF-214B-4772-BF6C-B1219096C060}",
                    },
                  },
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaCategoryName: { value: "Announcement" },
                  gaLabelName: { value: "Announcement" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "b619a274-7118-41da-b746-32fd8e568f9b",
        name: "column-4",
        displayName: "column-4",
        fields: {},
        Children: [
          {
            id: "9250bdd6-55d1-4fa5-ac26-9a4c309d82b7",
            name: "Rewards",
            displayName: "Rewards",
            fields: {
              title: { value: "สิทธิพิเศษ" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/rewards/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{37296FD4-8CF2-45BA-88E0-FAAEC51215A7}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "d0be86f1-2763-4ac9-a867-22ea867b091b",
            name: "Business insurance",
            displayName: "Business insurance",
            fields: {
              title: { value: "ประกันภัยกลุ่ม" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/group-insurance/",
                  text: "ประกันภัยกลุ่ม",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{0D08A74E-81B5-4DFD-9B53-B8AD7413F555}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "5e02f182-9bcc-4212-8cba-14bd94cd4435",
            name: "FWD MAX",
            displayName: "FWD MAX",
            fields: {
              title: { value: "เอฟดับบลิวดี แมกซ์" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/fwdmax/",
                  text: "เอฟดับบลิวดี แมกซ์",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{B06FCBBE-D82F-45E6-8CF6-9907C875B081}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
          {
            id: "07f70095-3eb9-4845-b7b8-cca6feffccc3",
            name: "About us",
            displayName: "About us",
            fields: {
              title: { value: "เกี่ยวกับเรา" },
              link: {
                value: {
                  href: "https://www.fwd.co.th/th/about-us/",
                  text: "เกี่ยวกับเรา",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{49F0D81D-2E91-44B2-9F6E-3267BABAFA41}",
                },
              },
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaCategoryName: { value: "" },
              gaLabelName: { value: "" },
            },
          },
        ],
      },
    ],
    countrySelector: [
      {
        id: "0f8ee116-4b66-4b7e-81c5-275ee4c64978",
        name: "Group",
        displayName: "Group",
        fields: {
          isDefault: { value: false },
          title: { value: "FWD Group" },
          link: {
            value: {
              href: "https://www.fwd.com/",
              text: "FWD Group",
              linktype: "external",
              url: "https://www.fwd.com/",
              anchor: "",
              title: "FWD Group",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "c8790a90-c83f-4e0e-9337-d4b42e0f634b",
        name: "Cambodia",
        displayName: "Cambodia",
        fields: {
          isDefault: { value: false },
          title: { value: "กัมพูชา" },
          link: {
            value: {
              href: "https://www.fwd.com.kh/",
              text: "กัมพูชา",
              linktype: "external",
              url: "https://www.fwd.com.kh/",
              anchor: "",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "705fb6bf-8708-4aff-aba3-843de80e7577",
        name: "Hong Kong",
        displayName: "Hong Kong",
        fields: {
          isDefault: { value: false },
          title: { value: " ฮ่องกง" },
          link: {
            value: {
              href: "https://www.fwd.com.hk/",
              text: "FWD Hong Kong",
              linktype: "external",
              url: "https://www.fwd.com.hk/",
              anchor: "",
              title: "FWD Hong Kong",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "4c2ac688-4db2-44c0-a2a3-df7eee3f311b",
        name: "Macau",
        displayName: "Macau",
        fields: {
          isDefault: { value: false },
          title: { value: "มาเก๊า" },
          link: {
            value: {
              href: "https://www.fwd.com.mo/",
              text: "FWD Macau",
              linktype: "external",
              url: "https://www.fwd.com.mo/",
              anchor: "",
              title: "FWD Macau",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "d6ec9fe1-6716-41f9-b260-777a3a0df396",
        name: "Indonesia",
        displayName: "Indonesia",
        fields: {
          isDefault: { value: false },
          title: { value: "อินโดนีเซีย" },
          link: {
            value: {
              href: "https://www.fwd.co.id/",
              text: "FWD Indonesia",
              linktype: "external",
              url: "https://www.fwd.co.id/",
              anchor: "",
              title: "FWD Indonesia",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "1c474efb-12a7-46c4-b789-9dac52771e92",
        name: "Japan",
        displayName: "Japan",
        fields: {
          isDefault: { value: false },
          title: { value: "ญี่ปุ่น" },
          link: {
            value: {
              href: "http://www.fwdlife.co.jp",
              text: "FWD Japan",
              linktype: "external",
              url: "http://www.fwdlife.co.jp",
              anchor: "",
              title: "FWD Japan",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "7819367f-f443-4cc4-8500-853d174d5931",
        name: "Malaysia",
        displayName: "Malaysia",
        fields: {
          isDefault: { value: false },
          title: { value: "มาเลเซีย" },
          link: {
            value: {
              href: "https://www.fwd.com.my/",
              text: "FWD Malaysia",
              linktype: "external",
              url: "https://www.fwd.com.my/",
              anchor: "",
              title: "FWD Malaysia",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "3cbf0795-1368-4bfa-823f-992a5e34ef0d",
        name: "Philippines",
        displayName: "Philippines",
        fields: {
          isDefault: { value: false },
          title: { value: "ฟิลิปปินส์" },
          link: {
            value: {
              href: "https://www.fwd.com.ph/",
              text: "FWD Philippines",
              linktype: "external",
              url: "https://www.fwd.com.ph/",
              anchor: "",
              title: "FWD Philippines",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "38e2032c-59d9-45f0-b485-98c30b212638",
        name: "Singapore",
        displayName: "Singapore",
        fields: {
          isDefault: { value: false },
          title: { value: "สิงคโปร์" },
          link: {
            value: {
              href: "https://www.fwd.com.sg/",
              text: "FWD Singapore",
              linktype: "external",
              url: "https://www.fwd.com.sg/",
              anchor: "",
              title: "FWD Singapore",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "66cfbe84-bc11-4565-9e91-63a71e5e33aa",
        name: "Thailand",
        displayName: "Thailand",
        fields: {
          isDefault: { value: true },
          title: { value: "ประเทศไทย" },
          link: {
            value: {
              href: "https://www.fwd.co.th/",
              text: "FWD Thailand",
              linktype: "external",
              url: "https://www.fwd.co.th/",
              anchor: "",
              title: "FWD Thailand",
              target: "",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "35530397-2c2a-4175-9914-35dc9ed3b057",
        name: "Vietnam",
        displayName: "Vietnam",
        fields: {
          isDefault: { value: false },
          title: { value: "เวียดนาม" },
          link: {
            value: {
              href: "https://www.fwd.com.vn/",
              text: "FWD Vietnam",
              linktype: "external",
              url: "https://www.fwd.com.vn/",
              anchor: "",
              title: "FWD Vietnam",
              target: "_blank",
            },
          },
          buttonIcon: null,
          listType: null,
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
    ],
    socialMediaLinks: [
      {
        id: "790af4e5-19e0-4919-bcc8-d2b55e0c7b2e",
        name: "Facebook",
        displayName: "Facebook",
        fields: {
          title: { value: "facebook" },
          link: {
            value: {
              href: "https://www.facebook.com/fwdthailand/",
              text: "facebook",
              linktype: "external",
              url: "https://www.facebook.com/fwdthailand/",
              anchor: "",
              target: "",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "FacebookFWDThailand" },
        },
      },
      {
        id: "0b365b93-2a12-443e-9c70-672b09782fa0",
        name: "Line",
        displayName: "Line",
        fields: {
          title: { value: "line" },
          link: {
            value: {
              href: "https://timeline.line.me/user/_dfo2OBQneRhH2PTn7wxjlbWMqCMR6Txr95avbeY",
              text: "line",
              linktype: "external",
              url: "https://timeline.line.me/user/_dfo2OBQneRhH2PTn7wxjlbWMqCMR6Txr95avbeY",
              anchor: "",
              target: "",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "LineFWDThailand" },
        },
      },
      {
        id: "cb9d89cf-e96d-46b9-af34-1c1af2174905",
        name: "Twitter",
        displayName: "Twitter",
        fields: {
          title: { value: "twitter" },
          link: {
            value: {
              href: "https://twitter.com/fwdthailand",
              text: "twitter",
              linktype: "external",
              url: "https://twitter.com/fwdthailand",
              anchor: "",
              target: "",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "TwitterFWDThailand" },
        },
      },
      {
        id: "beff79a2-16f1-4d2a-b469-8af59318e1a6",
        name: "YouTube",
        displayName: "YouTube",
        fields: {
          title: { value: "youtube" },
          link: {
            value: {
              href: "https://www.youtube.com/channel/UCD3UsUfE10gjPjGEepO_r1A",
              text: "youtube",
              linktype: "external",
              url: "https://www.youtube.com/channel/UCD3UsUfE10gjPjGEepO_r1A",
              anchor: "",
              target: "",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "YoutubeFWDThailand" },
        },
      },
    ],
    footerQuickLinks: [
      {
        id: "dafa174b-ab18-4821-ba4a-c9bb5aadf0d6",
        name: "Sitemap",
        displayName: "Sitemap",
        fields: {
          title: { value: "แผนผังเว็บไซต์" },
          link: {
            value: {
              href: "https://www.fwd.co.th/th/sitemap/",
              text: "แผนผังเว็บไซต์",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "แผนผังเว็บไซต์",
              querystring: "",
              id: "{6D4D73F8-997F-44B4-9503-87589C0201EC}",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "8a2e371d-020e-410d-85ee-1fddb7a48c44",
        name: "Disclaimer",
        displayName: "Disclaimer",
        fields: {
          title: { value: "ข้อตกลงการใช้" },
          link: {
            value: {
              href: "https://www.fwd.co.th/th/disclaimer/",
              text: "Disclaimer",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "Disclaimer",
              querystring: "",
              id: "{A6C39A1A-72C8-4766-AA77-F71EC84E3753}",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
      {
        id: "fd02bc01-2d73-4dd1-ab20-40f7696b80a1",
        name: "Privacy Policy",
        displayName: "Privacy Policy",
        fields: {
          title: { value: "นโยบายข้อมูลส่วนบุคคล" },
          link: {
            value: {
              href: "https://www.fwd.co.th/th/privacy-policy/",
              text: "Privacy Policy",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "Privacy Policy",
              querystring: "",
              id: "{586403F5-4598-40C9-96E5-0BB8ADC0D789}",
            },
          },
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.co.th/th/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaCategoryName: { value: "" },
          gaLabelName: { value: "" },
        },
      },
    ],
    copyrightLabel: {
      value:
        "สงวนลิขสิทธิ์ &copy; 2021 บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน)",
    },
    defaultComplianceAccreditation: {
      id: "93dede65-6cb1-439f-af28-236163f6342a",
      fieldType: "Droplink",
      fieldName: "defaultComplianceAccreditation",
      url: "https://www.fwd.co.th/th/components/compliance-accreditation/iso27001_th",
      fields: {
        Description: {
          value:
            "เอฟดับบลิวดี ประกันชีวิต ได้รับการรับรองระบบบริหารจัดการด้านความปลอดภัยของสารสนเทศตามมาตรฐาน ISO 27001 จาก TUV NORD (Thailand) Ltd.",
        },
        Images: [
          {
            id: "a5ebb96c-442c-41ff-a15d-95304b9dd21b",
            fields: {
              mobileImage: {
                value: {
                  src: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c",
                  alt: "ISO27001",
                  x1: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c&cx=0&cy=0&cw=328&ch=164&hash=2A22C37F951F9B626B7C1EB333C0164F",
                  x2: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c&cx=0&cy=0&cw=656&ch=328&hash=41FE2CBB735E950FA0C291A5ADCB79BE",
                },
              },
              link: { value: {} },
              image: {
                value: {
                  src: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c",
                  alt: "ISO27001",
                  x1: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c&cx=0&cy=0&cw=392&ch=196&hash=5167D3E3E36439DF58799023FAB6971F",
                  x2: "https://www.fwd.co.th/-/media/images/home/iso_270001/iso-logo-th.png?rev=96188739dc0d4378acac53c80983116c&cx=0&cy=0&cw=784&ch=392&hash=BD6CFB479EC72206606AAEF5C5DCFA57",
                },
              },
            },
          },
        ],
        accreditationMode: null,
      },
    },
    disableComplianceAccreditation: { value: false },
    gaCategoryName: { value: "" },
    gaLabelName: { value: "" },
    languageModalDescription: { value: "" },
    languageModalLink: { value: {} },
    languageModalSecondaryLink: { value: "" },
    languageModalTitle: { value: "" },
    languageList: [
      {
        id: "65e62323-9da1-4172-b472-7443ebbbfbee",
        fields: {
          languageName: { value: "EN" },
          languageCode: { value: "en" },
          defaultLanguage: { value: "Th" },
        },
      },
      {
        id: "5f8cb340-ec7f-4b71-bb4a-c950856d3495",
        fields: {
          languageName: { value: "ไทย" },
          languageCode: { value: "th" },
          defaultLanguage: { value: "En" },
        },
      },
    ],
    enableLanguageToggle: { value: true },
  },
  sitecoreContext: {
    language: "ไทย",
    languageCode: "th",
    availableLanguages: ["th", "en"],
  },
};
