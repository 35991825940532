/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://nbwpeb6dr5h4heagxir3uvadce.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kmh4g6h62zb7bp4bl4x4fzxel4",
    "aws_cognito_identity_pool_id": "ap-southeast-1:a4e61d47-2899-40c0-81db-b2644e756319",
    "aws_cognito_region": "ap-southeast-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "fwd-mentalhealth-myprod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d27ugd8tlpcrvt.cloudfront.net"
};


export default awsmobile;
