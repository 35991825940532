export const footerJson = {
  uid: "834cca27-4925-4542-9adc-9201d3ffad3e",
  componentName: "Footer",
  dataSource: "{1685656C-A971-4E74-A89A-3D474EFDD1F9}",

  fields: {
    logo: {
      value: {
        src: "https://www.fwd.com.hk/-/media/global/images/fwd-logo_colour.svg?rev=77f0e92219254f9caea8bbe5bba16478",
        alt: "FWD logo svg",
      },
    },
    logoLink: {
      value: {
        href: "https://www.fwd.com.hk/",
        text: "Home",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "Home",
        target: "",
        querystring: "",
        id: "{9CB72689-FA18-4900-B876-3AE397D3B009}",
      },
    },
    callingHoursTextAfterTooltip: { value: "" },
    call: { value: "3123 3123" },
    enableCallingHoursText: { value: false },
    callingHoursTextBeforeTooltip: { value: "" },
    callingHoursTooltip: { value: "" },
    icon: {
      id: "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
      fieldType: "CustomDroplink",
      fieldName: "icon",
      url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/call",
      fields: { value: { value: "call" }, key: { value: "Call" } },
    },
    siteNavigationLinks: [
      {
        id: "1ebeab06-370c-4369-9651-c7d6a619b279",
        name: "column-1",
        displayName: "column-1",
        fields: {},
        Children: [
          {
            id: "3431b193-681c-4142-9f73-794ae4e593d4",
            name: "Product",
            displayName: "Product",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "產品 " },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/products/",
                  text: "Products",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{DB3FF0BC-2E10-4735-8BAA-76A87A906821}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
            Children: [
              {
                id: "6543e042-8c82-4c41-a523-b4c867515f60",
                name: "Critical Illness",
                displayName: "Critical Illness",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "危疾 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/critical-illness/",
                      text: "Critical Illness",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{A82A3182-0C78-4208-B8A3-F15B27C2E709}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "7651448b-e7b8-4914-aeb5-fbe68e498ca0",
                name: "Life Protection",
                displayName: "Life Protection",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "人壽 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/life-protection/",
                      text: "Life Protection",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{6F14A835-42D9-4317-993F-19811456F986}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "c57f02ee-be06-4dc8-a96d-4ce3c78f13db",
                name: "Medical Cover",
                displayName: "Medical Cover",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "醫療保障" },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/medical-cover/",
                      text: "Medical Cover",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{636DDAC6-5889-4950-BE35-02B32E5C4F18}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "927bc564-132e-4e48-bd6b-ba61f687d2f0",
                name: "VHIS",
                displayName: "VHIS",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "自願醫保計劃" },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/vhis/",
                      text: "Vhis",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{162A0204-90F2-4731-8FF8-1B40FD8BFC8F}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "68247ed9-3f30-4229-9cfb-c5937bbb6682",
                name: "Savings",
                displayName: "Savings",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "儲蓄 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/savings/",
                      text: "Savings",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{D1312CF3-9AE4-4E3C-9375-DDE2398104B5}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "0267eb8f-84a7-47f0-8ecb-d8a76e115802",
                name: "Accident and Disability",
                displayName: "Accident and Disability",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "意外／傷殘 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/accident-disability/",
                      text: "Accident Disability",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{67CEF236-323A-4DBB-A4A5-44D8B231BF78}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "6b329393-9d7a-4ab2-81af-f5a0899ce0fd",
                name: "Household and Pets",
                displayName: "Household and Pets",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "家居／寵物 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/household-pets/",
                      text: "Household-pets",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{A923A2DC-E458-44F7-97D5-BCECC64F7497}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "176cb320-6acb-4cf1-9c3d-f2905f339597",
                name: "Invest",
                displayName: "Invest",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "投資 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/invest/",
                      text: "Invest",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{2375DA5B-104B-4B0C-A915-78223732320F}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "6972d099-a073-4faa-a92f-4ed0f5620bdf",
                name: "Car-boat",
                displayName: "Car-boat",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "汽車／遊艇 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/car-boat/",
                      text: "car-boat",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{676FD29E-0036-4C29-BF5E-D0032622E7B7}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "3acb3f96-9d5d-4cb4-b1ee-dd86fe4af2c6",
                name: "Helper",
                displayName: "Helper",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "外傭 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/helper/",
                      text: "helper",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{F758BC09-C312-4E75-B5DF-4A5660CBE482}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "320242c6-7fb0-4c4b-b0ad-5d71a9830fd7",
                name: "Travel and golf",
                displayName: "Travel and golf",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "旅遊／高爾夫球 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/travel-golf/",
                      text: "travel-golf",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{47EF2BAA-5211-49C4-A4B5-50133E343C8D}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "b51fdc29-b76e-47dc-920d-ab68aaad347d",
        name: "column-2",
        displayName: "column-2",
        fields: {},
        Children: [
          {
            id: "a865ba29-89d5-490a-a941-1fe3cf1bfc49",
            name: "Claims",
            displayName: "Claims",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "索償 " },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/claims/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{1ECCEDC9-E80A-4521-A507-CC81BD2A5EC6}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
            Children: [
              {
                id: "bb12f25f-2a51-4bfd-9858-969e4b67a4ea",
                name: "Find a form",
                displayName: "Find a form",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "尋找表格 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/support/forms/",
                      text: "forms",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{DA98F73B-F736-4913-AC1A-CE0A74A11DC5}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "f8618e3c-ea42-4090-8fea-a5de61600ed5",
                name: "Travel claims",
                displayName: "Travel claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "旅遊索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/travel/",
                      text: "travel",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{FB5C74DC-4135-451B-82AC-1A15B1F6A0B5}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "6b39cade-87bc-4517-9b54-5dff4599e599",
                name: "Medical claims",
                displayName: "Medical claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "醫療索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/outpatient/",
                      text: "medical",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      target: "",
                      querystring: "",
                      id: "{2C9488E4-20C5-4124-818F-95C3F0AD1883}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "f1c3d1f6-5690-4577-b1fa-95bfdce295e3",
                name: "Critical illness claims",
                displayName: "Critical illness claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "危疾索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/critical-illness/",
                      text: "critical illness",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{08C951DA-1F83-4DB6-9748-F939B690B0E6}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "d7c8c122-dcf4-4f69-8d46-bbd76e50aba9",
                name: "Accident claims",
                displayName: "Accident claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "意外索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/accident/",
                      text: "",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      target: "",
                      querystring: "",
                      id: "{45A7B83E-EE58-4312-A6F5-3F4D8E920729}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "c377828a-ff25-4100-9b42-765e7c164c49",
                name: "Disablement claims",
                displayName: "Disablement claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "傷殘索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/total-permanent-disablement/",
                      text: "disablement",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{25AE1954-DCBC-4545-AE03-BB21E5727685}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "0c09574b-df50-4e2d-8602-ce3ed7fe6c2c",
                name: "Helper claims",
                displayName: "Helper claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "外傭保障 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/helper/",
                      text: "helper",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{CB346164-7028-4BFA-9259-F1D69A99E1AD}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "dbbcf56c-83cf-43bc-984c-6cabccdb221e",
                name: "Home and property claims",
                displayName: "Home and property claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "家居財物索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/home-property/",
                      text: "home-property",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{A5A8371A-8C30-4B37-A54E-22C8052C3B60}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "417907cc-9285-454d-b5c4-4d5fbbb265c8",
                name: "Pets claims",
                displayName: "Pets claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "寵物索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/pets/",
                      text: "pets",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{080FDC62-0EE8-446A-9318-970262DE4605}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "ea00b33a-a4e4-40e2-85ea-dfa352683213",
                name: "Car and boat claims",
                displayName: "Car and boat claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "汽車及遊艇索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/car-boat/",
                      text: "car-boat",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{D11A872C-2C11-4FCF-B6A8-E8D38029F9CC}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "4c7d44e6-2181-4935-9164-01f8c0e62f9c",
                name: "Golf claims",
                displayName: "Golf claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "高爾夫球索償 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/products/travel-golf/",
                      text: "travel-golf",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{47EF2BAA-5211-49C4-A4B5-50133E343C8D}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "e041152c-d786-4ea7-99bd-4136ee1a225a",
                name: "Loss of life claims",
                displayName: "Loss of life claims",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "死亡索償  " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/claims/loss-of-life/",
                      text: "loss of life",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{B5FEAC69-4732-4FC4-9D78-F6ADD0779995}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "a357808e-7483-45e8-b843-4e3c1b30108a",
        name: "column-3",
        displayName: "column-3",
        fields: {},
        Children: [
          {
            id: "79504469-ea34-4ff0-b5e8-a6f3202abf11",
            name: "Support",
            displayName: "Support",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "支援 " },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/support/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{9C6D9E13-7967-40EA-AAAA-753CA0685D6C}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
            Children: [
              {
                id: "656a9743-2044-4e9b-8ea1-7291502ee15d",
                name: "FAQs",
                displayName: "FAQs",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "常見問題 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/support/faq/",
                      text: "",
                      anchor: "",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{777F29A0-742A-4A61-8985-5D7C6C75C0E9}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "8adb753b-5ead-414f-a7e8-6bec902e5006",
                name: "Call",
                displayName: "Call",
                fields: {
                  buttonIcon: {
                    id: "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/call",
                    fields: {
                      value: { value: "call" },
                      key: { value: "Call" },
                    },
                  },
                  title: { value: "致電 3123 3123 " },
                  link: {
                    value: {
                      href: "http://tel:31233123",
                      text: "Call",
                      linktype: "external",
                      url: "tel:31233123",
                      anchor: "",
                      target: "",
                    },
                  },
                  listType: {
                    id: "fd670504-d972-4fee-9582-656b0d6ba342",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
                    fields: {
                      value: { value: "Call" },
                      key: { value: "call" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "ac3ab723-d9c1-49af-890f-9b701e2ae311",
                name: "locate-an-office",
                displayName: "locate-an-office",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "服務中心地點 " },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/support/#locatorComponent",
                      text: "",
                      anchor: "locatorComponent",
                      linktype: "internal",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{9C6D9E13-7967-40EA-AAAA-753CA0685D6C}",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "4d695e11-4209-4e75-bd28-83df492ef150",
                name: "Chat",
                displayName: "Chat",
                fields: {
                  buttonIcon: {
                    id: "631bc306-c30e-4d10-afe5-670237118479",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chat",
                    fields: {
                      value: { value: "chat" },
                      key: { value: "chat" },
                    },
                  },
                  title: { value: "即時對話 " },
                  link: { value: {} },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "6c82abe5-623f-4d52-b003-e58ad4778773",
                name: "online-enquiry",
                displayName: "online-enquiry",
                fields: {
                  buttonIcon: {
                    id: "f35ed9fd-ad24-494a-8fb6-33be8955a85d",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/call-email",
                    fields: {
                      value: { value: "call-email" },
                      key: { value: "callEmail" },
                    },
                  },
                  title: { value: "網上查詢 " },
                  link: {
                    value: {
                      text: "Enquire",
                      linktype: "form",
                      querystring: "bookAnAgent",
                      target: "",
                      id: "{3F7AFE34-5245-4587-ADEC-6A0239C32FF3}",
                      href: "https://www.fwd.com.hk/forms-list/general-enquiry-form",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "9f835c92-5c30-4fa7-9940-cad05c4293a7",
                name: "FWD Moments",
                displayName: "FWD Moments",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "FWD Moments" },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/fwd-moments/",
                      linktype: "external",
                      url: "https://www.fwd.com.hk/fwd-moments/",
                      anchor: "",
                      target: "",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "06e581d3-9c75-44ba-8596-b411f60a95a6",
                name: "View ex-Metlife information",
                displayName: "View ex-Metlife information",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "前大都會人壽資訊" },
                  link: {
                    value: {
                      href: "https://www.fwdlife.hk/tc/",
                      linktype: "external",
                      url: "https://www.fwdlife.hk/tc/",
                      anchor: "",
                      target: "_blank",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
            ],
          },
        ],
      },
      {
        id: "e42e892f-502b-4723-8173-f024cd4d9ee3",
        name: "column-4",
        displayName: "column-4",
        fields: {},
        Children: [
          {
            id: "e8df46d2-3126-4eb5-a2db-55fe3d66fe24",
            name: "Business Insurance",
            displayName: "Business Insurance",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "企業保障 " },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/business/",
                  text: "business",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{BBB4F360-4715-4653-8CDE-BA9E60D1E26D}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
            Children: [
              {
                id: "434ca16b-5a1e-4d12-a2cf-41ba2acb21b8",
                name: "Employee benefits",
                displayName: "Employee benefits",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "僱員福利" },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/business/employee-benefits/",
                      text: "僱員福利",
                      linktype: "external",
                      url: "https://www.fwd.com.hk/business/employee-benefits/",
                      anchor: "",
                      target: "",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
              {
                id: "bcedebbb-1387-4bc6-86bd-4a059edadc7d",
                name: "Commercial",
                displayName: "Commercial",
                fields: {
                  buttonIcon: {
                    id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                    fieldType: "CustomDroplink",
                    fieldName: "buttonIcon",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                    fields: {
                      value: { value: "chevron-right" },
                      key: { value: "chevronRight" },
                    },
                  },
                  title: { value: "商業保險" },
                  link: {
                    value: {
                      href: "https://www.fwd.com.hk/business/commercial/",
                      text: "商業保險",
                      linktype: "external",
                      url: "https://www.fwd.com.hk/business/commercial/",
                      anchor: "",
                      target: "",
                    },
                  },
                  listType: {
                    id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                    fieldType: "Droplink",
                    fieldName: "listType",
                    url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                    fields: {
                      value: { value: "Generic Link" },
                      key: { value: "generic_link" },
                    },
                  },
                  gaLabelName: { value: "" },
                  gaCategoryName: { value: "" },
                },
              },
            ],
          },
          {
            id: "62039744-6e61-4a1f-92d3-93536136c872",
            name: "About",
            displayName: "About",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "關於我們" },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/about-us/",
                  text: "About us",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{E5F9A3FD-CB1D-4BE3-BC8B-C33AB0CA8199}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
          },
          {
            id: "e22888b3-a607-475f-81d3-6a5ed3b73ab9",
            name: "Press",
            displayName: "Press",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "新聞稿" },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/press/",
                  text: "About us",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{054F3C02-2193-4124-A0F5-2C509121655F}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
          },
          {
            id: "92c24d0e-cbe0-4aa5-bd90-cf874867b9e8",
            name: "Live",
            displayName: "Live",
            fields: {
              buttonIcon: {
                id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
                fields: {
                  value: { value: "chevron-right" },
                  key: { value: "chevronRight" },
                },
              },
              title: { value: "網誌" },
              link: {
                value: {
                  href: "https://www.fwd.com.hk/blog/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{68E8B2B6-DD6C-411F-91E1-B1B25B2107EB}",
                },
              },
              listType: {
                id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                fieldType: "Droplink",
                fieldName: "listType",
                url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                fields: {
                  value: { value: "Generic Link" },
                  key: { value: "generic_link" },
                },
              },
              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
          },
        ],
      },
    ],
    countrySelector: [
      {
        id: "b43fd69b-bd63-4f64-9aab-06f1b0d527c7",
        name: "Group",
        displayName: "Group",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "富衛集團" },
          link: {
            value: {
              href: "https://www.fwd.com/",
              linktype: "external",
              url: "https://www.fwd.com/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "eb9ee5ea-e079-4bde-af3c-b82e5ebee28a",
        name: "Hong Kong",
        displayName: "Hong Kong",
        fields: {
          isDefault: { value: true },
          buttonIcon: null,
          title: { value: "Hong Kong" },
          link: {
            value: {
              href: "https://www.fwd.com.hk/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{9CB72689-FA18-4900-B876-3AE397D3B009}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "dc6f0a0c-f056-44c6-a565-2044c8b494a6",
        name: "Macau",
        displayName: "Macau",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "澳門" },
          link: {
            value: {
              href: "https://www.fwd.com.mo/en/",
              linktype: "external",
              url: "https://www.fwd.com.mo/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "62bb0970-5392-494c-8969-53863b8728d3",
        name: "China representative office",
        displayName: "China representative office",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "中國代表處" },
          link: {
            value: {
              href: "https://www.fwd.cn/",
              linktype: "external",
              url: "https://www.fwd.cn/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "79ec13ea-0312-44f7-b305-3660acd71adf",
        name: "Indonesia",
        displayName: "Indonesia",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "印尼" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/",
              linktype: "external",
              url: "https://www.fwd.co.id/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "e5b1b516-9ca4-4a59-9417-03cdedeaab5a",
        name: "Japan",
        displayName: "Japan",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "日本" },
          link: {
            value: {
              href: "https://www.fwdlife.co.jp/",
              linktype: "external",
              url: "https://www.fwdlife.co.jp/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "d3f70c56-ff92-468e-a8e7-bdede76909d1",
        name: "Malaysia",
        displayName: "Malaysia",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "馬來西亞" },
          link: {
            value: {
              href: "https://www.fwd.com.my/en/",
              linktype: "external",
              url: "https://www.fwd.com.my/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "0bff6386-fa8a-4ba5-800f-24c2afffbbe0",
        name: "Philippines",
        displayName: "Philippines",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "菲律賓" },
          link: {
            value: {
              href: "https://www.fwd.com.ph/en/",
              linktype: "external",
              url: "https://www.fwd.com.ph/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "7bb44e4e-75eb-4b9d-8ff8-4a4a8203cf36",
        name: "Singapore",
        displayName: "Singapore",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "新加坡" },
          link: {
            value: {
              href: "https://www.fwd.com.sg/",
              linktype: "external",
              url: "https://www.fwd.com.sg/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "dc572c84-d525-48c9-a5e0-6215970d93b4",
        name: "Thailand",
        displayName: "Thailand",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "泰國" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/",
              linktype: "external",
              url: "https://www.fwd.co.th/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "4499f614-f9e5-4cd8-a924-8a8ce0c82348",
        name: "Vietnam",
        displayName: "Vietnam",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "越南" },
          link: {
            value: {
              href: "https://www.fwd.com.vn/en/",
              linktype: "external",
              url: "https://www.fwd.com.vn/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
    ],
    socialMediaLinks: [
      {
        id: "edf939e0-1e60-42b5-93db-523f893f16dd",
        name: "Facebook",
        displayName: "Facebook",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "facebook" },
          link: {
            value: {
              href: "https://www.facebook.com/FWD.HK.MO/",
              text: "facebook",
              linktype: "external",
              url: "https://www.facebook.com/FWD.HK.MO/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "90db1f26-feb6-4049-bd0b-f66ad50b6dd0",
        name: "YouTube",
        displayName: "YouTube",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "youtube" },
          link: {
            value: {
              href: "https://www.youtube.com/FWDHK",
              text: "youtube",
              linktype: "external",
              url: "https://www.youtube.com/FWDHK",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
    ],
    footerQuickLinks: [
      {
        id: "e7ae2988-5323-46a2-bba4-6bbff858b107",
        name: "Sitemap",
        displayName: "Sitemap",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "網站指南 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/sitemap/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{163C1C9B-5497-413A-96C7-23AF7CB06E80}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "814e649f-6415-4c2e-aed7-64b7f2e1bd7c",
        name: "Online security",
        displayName: "Online security",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "網上保安 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/online-security/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{B49AECB1-755D-4D22-BE7D-2F7263D5DE27}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "a0d3a063-f1ba-48ad-94fb-e42f338af381",
        name: "Disclaimer",
        displayName: "Disclaimer",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "免責聲明 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/disclaimer/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{5A1FC210-94BB-4794-A258-23135CF3A3C1}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "716e4166-da74-41d5-907d-04e7555c93f5",
        name: "Insurance levy",
        displayName: "Insurance levy",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "保監局徵費附例 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/insurance-levy/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{0B7F57BA-7515-413E-8AB2-C9EBF635B160}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "49481599-18fa-4639-a656-bedb93e665f8",
        name: "Common reporting standard",
        displayName: "Common reporting standard",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "共同匯報標準 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/common-reporting-standard/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{253340F5-CCDF-4AEE-AE8F-767BA7F78C57}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "db48da58-7cca-49a3-9cf7-f11b92c23f77",
        name: "Important notice",
        displayName: "Important notice",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "重要通告 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/important-notice/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{E6BC512A-E439-4527-BBC9-18DDD6512A35}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "92c48be8-f427-406c-b6ac-7f23f0f11e76",
        name: "Use of cookies",
        displayName: "Use of cookies",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "Cookie政策 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/use-of-cookies/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{DCD61657-FA88-4252-B058-0B7EBE7F06F1}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "15cd02ca-2c2e-4ae9-9442-dac60f1f4beb",
        name: "Personal data protection policy and practices",
        displayName: "Personal data protection policy and practices",
        fields: {
          buttonIcon: {
            id: "f637d977-efa3-43f2-966a-b6aa2db4e70b",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/linkvariant/chevron-right",
            fields: {
              value: { value: "chevron-right" },
              key: { value: "chevronRight" },
            },
          },
          title: { value: "個人資料保護政策及執行 " },
          link: {
            value: {
              href: "https://www.fwd.com.hk/privacy-policy/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{BDB6C45F-3372-477C-A588-00DBEBB701C0}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
    ],
    copyrightLabel: {
      value:
        "2021 富衛人壽保險（百慕達）有限公司 <br />\n（於百慕達註冊成立之有限公司）版權所有　不得轉載",
    },
    defaultComplianceAccreditation: null,
    disableComplianceAccreditation: { value: false },
    gaLabelName: { value: "" },
    gaCategoryName: { value: "" },
    enableLanguageToggle: { value: true },
    languageList: [
      {
        id: "f7d73a71-1c14-4b6a-8db1-263b40425b4f",
        fields: {
          languageName: { value: "EN" },
          defaultLanguage: { value: "Tc" },
          languageCode: { value: "en" },
        },
      },
      {
        id: "8e721d84-8219-4d9b-9470-4621a8beff37",
        fields: {
          languageName: { value: "繁" },
          defaultLanguage: { value: "En" },
          languageCode: { value: "tc" },
        },
      },
    ],
    languageModalDescription: { value: "" },
    languageModalLink: { value: {} },
    languageModalSecondaryLink: { value: "" },
    languageModalTitle: { value: "" },
  },
  params: {
    backgroundColor: "white-orange",
    paddingTop: "Medium",
    paddingBottom: "None",
  },
  sitecoreContext: {
    language: "繁",
    languageCode: "tc",
    availableLanguages: ["tc", "en"],
  },
};
