import React from "react";
import { withStyles } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";
import closeIconSrc from '../../../assets/general/close-black-2x.png';

const DialogCloseButton = ({ classes, onClick }) => (
  <div className={classes.container} onClick={onClick}>
    <img className={classes.closeIcon} src={closeIconSrc}></img>
  </div>
);

const DialogCloseButtonStyled = withStyles({
  container: {
    // Position
    position: 'absolute',
    top: -32,
    right: -32,

    // Container style
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: COLORS.white,

    // Content
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  closeIcon: {
    height: 16,
    width: 16,
  },
})(DialogCloseButton);

export default DialogCloseButtonStyled;
