import React, { useState } from "react";

import PolicyValidationForm from "./PolicyValidationForm";
import NewCustomerLeadForm from "./NewCustomerLeadForm";
import { leadFormType, redeemFlowTypes } from "../../../constant/redeem";

const pageTypes = {
  policy: "policy",
  lead: "lead",
  resultValidationSuccess: "resultValidationSuccess",
  resultTrial: "resultTrial",
};

const initialPageTypes = {
  [redeemFlowTypes.policyValidationOnly]: pageTypes.policy,
  [redeemFlowTypes.policyValidationOrTrialWithLead]: pageTypes.policy,
  [redeemFlowTypes.policyValidationOrTrialWithoutLead]: pageTypes.policy,
  [redeemFlowTypes.trialWithLeadOnly]: pageTypes.lead,
  [redeemFlowTypes.directShow]: pageTypes.resultTrial,
};

const RedeemFlowHandlerFullPage = ({
  redeemFlowType = redeemFlowTypes.policyValidationOrTrialWithLead,
  renderResultViewValidationSuccess = (clientId, clientInfo) => null,
  renderResultViewTrial = () => null,
  leadFormFor = leadFormType.leadFormForTF,
  title,
  caption,
  displayConsentInValidation = false,
  newCustomerTitle,
  newCustomerActionLabel,
  validationFormRemark,
  providedBySection,
  redeemPageDisplayTFIcon = true,
}) => {
  const initialPageBasedOnRedeemFlowType = initialPageTypes[redeemFlowType];
  const [showingPageType, setShowingPageType] = useState(initialPageBasedOnRedeemFlowType);
  const [policyHolderClientId, setPolicyHolderClientId] = useState(null);
  const [policyHolderClientInfo, setPolicyHolderClientInfo] = useState(null);
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const onGoToPolicyValidation = () => {
    setShowingPageType(pageTypes.policy);
  };

  const onGoToLeadForm = () => {
    setShowingPageType(pageTypes.lead);
  };

  const onGoToResultValidationSuccess = ({ encryptedCustomerId, clientInfoObj, idNumber, dob }) => {
    setPolicyHolderClientId(encryptedCustomerId);
    setPolicyHolderClientInfo(clientInfoObj);
    setShowingPageType(pageTypes.resultValidationSuccess);
  };

  const onGoToResultTrial = () => {
    setShowingPageType(pageTypes.resultTrial);
  };

  if (redeemFlowType === redeemFlowTypes.policyValidationOnly) {
    return (
      <>
        {showingPageType === pageTypes.policy && <PolicyValidationForm isFullPage onSubmitSuccess={onGoToResultValidationSuccess} />}
        {showingPageType === pageTypes.resultValidationSuccess && renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
      </>
    );
  }

  if (redeemFlowType === redeemFlowTypes.policyValidationOrTrialWithLead) {
    return (
      <>
        {showingPageType === pageTypes.policy && (
          <PolicyValidationForm
            isFullPage
            onSubmitSuccess={onGoToResultValidationSuccess}
            onClickIAmNewCustomer={onGoToLeadForm}
            title={title}
            caption={caption}
            displayConsentInValidation={displayConsentInValidation}
            newCustomerTitle={newCustomerTitle}
            newCustomerActionLabel={newCustomerActionLabel}
            remark={validationFormRemark}
            redeemPageDisplayTFIcon={redeemPageDisplayTFIcon}

            idDocumentRemarks={currentBu === "vn"}
          />
        )}
        {showingPageType === pageTypes.lead && (
          <NewCustomerLeadForm isFullPage onGoBack={onGoToPolicyValidation} onSubmitSuccess={onGoToResultTrial} leadFormFor={leadFormFor} providedBySection={providedBySection} />
        )}
        {showingPageType === pageTypes.resultValidationSuccess && renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
        {showingPageType === pageTypes.resultTrial && renderResultViewTrial()}
      </>
    );
  }

  if (redeemFlowType === redeemFlowTypes.policyValidationOrTrialWithoutLead) {
    return (
      <>
        {showingPageType === pageTypes.policy && (
          <PolicyValidationForm isFullPage onSubmitSuccess={onGoToResultValidationSuccess} onClickIAmNewCustomer={onGoToResultTrial} />
        )}
        {showingPageType === pageTypes.resultValidationSuccess && renderResultViewValidationSuccess(policyHolderClientId, policyHolderClientInfo)}
        {showingPageType === pageTypes.resultTrial && renderResultViewTrial()}
      </>
    );
  }

  if (redeemFlowType === redeemFlowTypes.trialWithLeadOnly) {
    return (
      <>
        {showingPageType === pageTypes.lead && <NewCustomerLeadForm isFullPage onSubmitSuccess={onGoToResultTrial} leadFormFor={leadFormFor} providedBySection={providedBySection} />}
        {showingPageType === pageTypes.resultTrial && renderResultViewTrial()}
      </>
    );
  }

  if (redeemFlowType === redeemFlowTypes.directShow) {
    return <>{showingPageType === pageTypes.resultTrial && renderResultViewTrial()}</>;
  }

  return null;
};

export default RedeemFlowHandlerFullPage;
