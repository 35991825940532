import { makeStyles, Slider } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import HorizontalSliderPointer from "../../../assets/eTriage/questions/options/horizontalSliderPointer.svg";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  amplitudeOptionsContainer: {
    marginTop: 20,
  },
  circularSliderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  rainbowCircleContainer: {
    width: "100%",
    maxWidth: 300,
    flexDirection: "column",
  },
  rainbowCircleNumber: {
    width: "100%",
  },
  rainbowCircle: {
    width: "100%",
    marginTop: -88,
  },
  sliderContainer: {
    // position: "absolute",
    marginTop: -150,
    "& *": {
      fill: "transparent",
    },
    "& svg": {
      height: 150,
    },
  },
  sliderPointer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "50%",
    marginTop: "-20px",
    transformOrigin: "bottom right",
    transform: "rotate(90deg)",
  },
  horizontalSliderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  horizontalSlider: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  horizontalSliderLabelContainer: {
    display: "flex",
    flexDirection: "row",
  },
  horizontalSliderLabelBox: {
    flex: 2.33,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  firstItemLabelBox: {
    flex: 1,
  },
  lastItemLabelBox: {
    flex: 1,
  },
  horizontalSliderLabel: {
    fontWeight: 450,
    fontSize: 12,
    lineHeight: "18px",
    color: COLORS.fwdDardGreen50,
    alignItems: "center",
    textAlign: "center",
  },
  firstItem: { textAlign: "left" },
  lastItem: { textAlign: "right" },
  horizontalSliderLabelIndicator: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  horizontalSliderLabelIndicatorLine: {
    backgroundColor: COLORS.fwdDardGreen50,
    width: 2,
    height: 7,
    margin: 5,
  },
  colorBlockContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  colorBlock: {
    height: 35,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  firstColorBlock: {
    borderRadius: "8px 0px 0px 8px",
  },
  lastColorBlock: {
    borderRadius: "0px 8px 8px 0px",
  },
  horizontalSliderBarContainer: {
    marginTop: -15,
  },
  horizontalSliderBar: {
    width: "88%",
    alignSelf: "center",
    "& .MuiSlider-thumb::after": {
      backgroundImage: `url(${HorizontalSliderPointer})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    "& .MuiSlider-thumb.MuiSlider-active": {
      boxShadow: "none",
    },
    "& .MuiSlider-rail": {
      height: 30,
      top: -15
    },
  },
  horizontalSliderBarSelected: {
    border: `4px solid ${COLORS.white}`,
    boxShadow: "4px 4px 8px rgb(0 0 0 / 15%)",
  },
  indexLabel: {
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "18px",
    color: COLORS.fwdDarkGreen,
  },
}));

function AmplitudeSelectGroups({ onAnswerSelect, selectedAnswer }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(selectedAnswer ?? 0);
  const [pointerDegree, setPointerDegree] = useState(0); //0 - 150

  const handleChange = (data) => {
    const dataInt = parseInt(data);
    setSelectedValue(dataInt);
    // setPointerDegree((150 / 8) * dataInt);
    // optimizedSelectedFn(dataInt);
    handleAnswerSelected(dataInt);
  };

  useEffect(() => {
    setPointerDegree((150 / 8) * selectedValue);
  }, [selectedValue]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleAnswerSelected = (value) => {
    onAnswerSelect(value);
  };

  // const optimizedSelectedFn = useCallback(debounce(handleAnswerSelected), []);

  const amplitudeLabelList = [
    { label: "notAtAll" },
    { label: "slightly" },
    { label: "definitely" },
    { label: "markedly" },
    { label: "verySeverly" },
  ];

  const amplitudeBoxList = [
    { value: 0, color: "#FFFFFF" },
    { value: 1, color: "rgb(255, 245, 210)" },
    { value: 2, color: "rgb(255,235,166)" },
    { value: 3, color: "rgb(255,219,102)" },
    { value: 4, color: "rgb(255,203,36)" },
    { value: 5, color: "rgb(246,192,35)" },
    { value: 6, color: "rgb(232,180,32)" },
    { value: 7, color: "rgb(174,135,28)" },
    { value: 8, color: "rgb(146,114,25)" },
  ];
  return (
    <>
      <div className={classes.amplitudeOptionsContainer}>
        {/* <div className={classes.circularSliderContainer}>
          <div className={classes.circularSlider}>
            <div className={classes.rainbowCircleContainer}>
              <img
                src={SemiCircleNumber}
                className={classes.rainbowCircleContainer}
              />
              <img
                src={RainbowSemiCircle}
                className={classes.rainbowCircle}
                onContextMenu={() => {
                  return false;
                }}
              />
            </div>
            <div
              className={classes.sliderPointer}
              style={{ transform: `rotate(${pointerDegree}deg)` }}
            >
              <img
                src={SliderPointerSvg}
                onContextMenu={() => {
                  return false;
                }}
              />
            </div>
            <div className={classes.sliderContainer}>
              <CircularSlider
                size={300}
                minValue={0}
                maxValue={9}
                startAngle={96}
                endAngle={264}
                angleType={{
                  direction: "cw",
                  axis: "-y",
                }}
                handle1={{
                  value: selectedValue,
                  onChange: (v) => handleChange(v),
                }}
              />
            </div>
          </div>
        </div> */}
        <div className={classes.horizontalSliderContainer}>
          <div className={classes.horizontalSlider}>
            <div className={classes.horizontalSliderLabelContainer}>
              {amplitudeLabelList.map((item, index) => {
                const firstItem = index == 0;
                const lastItem = index == amplitudeLabelList.length - 1;
                return (
                  <div
                    key={index}
                    className={clsx(
                      classes.horizontalSliderLabelBox,
                      firstItem && classes.firstItemLabelBox,
                      lastItem && classes.lastItemLabelBox
                    )}
                  >
                    <div
                      className={clsx(
                        classes.horizontalSliderLabel,
                        firstItem && classes.firstItem,
                        lastItem && classes.lastItem
                      )}
                    >
                      {t(
                        `eTriageQuestionsPage.options.amplitude_${item.label}`
                      )}
                    </div>
                    <div className={classes.horizontalSliderLabelIndicator}>
                      <div
                        className={classes.horizontalSliderLabelIndicatorLine}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.horizontalSliderContainer}>
          <div className={classes.colorBlockContainer}>
            {amplitudeBoxList.map((item, index) => {
              const firstItem = index == 0;
              const lastItem = index == amplitudeBoxList.length - 1;
              const isSelected = item.value == selectedValue;
              return (
                <div
                  key={index}
                  className={clsx(
                    classes.colorBlock,
                    firstItem && classes.firstColorBlock,
                    lastItem && classes.lastColorBlock,
                    isSelected && classes.horizontalSliderBarSelected
                  )}
                  style={{ backgroundColor: `${item.color}` }}
                >
                  <div className={classes.indexLabel}>{index}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.horizontalSliderContainer}>
          <div
            className={clsx(
              classes.horizontalSlider,
              classes.horizontalSliderBarContainer
            )}
          >
            <div className={classes.horizontalSliderBar}>
              <Slider
                defaultValue={0}
                step={1}
                marks
                min={0}
                max={8}
                value={selectedValue}
                onChange={(event, value) => {
                  handleChange(value);
                }}
                style={{ color: "transparent" }}
              ></Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AmplitudeSelectGroups;
