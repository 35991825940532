import React from "react";
import { withStyles } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";

import ActionIcon from "./ActionIcon";
import AppIcon from "./AppIcon";
import { fontSizes } from "../../../constant/font";
import clsx from "clsx";
import HtmlParser from "react-html-parser";

const sectionHeaderSizes = {
  large: 'large',
  medium: 'medium',
  embedComponentTitle: 'embedComponentTitle',
};
const sectionHeaderAlignDirections = {
  left: 'left',
  center: 'center',
};

const SectionHeader = ({ classes, text, size = sectionHeaderSizes.medium, alignDirection = sectionHeaderAlignDirections.left, onGoBack, rightIconSrc }) => (
  <div className={alignDirection === sectionHeaderAlignDirections.left ? classes.headerContainerLeft : classes.headerContainerCenter}>
    {
      // Back icon
      !!onGoBack &&
      <div className={classes.leftItemContainer}>
        <ActionIcon type='arrowLeft' onClick={onGoBack} />
      </div>
    }
    <div className={size === sectionHeaderSizes.large ? classes.sectionTitleLarge : size === sectionHeaderSizes.embedComponentTitle ? classes.embedComponentTitle : classes.sectionTitleMedium}>{HtmlParser(text)}</div>
    {
      // Custom icon on the right
      !!rightIconSrc &&
      <div className={classes.rightItemContainer}>
        <AppIcon imageSrc={rightIconSrc} />
      </div>
    }
  </div>
);

const SectionHeaderStyled = withStyles({
  headerContainerLeft: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainerCenter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
  },
  rightItemContainer: {
    marginLeft: 16,
  },
  sectionTitleLarge: {
    fontSize: fontSizes.titleLarge,
    fontWeight: 700,
    lineHeight: '31px',
    color: COLORS.fwdDarkGreen,
    textAlign: 'center'
  },
  sectionTitleMedium: {
    fontSize: fontSizes.titleMedium,
    fontWeight: 700,
    lineHeight: '25px',
    color: COLORS.fwdDarkGreen,
  },

  embedComponentTitle: {
    fontSize: fontSizes.titleLarge,
    fontWeight: 700,
    lineHeight: '31px',
    color: COLORS.fwdDarkGreen,
    textAlign: 'left'
  }
})(SectionHeader);

export {
  sectionHeaderSizes,
  sectionHeaderAlignDirections,
};

export default SectionHeaderStyled;
