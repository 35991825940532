import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import NotAtAllSvg from "../../../assets/eTriage/questions/options/notAtAll.svg";
import SeveralDaysSvg from "../../../assets/eTriage/questions/options/severalDays.svg";
import MoreThanHalfTheDaysSvg from "../../../assets/eTriage/questions/options/moreThanHalfTheDays.svg";
import NearlyEverydaysSvg from "../../../assets/eTriage/questions/options/nearlyEverydays.svg";
import { FrequencyOptions } from "../../../constant/etriage/options";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";


const useStyles = makeStyles((theme) => ({
  frequencyButtonRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  frequencyButtonContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  frequencyButton: {
    border: `1px solid ${COLORS.fwdGrey}`,
    borderRadius: 8,
    flex: 1,
    maxWidth: 164,
    height: 110,
    // aspectRatio: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "center",
    // "&:hover": {
    //   // backgroundColor: COLORS.fwdOrange20,
    //   border: `1px solid ${COLORS.fwdOrange}`,
    // },
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "20px",
      padding: 10,
    },
    "& span": {
      whiteSpace: "nowrap"
    }
  },
  buttonImage: {
    // width: "33%",
    width: 43,
    height: 42,
    // marginBottom: 15,
  },
  selected: {
    backgroundColor: COLORS.fwdOrange20,
    border: `2px solid ${COLORS.fwdOrange}`,
    color: COLORS.fwdOrange,
  },
}));

function FrequencySelectGroups({ onAnswerSelect, selectedAnswer }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [answerSelected, setAnswerSelected] = useState(null);

  const iconSvg = {
    notAtAll: NotAtAllSvg,
    severalDays: SeveralDaysSvg,
    moreThanHalfTheDays: MoreThanHalfTheDaysSvg,
    nearlyEveryday: NearlyEverydaysSvg,
  };

  useEffect(() => {
    setAnswerSelected(selectedAnswer);
  }, [selectedAnswer]);

  return (
    <>
      <div className={classes.frequencyButtonGroups}>
        <div className={classes.frequencyButtonRow}>
          {FrequencyOptions.map((item, itemIndex) => {
            return (
              <div className={classes.frequencyButtonContainer} key={itemIndex}>
                <div
                  className={clsx(
                    classes.frequencyButton,
                    answerSelected == item.value && classes.selected
                  )}
                  onClick={() => {
                    onAnswerSelect(item.value);
                  }}
                >
                  <img
                    src={iconSvg[item.icon]}
                    className={classes.buttonImage}
                  />
                  {ReactHtmlParser(
                    t(item.label)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default FrequencySelectGroups;
