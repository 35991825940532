export const COLORS = {
  white: "#ffffff",
  black: "#000000",
  // your colors

  fwdOrange: "#E87722",
  headerBackgroundColor: "#FFF4EE",
  fwdOrange20: "#FFECDE",
  fwdOrange50: "#F3BB90",
  fwdOrangeLighter5: "#FEF9F4",
  fwdOrangeLighter20: "#FAE4D3",
  fwdGrey: "#EDEFF0",
  fwdGreyDarker: "#8B8E8F",
  fwdGreyDarkest: "#636566",
  fwdGrey20: "#F8F9F9",
  fwdDardGreen50: "#8B9793",
  fwdDardGreen20: "#CED8D6",
  fwdDarkGreen: "#183028",
  fwdLightGreen20: "#E2F5F0",
  fwdLightGreen50: "#B6E6D8",
  fwdBlue: "#0097A9",
  fwdBlue50: "#7FCBD4",
  fwdYellow: "#FED141",
  fwdYellow20: "#FFF6D9",
  fwdYellow50: "#FEE8A0",
  fwdAlertRed: "#B30909",
  fwdBackgroundGrey: "#F5F5F5",

  borderLightGrey: "#DBDFE1",
};
