import React from "react";
import { withStyles } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";

// TODO: Support "display: inline"
const ActionIcon = ({ classes, fill = COLORS.fwdOrange, type, onClick }) => (
  <div className={classes.container} onClick={onClick}>
    { type === 'arrowLeft' &&
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.353 16.5609L10.707 16.2074L4.4145 9.91537L19 9.91538L19 7.91537L4.4135 7.91537L10.7065 1.62187L10.353 1.26887C9.7675 0.682874 8.8175 0.682874 8.2315 1.26887L0.586001 8.91487L8.232 16.5609C8.8175 17.1464 9.7675 17.1464 10.353 16.5609Z"
          fill={fill} />
      </svg>
    }
    { type === 'arrowRight' &&
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg" >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.647 0.439124L8.293 0.792624L14.5855 7.08462L0 7.08462L0 9.08462L14.5865 9.08462L8.2935 15.3781L8.647 15.7311C9.2325 16.3171 10.1825 16.3171 10.7685 15.7311L18.414 8.08512L10.768 0.439124C10.1825 -0.146376 9.2325 -0.146376 8.647 0.439124Z"
          fill={fill} />
      </svg>
    }
    { type === 'custom' && null}
  </div>
);

const ActionIconStyled = withStyles({
  container: {
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
})(ActionIcon);

export default ActionIconStyled;
