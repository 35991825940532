import { SymptomsLevel, SystomsLevelDto } from "./symptomsLevel";

const noImpairment: SystomsLevelDto = {
  min: 0,
  max: 9,
  description: "eTriageResultPage.description.impairment.noImpairment",
  isEmergency: false,
  levelScore: 0,
  level: "no",
};

const moderateImpairment: SystomsLevelDto = {
  min: 10,
  max: 19,
  description: "eTriageResultPage.description.impairment.moderateImpairment",
  isEmergency: false,
  levelScore: 2,
  level: "mild",
};

const severeImpairment: SystomsLevelDto = {
  min: 20,
  description: "eTriageResultPage.description.impairment.severeImpairment",
  isEmergency: true,
  levelScore: 3,
  level: "severe",
};

export const impairmentLevels: SymptomsLevel[] = [
  new SymptomsLevel(noImpairment),
  new SymptomsLevel(moderateImpairment),
  new SymptomsLevel(severeImpairment),
];
