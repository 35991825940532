import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import clsx from "clsx";
import TabPng from "../../../assets/landing/tab.png";
import ReactPlayer from "react-player";
import videoPlaySvg from "../../../assets/landing/videoPlay.svg";
import currentPlayingVideoSvg from "../../../assets/landing/currentPlayingVideoIcon.svg";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  videoListContainer: {
    marginTop: 20,
    marginBottom: 40,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: 5,
    },
  },
  videoListTab: {
    display: "flex",
    color: COLORS.fwdOrange,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    paddingRight: 30,
  },
  videoListTabLabel: {
    backgroundColor: COLORS.white,
    padding: "10px 2px 10px 15px",
    zIndex: 2,
    borderRadius: "8px 0px 0px 0px",
  },
  videoListTabAfterTriangle: {
    backgroundImage: `url(${TabPng})`,
    width: 35,
    backgroundPosition: "right top",
    backgroundSize: "cover",
    marginLeft: -1,
    backgroundRepeat: "no-repeat",
  },
  videoListBox: {
    marginTop: -2,
    background: COLORS.white,
    borderRadius: "0px 16px 16px 16px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      padding: 0,
    },
  },
  videoListDetailContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    borderBottom: `1px solid ${COLORS.fwdGrey}`,
    [theme.breakpoints.up("md")]: {
      flex: 1.5,
      padding: 15,
      backgroundColor: COLORS.white,
      borderRadius: "0px 16px 16px 16px",
      flexDirection: "column-reverse",
    },
  },
  videoContainer: {
    marginBottom: 20,
    position: "relative",

    "& .videoClass video": {
      borderRadius: 16,
      overflow: "hidden",
    },
  },
  videoPlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },

  videoList: {
    marginTop: 10,
    padding: "15px 0px",
    height: 360,
    overflow: "scroll",
    [theme.breakpoints.up("md")]: {
      flex: 1,
      height: "auto",
      overflow: "scroll",
      borderRadius: "0px 16px 16px 16px",
      position: "relative",
      overflow: "hidden",
    },
  },
  videoListInnerContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflowY: "scroll",
    },
  },
  videoListItemRow: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    cursor: "pointer",
    "&:hover": {
      background: COLORS.fwdGrey20,
    },
  },
  currentVideoListItemRow: {
    background: COLORS.fwdOrange20 + "50",
  },
  currentPlayingVideoIconContainer: {
    width: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 5
  },
  currentPlayingVideoIcon: {
    width: '100%'
  },
  videoListItemRowContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  videoThumbnail: {
    width: 104,
    height: 75,
    objectFit: "cover",
    borderRadius: 5,
    marginRight: 10,
  },
  videoThumbnailCaptionContainer: {
    flexGrow: 1,
    width: "50%"
  },
  videoThumbnailCaption: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 700,
    color: COLORS.fwdDarkGreen,
  },
  videoThumbnailPresenter: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontSize: 16,
    lineHeight: "24x",
    fontWeight: 450,
    color: COLORS.fwdDarkGreen,
  },
  currentVideoInfoRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  currentVideoThumbnail: {
    height: 50,
    width: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  currentPresenterName: {
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    color: COLORS.fwdDarkGreen,
  },
  currentCaption: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    color: COLORS.fwdDarkGreen,
  },
  currentVideoLength: {
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    color: COLORS.fwdDardGreen50,
  },
}));

function LearningCenterVideoListSections() {
  let paramsKey = useParams();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const paramsFile = require(`../../../constant/landingJson/landingVideoJson_${currentBu}_${i18n.language}`);

  const params = paramsFile["landingVideoJson"];

  const videoList = params.learningCenterVideoList;

  const [currentVideo, setCurrentVideo] = useState(videoList[0]);
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    setVideoPlaying(false);
    setCurrentVideo(videoList[0]);
  }, [i18n.language]);

  useEffect(() => {
    if (paramsKey.videoKey) {
      const specificVideo = videoList.filter((item) => { return item.videoKey == paramsKey.videoKey });
      if (specificVideo.length > 0) {
        setCurrentVideo(specificVideo[0]);
      }
    }
  }, [paramsKey]);
  return (
    <>
      <div className={classes.videoListContainer}>
        <div className={classes.videoListTab}>
          {/* <div> {`Option ${letter} -`}</div> */}
          <div className={classes.videoListTabLabel}>
            {t("productLanding.learningCenter.nowPlaying")}
          </div>
          <div className={classes.videoListTabAfterTriangle} />
        </div>
        <div className={classes.videoListBox}>
          <div className={classes.videoListDetailContainer}>
            <div className={classes.videoContainer}>
              <ReactPlayer
                className={"videoClass"}
                url={currentVideo.videoUrl}
                controls={true}
                width={"100%"}
                height={"auto"}
                playsinline={true}
                playing={videoPlaying}
                onPlay={() => {
                  setVideoPlaying(true);
                }}
              />
              {!videoPlaying && (
                <img
                  alt={"Video Play Button Icon"}
                  src={videoPlaySvg}
                  className={classes.videoPlayIcon}
                  onClick={() => {
                    setVideoPlaying(true);
                  }}
                />
              )}
            </div>
            <div className={classes.currentVideoInfoRow}>
              <img
                alt={"Current Video thumbnail"}
                className={clsx([
                  classes.videoThumbnail,
                  classes.currentVideoThumbnail,
                ])}
                src={currentVideo.thumbnail}
              ></img>

              <div>
                <div className={classes.currentCaption}>
                  {currentVideo.caption}
                </div>
                <div className={classes.currentPresenterName}>
                  <div>
                    {currentVideo.presenterName} | {currentVideo.presenterTitle}
                  </div>
                </div>
                <div className={classes.currentVideoLength}>
                  {currentVideo.videoLength}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.videoList}>
            <div className={classes.videoListInnerContainer}>
              {videoList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(classes.videoListItemRow, item == currentVideo && classes.currentVideoListItemRow)}
                    onClick={() => setCurrentVideo(item)}
                  >
                    <div className={classes.videoListItemRowContainer}>
                      <div className={classes.currentPlayingVideoIconContainer}>
                        {item == currentVideo && (
                          <img
                            alt={"Currently Playing Video Icon"}
                            src={currentPlayingVideoSvg}
                            className={classes.currentPlayingVideoIcon}

                          />
                        )}
                      </div>

                      <img
                        alt={"Video thumbnail"}
                        className={classes.videoThumbnail}
                        src={item.thumbnail}
                        style={
                          item.thumbnailObjectPosition && {
                            objectPosition: item.thumbnailObjectPosition,
                          }
                        }
                      ></img>

                      <div className={classes.videoThumbnailCaptionContainer}>
                        <div className={classes.videoThumbnailCaption}>
                          {item.caption}
                        </div>
                        <div className={classes.videoThumbnailPresenter}>
                          {item.presenterName} | {item.presenterTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearningCenterVideoListSections;
