const AWS = require("aws-sdk");

export const getClientPolicy = async (governmentIdCard, dateOfBirth) => {
  if (process.env.REACT_APP_BU === 'hk') {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        governmentidcard: governmentIdCard,
        dob: dateOfBirth,
        productcode: process.env.REACT_APP_VALIDATION_PRODUCT_CODE,
        country: process.env.REACT_APP_VALIDATION_COUNTRY,
      },
    };

    let response;
    try {
      response = await fetch(
        process.env.REACT_APP_AWS_CLOUDFRONT_TO_LAMBDA,
        requestOptions
      )
    } catch (err) {
      console.error(err);
    }

    if (response.ok) {
      return await response.json();
    }
    throw new Error(response.status);

  } else {
    const lambda = new AWS.Lambda({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    return await new Promise((resolve, reject) => {
      lambda.invoke(
        {
          Qualifier: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION_VERSION,
          FunctionName: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION,
          Payload: JSON.stringify({
            headers: {
              governmentidcard: governmentIdCard,
              dob: dateOfBirth,
              productcode: process.env.REACT_APP_VALIDATION_PRODUCT_CODE,
              country: process.env.REACT_APP_VALIDATION_COUNTRY,
            },
          }),
        },
        async (err, res) => {
          if (err) {
            console.log(JSON.stringify(err));
            reject(err);
          }
          resolve(JSON.parse(res.Payload).body);
        }
      );
    });
  }
};

export const validateOtp = async (mobileNumber, otpCode) => {
  const lambda = new AWS.Lambda({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  return await new Promise((resolve, reject) => {
    lambda.invoke(
      {
        Qualifier: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION_VERSION,
        FunctionName: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION,
        Payload: JSON.stringify({
          headers: {
            'action': 'VALIDATE_OTP',
            'mobileNumber': mobileNumber,
            'otp': otpCode,
          },
        }),
      },
      async (err, res) => {
        if (err) {
          console.log(JSON.stringify(err));
          reject(err);
        }
        resolve(JSON.parse(res.Payload).body);
      }
    );
  });
};


export const resendOtp = async (mobileNumber) => {
  const lambda = new AWS.Lambda({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  return await new Promise((resolve, reject) => {
    lambda.invoke(
      {
        Qualifier: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION_VERSION,
        FunctionName: process.env.REACT_APP_VALIDATION_LAMBDA_FUNCTION,
        Payload: JSON.stringify({
          headers: {
            'action': 'RESEND_OTP',
            'mobileNumber': mobileNumber,
          },
        }),
      },
      async (err, res) => {
        if (err) {
          console.log(JSON.stringify(err));
          reject(err);
        }
        resolve(JSON.parse(res.Payload).body);
      }
    );
  });
};
