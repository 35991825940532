import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import i18next from "i18next";
import env from "../../../env";

import chevronRightIconSrc from "../../../assets/general/chevron-right.png";

const useStyles = makeStyles((theme) => ({
    popupContainer: {
        backgroundColor: COLORS.white,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontSize: 25,
        fontWeight: 700,
        lineHeight: "31px",
        color: COLORS.fwdDarkGreen,
        marginBottom: 20,
    },
    languageButtonContainer: {
        border: `1px solid ${COLORS.fwdOrange}`,
        borderRadius: 8,
        padding: 16,
        margin: "10px 0px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
    },
    languageButtonLabel: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 700,
        color: COLORS.fwdDarkGreen,
    },
    chevronRightIcon: {
        width: 24,
        height: 24,
    },
}));

function SelectLanguagePopup({ onLanguageSelected }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const currentBu = process.env.REACT_APP_BU ?? "hk";


    const languageOnClick = (langKey) => {
        const changeLanguage = langKey;
        i18next.changeLanguage(changeLanguage, (err, t) => {
            document.title = t(`tabTitle.programme.${currentBu}`) + " | " + t(`tabTitle.${currentBu}`);

            const currentPath = window.location.pathname;
            const firstSectionOfPath = currentPath.split("/")[1] ?? currentPath;
            const supportLang = process.env.REACT_APP_LANG.split(",");
            const newPath =
                supportLang.indexOf(firstSectionOfPath) >= 0 ? currentPath.replace(firstSectionOfPath, changeLanguage) : "/" + changeLanguage + currentPath;
            window.history.replaceState(null, "", newPath);
        });
        onLanguageSelected();
    };

    const languageButton = (langKey) => {
        return (
            <div
                className={classes.languageButtonContainer}
                onClick={() => {
                    languageOnClick(langKey);
                }}>
                <div className={classes.languageButtonLabel}>{t(`eTriageLanding.selectLanguagePopup.${env.currentBu}.${langKey}`)}</div>

                <img className={classes.chevronRightIcon} src={chevronRightIconSrc}></img>
            </div>
        );
    };

    return (
        <>
            <div>
                <div component="popupover" className={classes.popupContainer}>
                    <div className={classes.title}>{t(`eTriageLanding.selectLanguagePopup.title`)}</div>

                    {process.env.REACT_APP_LANG.split(",").map((langKey) => {
                        return languageButton(langKey);
                    })

                    }
                    {/* {languageButton("en")}
                    {languageButton("bm")} */}
                </div>
            </div>
        </>
    );
}

export default SelectLanguagePopup;
