import moment from "moment";

const localeMap = {
  en: "en",
  tc: "zh-hk",
  th: "th",
  vi: "vi",
};

const updateMomentLocaleWithLang = (currentLanguage) => {
  const targetLocale = localeMap[currentLanguage] || 'en';
  console.debug(`Switching locale to ${targetLocale} for language ${currentLanguage}`);
  moment.locale(targetLocale);
}

const momentDateToBUDateStringUtils = {
  hk: (momentDate) => {
    // Goal: Get string of epoch time of the day at 00:00:00 in UTC timezone
    return momentDate // E.g. 1993-05-06T17:00:00+08:00
      .utcOffset(0, true) // Becomes 1993-05-06T17:00:00+00:00, note that epoch time has changed
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // Becomes 1993-05-06T00:00:00+00:00, note that epoch time has changed again
      .format("x"); // Unix Millisecond Timestamp
  },
  th: (momentDate) => {
    return momentDate.format("YYYYMMDD");
  },
  my: (momentDate) => {
    return momentDate.format("YYYYMMDD");
  },
  vn: (momentDate) => {
    return momentDate.format("DD/MM/YYYY");
  },
  id: (momentDate) => {
    return ''; // TBC
  },
}

const momentDateToYearString = (momentDate) => {
  return momentDate.format('YYYY');
}

export {
  updateMomentLocaleWithLang,
  momentDateToBUDateStringUtils,
  momentDateToYearString,
};
