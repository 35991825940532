import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import { useNavigate } from "react-router-dom";
import * as nextgen from "@fwd-dep/nextgen-ui-lib";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import clsx from "clsx";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: COLORS.white,
  },
}));

function FooterContainer({ }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  const [params, setParams] = useState();

  useEffect(() => {
    try {
      const paramsFile = require(`../../../constant/footerJson/footerJson_${currentBu}_${i18n.language}`);

      setParams(paramsFile["footerJson"]);

    } catch (err) {
    }
  }, [i18n.language])




  return (
    <>
      <div className={classes.footerContainer}>
        {params && (
          <nextgen.Footer
            {...params}
            gtmPushEvent={(event) => console.log(event)}
            onLanguageChange={(changeLanguage) => {
              i18next.changeLanguage(changeLanguage, (err, t) => {
                document.title = t(`tabTitle.programme.${currentBu}`) + " | " + t(`tabTitle.${currentBu}`);

                const currentPath = window.location.pathname;
                const firstSectionOfPath = currentPath.split("/")[1] ?? currentPath;
                const supportLang = process.env.REACT_APP_LANG.split(",");
                const newPath = supportLang.indexOf(firstSectionOfPath) >= 0 ? currentPath.replace(firstSectionOfPath, changeLanguage) : "/" + changeLanguage + currentPath;
                window.history.replaceState(null, "", newPath);

              });
            }}
          />
        )}

      </div>
    </>
  );
}

export default FooterContainer;
