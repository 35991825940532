import React, { useEffect, useState } from "react";
import QuestionsPage from "./QuestionsPage";
import ResultPage from "./Result/ResultPage";
import { save, load } from "../../api/survey";
import { useParams } from "react-router-dom";

import { Dialog, makeStyles } from "@material-ui/core";
import NewLandingPopup from "./components/NewLandingPopup";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import PolicyValidationForm from "../containers/redeemFlow/PolicyValidationForm";
import stringHash from "string-hash";
import env from "../../env";
import SelectLanguagePopup from "./components/SelectLanguagePopup";
import PopupContainer from "../layout/PopupContainer";

const displaySection = {
  LANDING: "LANDING",
  QUESTIONS: "QUESTIONS",
  RESULT: "RESULT",
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 10,
    minWidth: 400,
    maxWidth: 730,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
      margin: 10,
      width: "100%",
    },
  },
  policyValidationFormContainer: {
    maxWidth: 360,
  },
  selectLanguageContainer: {
    width: "100%",
    padding: 20,
  },
}));

function ETriagePageWithPopup() {
  const classes = useStyles();
  let params = useParams();
  const [displayPage, setDisplayPage] = useState("");
  const [answers, setAnswers] = useState([]);
  const [surveyKey, setSurveyKey] = useState("");
  const [entryDialogOpen, setEntryDialogOpen] = React.useState(false);
  const [validationDialogOpen, setValidationDialogOpen] = React.useState(false);
  const [selectLanguageDialogOpen, setSelectLanguageDialogOpen] = React.useState(false);
  const [clientId, setClientId] = useState(false);

  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  useEffect(() => {
    if (params.lang && process.env.REACT_APP_LANG.split(",").indexOf(params.lang) >= 0) {
      i18next.changeLanguage(params.lang);
      document.title =
        t(`tabTitle.programme.${currentBu}`) +
        " | " +
        t(`tabTitle.${currentBu}`);
    }
    console.log(params);
    console.log(`process.env.REACT_APP_BU ${process.env.REACT_APP_BU}`);
    console.log(`process.env.env ${process.env.REACT_APP_ENV}`);
  }, []);

  async function fetchAnswer() {
    const answersLoaded = await load(params.surveyId);
    if (answersLoaded.answers) {
      setAnswers(answersLoaded.answers);
      if (!env.isDisplayValidationPopupBeforeResultPage) {
        setDisplayPage(displaySection.RESULT);
      }

    } else {
      setDisplayPage(displaySection.QUESTIONS);
    }

  }

  const changePage = async () => {
    setEntryDialogOpen(false);
    setDisplayPage(displaySection.QUESTIONS);

    document
      .getElementById("root")
      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const tagGoArgs = (planCode = "") => {
    return {
      dataLayer: {
        event: "validate",
        buttonTitle: "go",
        pageUrl: window.location.href,
        componentName: "button",
        language: i18n.language,
        planCode: planCode,
      },
    };
  };

  const tagStartNowEventArgs = {
    dataLayer: {
      event: "buttonClick",
      buttonTitle: "start now",
      pageUrl: window.location.href,
      language: i18n.language,
      componentName: "button",
    },
  };

  const startWithConsent = () => {
    changePage();
    TagManager.dataLayer(tagStartNowEventArgs);
  };

  useEffect(() => {
    if (params.surveyId && answers.length == 0) {
      setEntryDialogOpen(false);
      fetchAnswer();
      setSurveyKey(params.surveyId);
      if (env.isDisplayValidationPopupBeforeResultPage) {
        setValidationDialogOpen(true);
      }
    } else {
      if (env.isDisplaySelectLangPopup) {
        setSelectLanguageDialogOpen(true);
        setEntryDialogOpen(false);
      } else {
        setSelectLanguageDialogOpen(false);
        setEntryDialogOpen(true);
      }
      setDisplayPage(displaySection.QUESTIONS);
    }
    return;
  }, [params]);


  const displayPolicyValidationPopup = () => {
    setValidationDialogOpen(true);

  };



  const onValidationSuccess = async ({ encryptedCustomerId, clientInfoObj, idNumber, dob }) => {
    setClientId(encryptedCustomerId);
    setValidationDialogOpen(false);
    setDisplayPage(displaySection.RESULT);
    if (!surveyKey) {
      setSurveyKey(await save(answers, stringHash(encryptedCustomerId), true, { productCode: clientInfoObj.productCode }));
      document
        .getElementById("root")
        .scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const onIsNonCustomer = async () => {
    setClientId(false);
    setValidationDialogOpen(false);
    setDisplayPage(displaySection.RESULT);
    if (!surveyKey) {
      setSurveyKey(await save(answers, "", false, {}));
      document
        .getElementById("root")
        .scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      {displayPage === displaySection.QUESTIONS ? (
        <QuestionsPage
          changePage={async (answer) => {
            if (env.isDisplayValidationPopupBeforeResultPage) {
              displayPolicyValidationPopup();
              setAnswers(answer);
            } else {
              setAnswers(answer);
              setDisplayPage(displaySection.RESULT);

              setSurveyKey(await save(answer, "", false, {}));
              document
                .getElementById("root")
                .scrollTo({ top: 0, left: 0, behavior: "smooth" });

            }
            // setAnswers(answer);

          }}
        />
      ) : displayPage === displaySection.RESULT ? (
        <ResultPage
          answers={answers}
          surveyKey={surveyKey}
          retakeTest={() => {
            setAnswers([]);
            setSurveyKey("");
            setDisplayPage(displaySection.QUESTIONS);
            if (process.env.REACT_APP_NEW_ETRIAGE_LANDING_WITH_POPUP != 'true') {
              window.history.pushState(null, "", `/${i18n.language}/eTriage`);
            } else {
              window.history.pushState(null, "", `/${i18n.language}`);
            }

          }}
          clientId={clientId}
        />
      ) : null}

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={entryDialogOpen}
        disableEscapeKeyDown={true}
      >
        <NewLandingPopup startWithConsent={startWithConsent} />
      </Dialog>

      <PopupContainer
        isOpen={selectLanguageDialogOpen}
        onClickClose={() => {
          setSelectLanguageDialogOpen(false);
          setEntryDialogOpen(true);
        }}>
        <div className={classes.selectLanguageContainer}>
          <SelectLanguagePopup
            onLanguageSelected={() => {
              setSelectLanguageDialogOpen(false);
              setEntryDialogOpen(true);
            }}
          />
        </div>
      </PopupContainer>

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={validationDialogOpen}
        disableEscapeKeyDown={true}
      >
        <div className={classes.policyValidationFormContainer}>
          <PolicyValidationForm
            onSubmitSuccess={onValidationSuccess}
            onClickSkipStep={onIsNonCustomer}
            isValidationBeforeResultPage
            isEmbedComponent
            title={t(`eTriageLanding.validation.${currentBu}.title`)}
            caption={t(`eTriageLanding.validation.${currentBu}.caption`)}
            remark={t(`eTriageLanding.validation.${currentBu}.remarks`)}
            skipButtonAlignLeft={currentBu === "vn"}
            idDocumentRemarks={currentBu === "vn"}
            displayConsentInValidation={env.isDisplayConsentInValidationForm} />
        </div>

      </Dialog>


    </>
  );
}

export default ETriagePageWithPopup;
