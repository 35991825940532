import { withStyles } from "@material-ui/core";
import React from "react";
import { COLORS } from "../../../constant/colors";
import ErrorSvg from "../../../assets/eTriage/landing/error.svg";

const ErrorMessage = ({ classes, text }) => (
  <div className={classes.errorMsgContainer}>
    <img src={ErrorSvg} className={classes.errorSvg} />
    <div className={classes.errorMsg}>
      {text}
    </div>
  </div>
);

const ErrorMessageStyled = withStyles({
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 6,
  },
  errorSvg: {
    width: 20,
    height: 20,
    marginRight: 6,
  },
  errorMsg: {
    color: COLORS.fwdAlertRed,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "20px",
  },
})(ErrorMessage);

export default ErrorMessageStyled;
