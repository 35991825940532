import { makeStyles } from "@material-ui/core";

import clsx from "clsx";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import warningSvg from "../../../assets/eTriage/questions/emergencyBanner/warning.svg";
import closeBannerSvg from "../../../assets/eTriage/questions/emergencyBanner/closeBanner_grey.svg";

import expandButtonSvg from "../../../assets/eTriage/questions/emergencyBanner/expandButton.svg";

import collapseButtonSvg from "../../../assets/eTriage/questions/emergencyBanner/collapseButton.svg";

import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  emergencyBannerContainer: {
    background: COLORS.fwdOrange20,
    display: "flex",
    flexDirection: "column",
    maxWidth: 856,
    width: "100%",
    paddingTop: 40,
    padding: 20,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& a": {
      color: COLORS.fwdDarkGreen,
    },
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  warningSvg: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  warningTitle: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
  },
  closeBannerIcon: {
    width: 24,
    height: 24,
    marginLeft: 10,
    cursor: "pointer",
  },
  warningCaption: {
    fontSize: 14,
    lineHeight: "21px",
    fontWeight: 450,
    marginBottom: 20,
    paddingRight: 20,
    color: COLORS.fwdDarkGreen,
    "& a": {
      fontWeight: 700
    }
  },
  expandedButtonRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  expandedButtonImgRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mobile: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
}));

function EmergencyBanner({ closeEmergencyBanner }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  return (
    <>
      <div className={classes.emergencyBannerContainer}>
        <div className={classes.topRow}>
          <div className={classes.row}>
            <img src={warningSvg} className={classes.warningSvg} />
            <div className={classes.warningTitle}>
              {ReactHtmlParser(
                t(`eTriageQuestionsPage.emergencyBanner.${currentBu}.title`)
              )}
            </div>
          </div>
          <div
            className={classes.closeBannerIcon}
            onClick={closeEmergencyBanner}
          >
            <img src={closeBannerSvg} />
          </div>
        </div>
        <div className={clsx([classes.warningCaption, classes.mobile])}>
          <div>
            {ReactHtmlParser(
              t(`eTriageQuestionsPage.emergencyBanner.${currentBu}.caption`)
            )}
          </div>
          <div>
            <Trans
              i18nKey={`eTriageQuestionsPage.emergencyBanner.${currentBu}.captionAskforHelp`}
            />
          </div>
        </div>

        <div className={classes.expandedButtonRow}>
          {isExpanded ? (
            <>
              <div className={classes.warningCaption}>
                <div>
                  {ReactHtmlParser(
                    t(
                      `eTriageQuestionsPage.emergencyBanner.${currentBu}.caption`
                    )
                  )}
                </div>
                <div>
                  <Trans
                    i18nKey={`eTriageQuestionsPage.emergencyBanner.${currentBu}.captionAskforHelp`}
                  />
                </div>
              </div>

              <div className={classes.expandedButtonImgRow}>
                <img
                  src={collapseButtonSvg}
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                ></img>
              </div>
            </>
          ) : (
            <div className={classes.expandedButtonImgRow}>
              <img
                src={expandButtonSvg}
                onClick={() => {
                  setIsExpanded(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EmergencyBanner;
