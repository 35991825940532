const fontSizes = {
  titleLarge: 25,
  titleMedium: 20,
  contentMedium: 16,
  contentSmall: 14,
};

export {
  fontSizes,
};
