export const headerJson = {

  "uid": "abaca3eb-6579-47ef-b88f-c9e713f1ec17",
  "componentName": "Header",
  "dataSource": "{8903F482-BBC8-4A1D-B453-4372D0362FE7}",
  "params": {
    "backgroundColor": "white-orange",
    "paddingTop": "None",
    "paddingBottom": "None"
  },
  "fields": {
    "iconNavigationLinks": [
      {
        "id": "81836702-d156-47bf-aaed-469966e67a67",
        "name": "Login",
        "displayName": "Login",
        "fields": {
          "enableDropdown": {
            "value": true
          },
          "buttonIcon": null,
          "title": {
            "value": "Login"
          },
          "link": {
            "value": {}
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        },
        "Children": [
          {
            "id": "a780b20c-d043-4ca4-85bd-b269db8e03fb",
            "name": "Customer",
            "displayName": "Customer",
            "fields": {
              "buttonIcon": {
                "id": "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/link",
                "fields": {
                  "value": {
                    "value": "arrow-right"
                  },
                  "key": {
                    "value": "Link"
                  }
                }
              },
              "title": {
                "value": "Customer"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/myPortal/ ",
                  "text": "Customer",
                  "linktype": "external",
                  "url": "https://www.fwd.com.my/myPortal/ ",
                  "anchor": "",
                  "title": "Customer",
                  "target": ""
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          },
          {
            "id": "cf4ad6f1-b0a5-4991-ba3d-a65f656137c1",
            "name": "Agent",
            "displayName": "Agent",
            "fields": {
              "buttonIcon": {
                "id": "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/link",
                "fields": {
                  "value": {
                    "value": "arrow-right"
                  },
                  "key": {
                    "value": "Link"
                  }
                }
              },
              "title": {
                "value": "Agent"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/fwdeiris/ ",
                  "text": "Agent",
                  "linktype": "external",
                  "url": "https://www.fwd.com.my/fwdeiris/ ",
                  "anchor": "",
                  "title": "Agent",
                  "target": ""
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          }
        ]
      },
      {
        "id": "f86a2012-d428-42c1-9b94-5c398a017b25",
        "name": "Call",
        "displayName": "Call",
        "fields": {
          "enableDropdown": {
            "value": true
          },
          "buttonIcon": {
            "id": "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/call",
            "fields": {
              "value": {
                "value": "call"
              },
              "key": {
                "value": "Call"
              }
            }
          },
          "title": {
            "value": ""
          },
          "link": {
            "value": {
              "href": "http://tel:1300 13 7988",
              "linktype": "external",
              "url": "http://tel:1300 13 7988",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "d39231cb-938a-4195-8747-a8f060976202",
        "name": "Search",
        "displayName": "Search",
        "fields": {
          "enableDropdown": {
            "value": true
          },
          "buttonIcon": {
            "id": "8e6f2179-2d7b-420d-9d47-d13c408bb3c5",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/search",
            "fields": {
              "value": {
                "value": "search"
              },
              "key": {
                "value": "Search"
              }
            }
          },
          "title": {
            "value": ""
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/search/",
              "text": "",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "",
              "querystring": "",
              "id": "{0C2BF8C6-DD1F-4D4E-A985-8F10AF3C2873}"
            }
          },
          "listType": {
            "id": "b91e87f2-99cc-4a93-a0ba-3ee8c14f36a4",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/search",
            "fields": {
              "value": {
                "value": "Search"
              },
              "key": {
                "value": "search"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "headerNavigationLinks": [
      {
        "id": "524bccb5-0257-4bc2-b789-d7cbafd46d5b",
        "name": "personal-insurance",
        "displayName": "Personal Insurance",
        "fields": {
          "buttonIcon": null,
          "title": {
            "value": "Personal Insurance"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/products/",
              "id": "{A47026B3-7337-419C-A5CE-15D85CF877EB}",
              "querystring": "",
              "target": "",
              "text": "",
              "anchor": "",
              "title": "",
              "class": "",
              "linktype": "internal"
            }
          },
          "pageType": {
            "id": "4fded742-177c-4850-9853-fa31cc47d63e",
            "fieldType": "Droptree",
            "fieldName": "pageType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/fwd-hk/components/pagetype/individual",
            "fields": {
              "value": {
                "value": "Individual"
              },
              "key": {
                "value": "individual"
              }
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        },
        "Children": [
          {
            "id": "28df52b4-d626-4789-9391-d90e8aaa4835",
            "name": "Products",
            "displayName": "Products",
            "fields": {
              "buttonIcon": {
                "id": "19fcb2e6-0897-424f-9d15-b35209e646ed",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/products",
                "fields": {
                  "value": {
                    "value": "products"
                  },
                  "key": {
                    "value": "products"
                  }
                }
              },
              "title": {
                "value": "Products"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/products/",
                  "linktype": "external",
                  "url": "https://www.fwd.com.my/products/",
                  "anchor": "",
                  "target": ""
                }
              },
              "pageType": null,
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          },
          {
            "id": "dcc3dea9-7d89-40c0-a516-bc8a3e35eb10",
            "name": "Claims",
            "displayName": "Claims",
            "fields": {
              "buttonIcon": {
                "id": "8ba0c144-7d03-426d-84a8-3329cca6784a",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/claims",
                "fields": {
                  "value": {
                    "value": "claims"
                  },
                  "key": {
                    "value": "claims"
                  }
                }
              },
              "title": {
                "value": "Claims"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/claims/",
                  "text": "",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "",
                  "target": "",
                  "querystring": "",
                  "id": "{176F0964-27C7-40B6-9DA5-54DA1C6CB59E}"
                }
              },
              "pageType": {
                "id": "4fded742-177c-4850-9853-fa31cc47d63e",
                "fieldType": "Droptree",
                "fieldName": "pageType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/fwd-hk/components/pagetype/individual",
                "fields": {
                  "value": {
                    "value": "Individual"
                  },
                  "key": {
                    "value": "individual"
                  }
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          },
          {
            "id": "a91417a7-7baa-4891-93da-478c31d2c3f3",
            "name": "Support",
            "displayName": "Support",
            "fields": {
              "buttonIcon": {
                "id": "79cc843a-2212-40b5-b09f-2018d04c72a9",
                "fieldType": "CustomDroplink",
                "fieldName": "buttonIcon",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/contact",
                "fields": {
                  "value": {
                    "value": "contact"
                  },
                  "key": {
                    "value": "Contact"
                  }
                }
              },
              "title": {
                "value": "Support"
              },
              "link": {
                "value": {
                  "href": "https://www.fwd.com.my/support/",
                  "text": "Support",
                  "anchor": "",
                  "linktype": "internal",
                  "class": "",
                  "title": "Support",
                  "querystring": "",
                  "id": "{7D817917-32F5-4221-9CD4-88CFE7E7C4C0}"
                }
              },
              "pageType": {
                "id": "4fded742-177c-4850-9853-fa31cc47d63e",
                "fieldType": "Droptree",
                "fieldName": "pageType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/fwd-hk/components/pagetype/individual",
                "fields": {
                  "value": {
                    "value": "Individual"
                  },
                  "key": {
                    "value": "individual"
                  }
                }
              },
              "listType": {
                "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
                "fieldType": "Droplink",
                "fieldName": "listType",
                "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
                "fields": {
                  "value": {
                    "value": "Generic Link"
                  },
                  "key": {
                    "value": "generic_link"
                  }
                }
              },
              "gaLabelName": {
                "value": ""
              },
              "gaCategoryName": {
                "value": ""
              }
            }
          }
        ]
      },
      {
        "id": "0f4f24e0-81ca-4fc3-aed5-fbe527d2cbdd",
        "name": "about-us",
        "displayName": "About Us",
        "fields": {
          "buttonIcon": {
            "id": "a12ce2e2-a53a-455b-8c36-2050fedba642",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/team",
            "fields": {
              "value": {
                "value": "team"
              },
              "key": {
                "value": "Team"
              }
            }
          },
          "title": {
            "value": "About us"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/about-us/",
              "id": "{E29AE3B4-0EF7-4A19-94D0-5A773DA078BF}",
              "querystring": "",
              "target": "",
              "text": "",
              "anchor": "",
              "title": "",
              "class": "",
              "linktype": "internal"
            }
          },
          "pageType": {
            "id": "fb2c4a8a-0593-44b7-b7c4-ae07af4f8bb2",
            "fieldType": "Droptree",
            "fieldName": "pageType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/fwd-th/components/pagetype/individual",
            "fields": {
              "value": {
                "value": "Individual"
              },
              "key": {
                "value": "individual"
              }
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "6bf9bac4-e923-4d2e-aa9c-60abfddf35b9",
        "name": "Elite studio",
        "displayName": "Elite studio",
        "fields": {
          "buttonIcon": {
            "id": "625786bf-68f1-459d-ab9f-ce3c883e7af2",
            "fieldType": "CustomDroplink",
            "fieldName": "buttonIcon",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linkvariant/career",
            "fields": {
              "value": {
                "value": "career"
              },
              "key": {
                "value": "career"
              }
            }
          },
          "title": {
            "value": "Find an agent"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/elite-studio/",
              "linktype": "external",
              "url": "https://www.fwd.com.my/elite-studio/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "pageType": {
            "id": "4aedd376-1a25-4196-a978-72eb7b93d5db",
            "fieldType": "Droptree",
            "fieldName": "pageType",
            "url": "https://www.fwd.com.my/components/pagetype/individual",
            "fields": {
              "value": {
                "value": "Individual"
              },
              "key": {
                "value": "individual"
              }
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "mobileLogo": {
      "value": {
        "src": "https://www.fwd.com.my/-/media/global/images/fwd-logo_colour.svg?rev=77f0e92219254f9caea8bbe5bba16478",
        "alt": "FWD logo svg",
        "x1": "https://www.fwd.com.my/-/media/global/images/fwd-logo_colour.svg?rev=77f0e92219254f9caea8bbe5bba16478&cx=0&cy=0&cw=160&ch=55&hash=3AF3D29B3D5E2EAFA3BE3C37BE360961",
        "x2": "https://www.fwd.com.my/-/media/global/images/fwd-logo_colour.svg?rev=77f0e92219254f9caea8bbe5bba16478&cx=0&cy=0&cw=320&ch=110&hash=D2514977A717BE6D04560201E1E90784"
      }
    },
    "logo": {
      "value": {}
    },
    "logoLink": {
      "value": {
        "href": "https://www.fwd.com.my/",
        "linktype": "external",
        "url": "https://www.fwd.com.my/",
        "anchor": "",
        "target": ""
      }
    },
    "headerLogo": {
      "value": {}
    },
    "countrySelector": [
      {
        "id": "fc1eb478-c5ad-47a7-8aea-864133ac63db",
        "name": "FWD Group",
        "displayName": "FWD Group",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "FWD Group"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com/",
              "text": "FWD Group",
              "linktype": "external",
              "url": "https://www.fwd.com/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "2a8c3247-1efb-4c5c-9188-46aff2bc4af2",
        "name": "Cambodia",
        "displayName": "Cambodia",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Cambodia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.kh/",
              "text": "Cambodia",
              "linktype": "external",
              "url": "https://www.fwd.com.kh/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "f281af72-fa4b-4f87-98f1-4b9d77180e60",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "6155fb51-0c83-4f83-81a0-d3cc4c6915c1",
        "name": "Hong Kong",
        "displayName": "Hong Kong",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Hong Kong"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.hk/en/",
              "text": "Hong Kong",
              "linktype": "external",
              "url": "https://www.fwd.com.hk/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "f281af72-fa4b-4f87-98f1-4b9d77180e60",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/linklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "b9a14c94-20c4-47c8-96da-d4ef066f66ec",
        "name": "Indonesia",
        "displayName": "Indonesia",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Indonesia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.co.id/",
              "text": "Indonesia",
              "linktype": "external",
              "url": "https://www.fwd.co.id/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "c6a85dde-9c2e-40a0-b81a-b772285b262f",
        "name": "Japan",
        "displayName": "Japan",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Japan"
          },
          "link": {
            "value": {
              "href": "http://www.fwdlife.co.jp",
              "linktype": "external",
              "url": "http://www.fwdlife.co.jp",
              "anchor": "",
              "target": ""
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "68fb5672-9cfe-4c6e-b047-8837286a4d16",
        "name": "Macau",
        "displayName": "Macau",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Macau"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.vn/en/",
              "text": "Macau",
              "linktype": "external",
              "url": "https://www.fwd.com.vn/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "6f4be9dc-4cfa-4ec3-bbe2-1f690c2342cc",
        "name": "Malaysia",
        "displayName": "Malaysia",
        "fields": {
          "isDefault": {
            "value": true
          },
          "buttonIcon": null,
          "title": {
            "value": "Malaysia"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.my/",
              "text": "",
              "anchor": "",
              "linktype": "internal",
              "class": "",
              "title": "",
              "querystring": "",
              "id": "{ED1C1C9C-BAFE-45EF-ABDD-C1BDE7D278BF}"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "2efe1c70-1706-48dd-a7b6-13185fde4ecc",
        "name": "Philipines",
        "displayName": "Philipines",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Philipines"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.ph/",
              "text": "Philipines",
              "linktype": "external",
              "url": "https://www.fwd.com.ph/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "875e163c-ffe8-4f80-be37-48221f095a82",
        "name": "Singapore",
        "displayName": "Singapore",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Singapore"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.sg/",
              "text": "Singapore",
              "linktype": "external",
              "url": "https://www.fwd.com.sg/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "28ff0713-bc7c-49b1-b59b-dc5bd28e3c3c",
        "name": "Thailand",
        "displayName": "Thailand",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Thailand"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.co.th/",
              "text": "Thailand",
              "linktype": "external",
              "url": "https://www.fwd.co.th/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      },
      {
        "id": "44505fa1-98a3-4bf8-bfc3-d14ac8b51a48",
        "name": "Vietnam",
        "displayName": "Vietnam",
        "fields": {
          "isDefault": {
            "value": false
          },
          "buttonIcon": null,
          "title": {
            "value": "Vietnam"
          },
          "link": {
            "value": {
              "href": "https://www.fwd.com.vn/en/",
              "text": "Vietnam",
              "linktype": "external",
              "url": "https://www.fwd.com.vn/en/",
              "anchor": "",
              "target": "_blank"
            }
          },
          "listType": {
            "id": "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            "fieldType": "Droplink",
            "fieldName": "listType",
            "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            "fields": {
              "value": {
                "value": "Generic Link"
              },
              "key": {
                "value": "generic_link"
              }
            }
          },
          "gaLabelName": {
            "value": ""
          },
          "gaCategoryName": {
            "value": ""
          }
        }
      }
    ],
    "displayMode": {
      "id": "2c61b2e1-e1c4-4622-b814-1e458cf094b6",
      "fieldType": "Droplink",
      "fieldName": "displayMode",
      "url": "https://www.fwd.com.my/sitecore/content/fwd/global/settings/list/carouselmode/both",
      "fields": {
        "value": {
          "value": "Both"
        },
        "key": {
          "value": "Both"
        }
      }
    },
    "searchPageLink": {
      "value": {
        "href": "https://www.fwd.com.my/search/",
        "linktype": "internal",
        "id": "{0C2BF8C6-DD1F-4D4E-A985-8F10AF3C2873}"
      }
    },
    "pastSearchRecordsCount": {
      "value": "8"
    },
    "title": {
      "value": "I'm looking for..."
    },
    "placeholderText": {
      "value": "Please enter a keyword or phrase"
    },
    "enableLanguageToggle": {
      "value": true
    },
    "languageList": [
      {
        "id": "440955d7-719b-4fe5-9d42-37fd2c4a9083",
        "fields": {
          "languageName": {
            "value": "BM"
          },
          "defaultLanguage": {
            "value": "En"
          },
          "languageCode": {
            "value": "bm"
          }
        }
      },
      {
        "id": "440955d7-719b-4fe5-9d42-37fd2c4a9083",
        "fields": {
          "languageName": {
            "value": "EN"
          },
          "defaultLanguage": {
            "value": "Bm"
          },
          "languageCode": {
            "value": "en"
          }
        }
      }
    ],
    "languageModalDescription": {
      "value": "We currently working to have this content in your language. You can choose to stay on this page, or proceed to explore other relevant content."
    },
    "languageModalLink": {
      "value": {
        "href": "https://www.fwd.com.my/",
        "linktype": "external",
        "url": "https://www.fwd.com.my/",
        "anchor": "",
        "target": ""
      }
    },
    "languageModalSecondaryLink": {
      "value": "Stay on page"
    },
    "languageModalTitle": {
      "value": "This content might not be in your language."
    }
  }
  ,
  sitecoreContext: {
    language: "BM",
    languageCode: "bm",
    availableLanguages: ["bm", "en"],
  },
};
