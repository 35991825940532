import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import { useNavigate } from "react-router-dom";
import * as nextgen from "@fwd-dep/nextgen-ui-lib";
import isEmpty from "lodash/isEmpty";
import axios from "axios";

import clsx from "clsx";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  customerSupportContainer: {
    backgroundColor: COLORS.white,
  },
}));

function CustomerSupportContainer({ }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  const [params, setParams] = useState();
  const [dictionary, setDictionary] = useState();

  useEffect(() => {
    try {
      const paramsFile = require(`../../../constant/customerSupportJson/customerSupportJson_${currentBu}_${i18n.language}`);

      setParams({
        ...paramsFile["customerSupportJson"],
        updateGroupEnquiryData: () => { },
        updateFormLinks: () => { },
        updateFormInitializer: () => { },
        updateFormModalState: () => { },
      });

    } catch (err) {
    }
    try {
      const dictionaryFile = require(`../../../constant/customerSupportJson/formsHocDictionaryJson_${currentBu}_${i18n.language}`);
      setDictionary(dictionaryFile["dictionary"]);
    } catch (err) {

    }



  }, [i18n.language]);


  const defaultAPIStatus = {
    loading: false,
    apiStatus: null,
    error: null,
    errorCode: null,
    failCounter: 0,
    message: "",
  };



  const [formModalState, setFormModalState] = React.useState(false);
  const [apiStatus, setApiStatus] = React.useState(defaultAPIStatus);

  const onSubmit = async (payload) => {
    console.log("payload", payload);
    setApiStatus({
      loading: true,
      apiStatus: null,
      error: false,
      errorCode: null,
      failCounter: 0,
      message: "",
    });
    try {
      const response = await axios({
        method: "POST",
        url: payload.apiUrl,
        data: payload.data,
        headers: {
          entity: payload.entity,
          Authorization: payload.authorizationKey,
          ...(payload.factorType
            ? { "factor-type": payload.factorType }
            : null),
        },
      });
      console.log("response", response);
      if (response.status === 200) {
        if (response.data !== "undefined") {
          if (!isEmpty(response.data.data)) {
            setApiStatus({
              loading: false,
              apiStatus: "success",
              error: false,
              errorCode: 200,
              failCounter: 0,
              message: "",
            });
          } else {
            setApiStatus({
              loading: false,
              apiStatus: "fail",
              error: true,
              errorCode: response.status,
              failCounter: 0,
              message: "-Request returned 0",
            });
          }
        } else {
          setApiStatus({
            loading: false,
            apiStatus: "fail",
            error: true,
            errorCode: response.status,
            failCounter: 0,
            message: "-Request failed",
          });
        }
      } else {
        setApiStatus({
          loading: false,
          apiStatus: "fail",
          error: true,
          errorCode: response.status,
          failCounter: 0,
          message: response.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      setApiStatus({
        loading: false,
        apiStatus: "fail",
        error: true,
        errorCode: 400,
        failCounter: 0,
        message: error.message,
      });
    }
  };

  return (
    <>
      <div className={classes.customerSupportContainer}>

        {
          params && (
            <nextgen.CustomerSupport
              {...params}
              navigate={(href) => {
                console.log("navigate", href);
                window.open(href);
              }}
              gtmPushEvent={(event) => console.log(event)}
              updateFormModalState={(v) => {
                setFormModalState(v.formModalState);
              }}
              handleChatBotToggle={(e) => console.log(e)}
              updateGroupEnquiryData={() => { }}
              updateFormLink={() => { }}
              updateFormInitializer={() => { }}
            />
          )
        }

        {
          dictionary && (
            <nextgen.FormsHOC
              formModalState={formModalState}
              updateFormModalState={setFormModalState}
              resetAPIStatus={() => setApiStatus(defaultAPIStatus)}
              submitAction={(payload) => onSubmit(payload)}
              {...params.callbackForm}
              apiStatus={apiStatus}
              dictionary={dictionary}
            />
          )
        }


      </div>
    </>
  );
}

export default CustomerSupportContainer;
