export const FrequencyOptions = [
  {
    icon: "notAtAll",
    label: "eTriageQuestionsPage.options.frequency_notAtAll",
    value: 0,
  },
  {
    icon: "severalDays",
    label: "eTriageQuestionsPage.options.frequency_severalDays",
    value: 1,
  },
  {
    icon: "moreThanHalfTheDays",
    label: "eTriageQuestionsPage.options.frequency_moreThanHalfTheDays",
    value: 2,
  },
  {
    icon: "nearlyEveryday",
    label: "eTriageQuestionsPage.options.frequency_nearlyEveryday",
    value: 3,
  },
];
