import Questions, { Category } from "../constant/etriage/questions";
import { depressionLevels } from "../SymptomsLevels/depresionLevels";
import { anxietyLevels } from "../SymptomsLevels/anxietyLevels";
import { impairmentLevels } from "../SymptomsLevels/impairmentLevels";
import { SystomsLevel } from "../SymptomsLevels/symptomsLevel";

export type Answer = {|
  question: number,
  answer: number,
|};

export type SingleScore = {|
  score: number,
  maxScore: number,
  level: SymptomsLevel,
  answers: Array<Answer>,
|};

export type Scores = Map<Category, SingleScore>;

export const calculateScores = (answers: Array<?number>): Scores => {
  //   return useMemo(() => {
  let scores: Scores = new Map([
    [
      "anxiety",
      {
        score: 0,
        maxScore: 0,
        level: anxietyLevels[0],
        answers: [],
      },
    ],
    [
      "depression",
      {
        score: 0,
        maxScore: 0,
        level: depressionLevels[0],
        answers: [],
      },
    ],
    [
      "impairment",
      {
        score: 0,
        maxScore: 0,
        level: impairmentLevels[0],
        answers: [],
      },
    ],
  ]);

  Questions.forEach((question, i) => {
    scores = increment(
      scores,
      question.category,
      i,
      answers[i] ?? 0,
      question.type === "frequency" ? 3 : 8
    );
  });

  //   populateLevel("depression", scores.get("depression"));
  //   populateLevel("anxiety", scores.get("anxiety"));
  //   populateLevel("impairment", scores.get("impairment"));

  return scores;
  //   }, [answers]);
};

const increment = (scores, category, question, answer, total) => {
  const score = scores.get(category);
  if (score) {
    score.score += answer;
    score.maxScore += total;
    score.answers.push({ question, answer });
    score.level = getLevel(category, score);
  }
  return scores.set(category, score);
};

const getLevel = (symptomsType: SymptomsType, score: ?SingleScore) => {
  if (!score) {
    return undefined;
  }
  if (symptomsType === "depression") {
    return depressionLevels.find((level: SystomsLevel) =>
      level.matches(score.score)
    );
  }
  if (symptomsType === "anxiety") {
    return anxietyLevels.find((level: SystomsLevel) =>
      level.matches(score.score)
    );
  }
  if (symptomsType === "impairment") {
    return impairmentLevels.find((level: SystomsLevel) =>
      level.matches(score.score)
    );
  }

  return undefined;
};

// const populateLevel = (symptomsType: SymptomsType, score: ?SingleScore) => {
//   if (!score) return;

//   score.level = getLevel(symptomsType, score);

//   return score;
// };

export type SymptomsType = "depression" | "anxiety" | "impairment";
