import "regenerator-runtime/runtime";
import { v4 as uuid } from "uuid";
import { API } from "aws-amplify";
import {
  createAnswer,
  createSurveyEmail,
  createSurveyRecord,
} from "../graphql/mutations";
import {
  listSurveyRecords,
  surveyRecordsForSurveyKey,
} from "../graphql/queries";


export const save = async (answers, clientId, isCustomer, policyDetail) => {
  let formatAnswer = answers.map((value, key) =>
    JSON.stringify({ question: key + 1, answer: value })
  );

  const id = uuid();
  const policyId = policyDetail.policy ?? null;
  const policyCode = policyDetail.productCode ?? null;
  const policyIssueDate = policyDetail.issueDate ?? null;


  await API.graphql({
    query: createSurveyRecord,
    variables: {
      input: {
        surveyKey: id,
        answers: formatAnswer,
        clientId: clientId,
        isCustomer: isCustomer,
        policy: policyId,
        productCode: policyCode,
        policyIssueDate: policyIssueDate

      },
    },
  });
  await Promise.all(
    answers.map((val, i) =>
      API.graphql({
        query: createAnswer,
        variables: {
          input: {
            surveyKey: id,
            question: i + 1,
            answer: val,
          },
        },
      })
    )
  );
  return id;
};

export const saveEmail = async (email, surveyKey) =>
  API.graphql({
    query: createSurveyEmail,
    variables: {
      input: {
        email,
        surveyKey,
      },
    },
  });

const doFetch = async (key) =>
  (
    await API.graphql({
      query: surveyRecordsForSurveyKey,
      variables: {
        surveyKey: key,
      },
    })
  ).data.surveyRecordsForSurveyKey;

export const getSurveyByClientId = async (clientId) => {
  let clientSurvey = await API.graphql({
    query: listSurveyRecords,
    variables: {
      filter: {
        clientId: {
          eq: clientId,
        },
      },
    },
  });
  clientSurvey = clientSurvey.data.listSurveyRecords.items;
  if (clientSurvey.length > 0) {
    clientSurvey.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    console.log(clientSurvey)
    return clientSurvey[0];
  } else {
    return null;
  }
};




export const getAllSurveyList = async (startDate, endDate) => {
  let clientSurvey = await API.graphql({
    query: listSurveyRecords,
    variables: {
      filter: {
        createdAt: {
          between: [startDate, endDate]
        },
      },
    },
  });
  clientSurvey = clientSurvey.data.listSurveyRecords.items;
  return clientSurvey.length;
};



export const load = async (key) => {
  let page = await doFetch(key);

  if (page.items.length === 0) {
    return { answers: null, clientId: null };
  }
  let answers = page.items[0].answers.map((answersItem) => {
    return JSON.parse(answersItem).answer;
  });
  let clientId = page.items[0].clientId;
  return { answers: answers, clientId: clientId };
};
