import q1Png from "../../../assets/eTriage/questions/newETriage/Q1.png";
import q2Png from "../../../assets/eTriage/questions/newETriage/Q2.png";
import q3Png from "../../../assets/eTriage/questions/newETriage/Q3.png";
import q4Png from "../../../assets/eTriage/questions/newETriage/Q4.png";
import q5Png from "../../../assets/eTriage/questions/newETriage/Q5.png";
import q6Png from "../../../assets/eTriage/questions/newETriage/Q6.png";
import q7Png from "../../../assets/eTriage/questions/newETriage/Q7.png";
import q8Png from "../../../assets/eTriage/questions/newETriage/Q8.png";
import q9Png from "../../../assets/eTriage/questions/newETriage/Q9.png";
import q10Png from "../../../assets/eTriage/questions/newETriage/Q10.png";
import q11Png from "../../../assets/eTriage/questions/newETriage/Q11.png";
import q12Png from "../../../assets/eTriage/questions/newETriage/Q12.png";
import q13Png from "../../../assets/eTriage/questions/newETriage/Q13.png";
import q14Png from "../../../assets/eTriage/questions/newETriage/Q14.png";
import q15Png from "../../../assets/eTriage/questions/newETriage/Q15.png";
import q16Png from "../../../assets/eTriage/questions/newETriage/Q16.png";
import q17Png from "../../../assets/eTriage/questions/newETriage/Q17.png";
import q18Png from "../../../assets/eTriage/questions/newETriage/Q18.png";
import q19Png from "../../../assets/eTriage/questions/newETriage/Q19.png";
import q20Png from "../../../assets/eTriage/questions/newETriage/Q20.png";
import q21Png from "../../../assets/eTriage/questions/newETriage/Q21.png";

const QuestionImage = ({ questionIndex }) => {
  // const path = require(`../../../assets/eTriage/questions/q${index}.png`);
  // return <img alt={`${index}`} src={ path}/>;
  // debugger;
  const index = questionIndex + 1;
  const pngToShow =
    index == 1
      ? q1Png
      : index == 2
        ? q2Png
        : index == 3
          ? q3Png
          : index == 4
            ? q4Png
            : index == 5
              ? q5Png
              : index == 6
                ? q6Png
                : index == 7
                  ? q7Png
                  : index == 8
                    ? q8Png
                    : index == 9
                      ? q9Png
                      : index == 10
                        ? q10Png
                        : index == 11
                          ? q11Png
                          : index == 12
                            ? q12Png
                            : index == 13
                              ? q13Png
                              : index == 14
                                ? q14Png
                                : index == 15
                                  ? q15Png
                                  : index == 16
                                    ? q16Png
                                    : index == 17
                                      ? q17Png
                                      : index == 18
                                        ? q18Png
                                        : index == 19
                                          ? q19Png
                                          : index == 20
                                            ? q20Png
                                            : q21Png;

  // return <img alt={`${index}`} src={pngToShow} style={{ width: "100%" }} />;
  return pngToShow;
};

export default QuestionImage;
