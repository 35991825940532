export const landingComponentJson = {
  BannerContainer: true,
  AssessmentServiceContainer: true,
  AssessmentServiceContainerLandingPageTnCButton: true,
  JustLikeDentistContainer: true,
  LearningCenterContainer: true,
  MindStrengthForFamilyContainer: true,
  CustomerSupportContainer: true,
  FooterContainer: true,
};
