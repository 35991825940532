const redeemFlowTypes = {
  policyValidationOnly: 'policyValidationOnly',
  policyValidationOrTrialWithLead: 'policyValidationOrTrialWithLead',
  policyValidationOrTrialWithoutLead: 'policyValidationOrTrialWithoutLead',
  trialWithLeadOnly: 'trialWithLeadOnly',
  directShow: 'directShow',
};

const leadFormType = {
  leadFormForTF: 'leadFormForTF',
  leadFormForDA: 'leadFormForDA'

}

export {
  redeemFlowTypes,
  leadFormType
};
