import { SymptomsLevel, SystomsLevelDto } from "./symptomsLevel";

const noAnxiety: SystomsLevelDto = {
  min: 0,
  max: 4,
  description: "eTriageResultPage.description.anxitey.noAnxiety",
  isEmergency: false,
  levelScore: 0,
  level: "no",
};
const mildAnxiety: SystomsLevelDto = {
  min: 5,
  max: 9,
  description: "eTriageResultPage.description.anxitey.mildAnxiety",
  isEmergency: false,
  levelScore: 1,
  level: "mild",
};
const moderateAnxiety: SystomsLevelDto = {
  min: 10,
  max: 14,
  description: "eTriageResultPage.description.anxitey.moderateAnxiety",
  isEmergency: false,
  levelScore: 2,
  level: "moderate",
};
const severeAnxiety: SystomsLevelDto = {
  min: 15,
  description: "eTriageResultPage.description.anxitey.severeAnxiety",
  isEmergency: true,
  levelScore: 3,
  level: "severe",
};

export const anxietyLevels: SymptomsLevel[] = [
  new SymptomsLevel(noAnxiety),
  new SymptomsLevel(mildAnxiety),
  new SymptomsLevel(moderateAnxiety),
  new SymptomsLevel(severeAnxiety),
];
