import { makeStyles } from "@material-ui/core";

import React, { useEffect } from "react";

import JustLikeDentistContainer from "./components/JustLikeDentistContainer";
import AssessmentServiceContainer from "./components/AssessmentServiceContainer";

import BannerContainer from "./components/BannerContainer";
import CustomerSupportContainer from "./components/CustomerSupportContainer";
import FooterContainer from "./components/FooterContainer";
import LearningCenterContainer from "./components/LearningCenterContainer";
import MindStrengthForFamilyContainer from "./components/MindStrengthForFamilyContainer";
import i18next from "i18next";
import HashLinkObserver from "react-hash-link";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

function Landing() {
  const classes = useStyles();

  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const paramsFile = require(`../../constant/landingJson/landingComponentJson_${currentBu}`);
  const { t, i18n } = useTranslation();
  const params = paramsFile["landingComponentJson"];
  useEffect(() => {
    if (params.lang && process.env.REACT_APP_LANG.split(",").indexOf(params.lang) >= 0) {
      i18next.changeLanguage(params.lang);
      document.title =
        t(`tabTitle.programme.${currentBu}`) +
        " | " +
        t(`tabTitle.${currentBu}`);
    }
    console.log(params);
    console.log(`process.env.REACT_APP_BU ${process.env.REACT_APP_BU}`);
    console.log(`process.env.env ${process.env.REACT_APP_ENV}`);
  }, []);

  return (
    <>
      <div component="section" className={classes.content}>
        <HashLinkObserver />
        {params.BannerContainer && <BannerContainer />}
        {params.AssessmentServiceContainer && <AssessmentServiceContainer />}
        {params.JustLikeDentistContainer && <JustLikeDentistContainer />}
        {params.LearningCenterContainer && <LearningCenterContainer />}
        {/* {params.MindStrengthForFamilyContainer && (
          <MindStrengthForFamilyContainer />
        )} */}
        {params.CustomerSupportContainer && <CustomerSupportContainer />}
        {params.FooterContainer && <FooterContainer />}
      </div>
    </>
  );
}

export default Landing;
