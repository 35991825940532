const DotSvgComponent = ({ fill }) => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="8" height="8" rx="4" fill={fill} />
    </svg>
  );
};

export default DotSvgComponent;
