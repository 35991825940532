export const headerJson = {
  fields: {
    iconNavigationLinks: [
      {
        id: "386f4e5d-7e07-4bb4-a649-4aea9ff4f806",
        name: "Login",
        displayName: "Login",
        fields: {
          enableDropdown: { value: false },
          buttonIcon: {
            id: "a12ce2e2-a53a-455b-8c36-2050fedba642",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/team",
            fields: { value: { value: "team" }, key: { value: "Team" } },
          },
          title: { value: "Login" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/login/",
              linktype: "external",
              url: "https://www.fwd.co.id/en/login/",
              anchor: "",
              target: "",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "Login" },
          gaCategoryName: { value: "" },
        }

      },
      {
        id: "d4cd8abe-7723-4e70-9d3b-65390303e779",
        name: "Call",
        displayName: "Call",
        fields: {
          enableDropdown: { value: true },
          buttonIcon: {
            id: "0853a008-9b3c-4a10-98b9-1e96a3cdc253",
            fieldType: "CustomDroplink",
            fieldName: "callIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/call",
            fields: { value: { value: "call" }, key: { value: "Call" } },
          },
          link: {
            value: {
              href: "http://tel:1500250",
              linktype: "external",
              url: "tel:1500250",
              anchor: "",
              target: "",
            },
          },
          listType: {
            id: "fd670504-d972-4fee-9582-656b0d6ba342",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/call",
            fields: { value: { value: "Call" }, key: { value: "call" } },
          },
          gaLabelName: { value: "Call" },
          gaCategoryName: { value: "" },
        },

      },
      {
        id: "2ecea1f2-4d14-4b34-8ed5-d8ed889a6730",
        name: "Search",
        displayName: "Search",
        fields: {
          enableDropdown: { value: false },
          buttonIcon: {
            id: "8e6f2179-2d7b-420d-9d47-d13c408bb3c5",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            fields: { value: { value: "search" }, key: { value: "Search" } },
          },
          title: { value: "" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/search/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{AA2127DC-2C63-4FDD-B43D-2583EC5200A4}",
            },
          },
          listType: {
            id: "b91e87f2-99cc-4a93-a0ba-3ee8c14f36a4",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/search",
            fields: { value: { value: "Search" }, key: { value: "search" } },
          },
          gaLabelName: { value: "Search" },
          gaCategoryName: { value: "" },
        },
      },
    ],
    headerNavigationLinks: [
      {
        id: "03f7d1bb-e0de-498f-bec2-918e48c3be3d",
        name: "personal-insurance",
        displayName: "Personal Insurance",
        fields: {
          buttonIcon: null,
          title: { value: "Personal insurance" },
          link: {
            value: {
              href: "https://www.fwd.com.hk/en/products/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              target: "",
              querystring: "",
              id: "{DB3FF0BC-2E10-4735-8BAA-76A87A906821}",
            },
          },
          pageType: {
            id: "4fded742-177c-4850-9853-fa31cc47d63e",
            fieldType: "Droptree",
            fieldName: "pageType",
            url: "https://www.fwd.com.hk/en/components/pagetype/individual",
            fields: {
              value: { value: "Individual" },
              key: { value: "individual" },
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "Personal insurance" },
          gaCategoryName: { value: "" },
        },
        Children: [
          {
            id: "7e9ea3f0-9071-4379-a25c-f1ac576e3463",
            name: "Products",
            displayName: "Products",
            fields: {
              buttonIcon: {
                id: "19fcb2e6-0897-424f-9d15-b35209e646ed",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/products",
                fields: {
                  value: { value: "products" },
                  key: { value: "products" },
                },
              },
              title: { value: "Products" },
              link: {
                value: {
                  href: "https://www.fwd.co.id/en/products/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  target: "",
                  querystring: "",
                  id: "{DB3FF0BC-2E10-4735-8BAA-76A87A906821}",
                },
              },
              gaLabelName: { value: "Products" },
              gaCategoryName: { value: "" },
            },
          },
          {
            id: "263a64a1-ba2e-48cb-b459-882d4d37430f",
            name: "Claims",
            displayName: "Claims",
            fields: {
              buttonIcon: {
                id: "19fcb2e6-0897-424f-9d15-b35209e646ed",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/products",
                fields: {
                  value: { value: "claims" },
                  key: { value: "claims" },
                },
              },
              title: { value: "Claims" },
              link: {
                value: {
                  href: "https://www.fwd.co.id/en/claims/",
                  linktype: "external",
                  url: "https://www.fwd.co.id/en/claims/",
                  anchor: "",
                  target: "_blank",
                },
              },

              gaLabelName: { value: "" },
              gaCategoryName: { value: "" },
            },
          },
          {
            id: "ce7d4b83-4bcc-4064-ab5d-6407be87d748",
            name: "Support",
            displayName: "Support",
            fields: {
              buttonIcon: {
                id: "fbdc2dbb-311b-47d3-929d-214d7b812ba5",
                fieldType: "CustomDroplink",
                fieldName: "buttonIcon",
                url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/supportoutline---obsolete",
                fields: {
                  value: { value: "live-chat" },
                  key: { value: "liveChat" },
                },
              },
              title: { value: "Support" },
              link: {
                value: {
                  href: "https://www.fwd.co.id/en/support/",
                  text: "",
                  anchor: "",
                  linktype: "internal",
                  class: "",
                  title: "",
                  querystring: "",
                  id: "{9C6D9E13-7967-40EA-AAAA-753CA0685D6C}",
                },
              },

              gaLabelName: { value: "Support" },
              gaCategoryName: { value: "" },
            },
          }
        ],
      },
      {
        id: "43605822-a526-4658-a490-e8307981a501",
        name: "employee-benefits",
        displayName: "Employee Benefits",
        fields: {
          buttonIcon: {
            id: "368ba79d-8cae-435c-a781-fe6fb2f3725d",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/collection---obsolete",
            fields: {
              value: { value: "collection" },
              key: { value: "Collection" },
            },
          },
          title: { value: "Employee Benefits" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/employee-benefits/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{E5F9A3FD-CB1D-4BE3-BC8B-C33AB0CA8199}",
            },
          },

          gaLabelName: { value: "Employee Benefits" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "43605822-a526-4658-a490-e8307981a502",
        name: "fwd-max",
        displayName: "FWD MAX",
        fields: {
          buttonIcon: {
            id: "368ba79d-8cae-435c-a781-fe6fb2f3725d",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/collection---obsolete",
            fields: {
              value: { value: "collection" },
              key: { value: "Collection" },
            },
          },
          title: { value: "FWD MAX" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/fwdmax/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{E5F9A3FD-CB1D-4BE3-BC8B-C33AB0CA8199}",
            },
          },

          gaLabelName: { value: "FWD MAX" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "43605822-a526-4658-a490-e8307981a503",
        name: "fwd-care",
        displayName: "FWD Care",
        fields: {
          buttonIcon: {
            id: "368ba79d-8cae-435c-a781-fe6fb2f3725d",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/collection---obsolete",
            fields: {
              value: { value: "collection" },
              key: { value: "Collection" },
            },
          },
          title: { value: "FWD Care" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/fwdcare/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{E5F9A3FD-CB1D-4BE3-BC8B-C33AB0CA8199}",
            },
          },

          gaLabelName: { value: "FWD Care" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "43605822-a526-4658-a490-e8307981a5b7",
        name: "about-us",
        displayName: "About Us",
        fields: {
          buttonIcon: {
            id: "368ba79d-8cae-435c-a781-fe6fb2f3725d",
            fieldType: "CustomDroplink",
            fieldName: "buttonIcon",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/linkvariant/collection---obsolete",
            fields: {
              value: { value: "collection" },
              key: { value: "Collection" },
            },
          },
          title: { value: "About us" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/about-us/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{E5F9A3FD-CB1D-4BE3-BC8B-C33AB0CA8199}",
            },
          },

          gaLabelName: { value: "About us" },
          gaCategoryName: { value: "" },
        },
      }

    ],
    mobileLogo: {
      value: {
        src: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-mobile-optimized.gif?rev=a8b502d8e15f4138b3886f4f4055d8e1",
        alt: "Mobile Logo",
        x1: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-mobile-optimized.gif?rev=a8b502d8e15f4138b3886f4f4055d8e1&cx=0&cy=0&cw=160&ch=55&hash=7022D21F9F18BB0976118AB5D2581224",
        x2: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-mobile-optimized.gif?rev=a8b502d8e15f4138b3886f4f4055d8e1&cx=0&cy=0&cw=320&ch=110&hash=7AA10FB71EF1FBCEA89E0E4CEC328D3A",
      },
    },
    logo: {
      value: {
        src: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-desktop_optimized.gif?rev=65cf41896c74464b91195aef5f75ab38",
        alt: "Desktop Logo",
        x1: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-desktop_optimized.gif?rev=65cf41896c74464b91195aef5f75ab38&cx=0.34&cy=0.49&cw=420&ch=145&hash=BA06E53B6851E960624E0E9D65530568",
        x2: "https://www.fwd.com.hk/en/-/media/images/fwd_logo-desktop_optimized.gif?rev=65cf41896c74464b91195aef5f75ab38&cx=0.34&cy=0.49&cw=840&ch=290&hash=6B51AE82CBDA193DA8CCB3D5FABFC3DB",
      },
    },
    logoLink: {
      value: {
        href: "https://www.fwd.co.id/en/",
        text: "Home",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "Home",
        querystring: "",
        id: "{9CB72689-FA18-4900-B876-3AE397D3B009}",
      },
    },
    headerLogo: {
      value: {
        src: "https://www.fwd.com.vn/images/v3/assets/bltca0b25cb8321936d/bltc2f517717caadfd8/641bc4f72fa7357de06f1cd3/10x_logo_420x145.webp",
        alt: "FWD Desktop Logo",
      },
    },
    countrySelector: [
      {
        id: "b43fd69b-bd63-4f64-9aab-06f1b0d527c7",
        name: "Group",
        displayName: "Group",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "FWD Group" },
          link: {
            value: {
              href: "https://www.fwd.com/",
              linktype: "external",
              url: "https://www.fwd.com/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "eb9ee5ea-e079-4bde-af3c-b82e5ebee28a",
        name: "Hong Kong",
        displayName: "Hong Kong",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Hong Kong" },
          link: {
            value: {
              href: "https://www.fwd.com.hk/en/",
              text: "",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{9CB72689-FA18-4900-B876-3AE397D3B009}",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "dc6f0a0c-f056-44c6-a565-2044c8b494a6",
        name: "Macau",
        displayName: "Macau",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Macau" },
          link: {
            value: {
              href: "https://www.fwd.com.mo/en/",
              linktype: "external",
              url: "https://www.fwd.com.mo/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "62bb0970-5392-494c-8969-53863b8728d3",
        name: "China representative office",
        displayName: "China representative office",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "China representative office" },
          link: {
            value: {
              href: "https://www.fwd.cn/",
              linktype: "external",
              url: "https://www.fwd.cn/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "79ec13ea-0312-44f7-b305-3660acd71adf",
        name: "Indonesia",
        displayName: "Indonesia",
        fields: {
          isDefault: { value: true },
          buttonIcon: null,
          title: { value: "Indonesia" },
          link: {
            value: {
              href: "https://www.fwd.co.id/en/",
              linktype: "external",
              url: "https://www.fwd.co.id/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "e5b1b516-9ca4-4a59-9417-03cdedeaab5a",
        name: "Japan",
        displayName: "Japan",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Japan" },
          link: {
            value: {
              href: "https://www.fwdlife.co.jp/",
              linktype: "external",
              url: "https://www.fwdlife.co.jp/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "d3f70c56-ff92-468e-a8e7-bdede76909d1",
        name: "Malaysia",
        displayName: "Malaysia",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Malaysia" },
          link: {
            value: {
              href: "https://www.fwd.com.my/en/",
              linktype: "external",
              url: "https://www.fwd.com.my/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "0bff6386-fa8a-4ba5-800f-24c2afffbbe0",
        name: "Philippines",
        displayName: "Philippines",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Philippines" },
          link: {
            value: {
              href: "https://www.fwd.com.ph/en/",
              linktype: "external",
              url: "https://www.fwd.com.ph/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "7bb44e4e-75eb-4b9d-8ff8-4a4a8203cf36",
        name: "Singapore",
        displayName: "Singapore",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Singapore" },
          link: {
            value: {
              href: "https://www.fwd.com.sg/",
              linktype: "external",
              url: "https://www.fwd.com.sg/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "dc572c84-d525-48c9-a5e0-6215970d93b4",
        name: "Thailand",
        displayName: "Thailand",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Thailand" },
          link: {
            value: {
              href: "https://www.fwd.co.th/en/",
              linktype: "external",
              url: "https://www.fwd.co.th/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
      {
        id: "4499f614-f9e5-4cd8-a924-8a8ce0c82348",
        name: "Vietnam",
        displayName: "Vietnam",
        fields: {
          isDefault: { value: false },
          buttonIcon: null,
          title: { value: "Vietnam" },
          link: {
            value: {
              href: "https://www.fwd.com.vn/en/",
              linktype: "external",
              url: "https://www.fwd.com.vn/en/",
              anchor: "",
              target: "_blank",
            },
          },
          listType: {
            id: "319b533c-ce50-4dfd-97b3-19b7b917cb0e",
            fieldType: "Droplink",
            fieldName: "listType",
            url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/navigationlinklisttype/generic_link",
            fields: {
              value: { value: "Generic Link" },
              key: { value: "generic_link" },
            },
          },
          gaLabelName: { value: "" },
          gaCategoryName: { value: "" },
        },
      },
    ],
    displayMode: {
      id: "c81d8d8f-e8d9-468f-acfc-bbdce30d66ec",
      fieldType: "Droplink",
      fieldName: "displayMode",
      url: "https://www.fwd.com.hk/en/sitecore/content/fwd/global/settings/list/carouselmode/mobile",
      fields: { value: { value: "Mobile" }, key: { value: "Mobile" } },
    },

    pastSearchRecordsCount: { value: "8" },
    title: { value: "I'm looking for..." },
    placeholderText: { value: "Please enter a keyword or phrase" },
    enableLanguageToggle: { value: true },
    languageList: [
      {
        id: "f7d73a71-1c14-4b6a-8db1-263b40425b4f",
        fields: {
          languageName: { value: "EN" },
          defaultLanguage: { value: "id" },
          languageCode: { value: "en" },
        },
      },
      {
        id: "8e721d84-8219-4d9b-9470-4621a8beff37",
        fields: {
          languageName: { value: "ID" },
          defaultLanguage: { value: "En" },
          languageCode: { value: "id" },
        },
      },
    ],
    languageModalDescription: {
      value:
        "We currently working to have this content in your language. You can choose to stay on this page, or proceed to explore other relevant content.",
    },
    languageModalLink: {
      value: {
        href: "/en/",
        text: "Explore",
        anchor: "",
        linktype: "internal",
        class: "",
        title: "Okie",
        target: "",
        querystring: "",
        id: "{9CB72689-FA18-4900-B876-3AE397D3B009}",
      },
    },
    languageModalSecondaryLink: { value: "Stay on page" },
    languageModalTitle: {
      value: "This content might not be in your language.",
    },
  },

  params: {
    backgroundColor: "white-orange",
    paddingTop: "None",
    paddingBottom: "None",
  },
  sitecoreContext: {
    language: "EN",
    languageCode: "en",
    availableLanguages: ["id", "en"],
  },
};
