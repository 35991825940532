import React from "react";
import clsx from "clsx";

import { withStyles } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";
import { Button as _Button } from "@material-ui/core";
import { ReactComponent as Loader } from "../../../assets/general/spinner.svg";

const buttonTypes = {
  filled: 'filled',
  filledLight: 'filledLight',
  outlined: 'outlined',
};

const buttonRadiusTypes = {
  round: 'round',
  rounder: 'rounder',
};

const Button = ({ classes, title, type = buttonTypes.filled, radiusType = buttonRadiusTypes.round, isDisabled, onClick, iconLeft, otherButtonProps = {}, isLoading = false }) => (
  <_Button
    disabled={isDisabled}
    className={clsx(
      classes.buttonBase,
      type === buttonTypes.filledLight && classes.buttonFilledLight,
      type === buttonTypes.outlined && classes.buttonOutlined,
      radiusType === buttonRadiusTypes.rounder && classes.buttonRounder,
    )}
    onClick={onClick}
    disableRipple={true}
    {...otherButtonProps}
  >
    {
      !!iconLeft &&
      <div className={classes.iconLeft}>
        {iconLeft}
      </div>
    }
    <div className={classes.buttonText}>{!isLoading ? title : <Loader className={classes.spinner}></Loader>}</div>
  </_Button>
);

const styles = {
  buttonBase: {
    background: COLORS.fwdOrange,
    color: COLORS.white,
    padding: 12,
    width: "100%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: COLORS.fwdOrange,
    },
    "&:disabled": {
      background: COLORS.fwdOrange50,
      color: COLORS.white,
    },
    "&.MuiButtonBase-root": {
      textTransform: 'unset', // Disable auto-capitalize
    },
  },
  buttonFilledLight: {
    color: COLORS.fwdOrange,
    background: COLORS.fwdOrangeLighter20,
    "&:hover": {
      background: COLORS.fwdOrangeLighter20,
    },
    "&:disabled": {
      background: COLORS.fwdOrangeLighter20,
      color: COLORS.fwdOrange50,
    },
  },
  buttonOutlined: {
    color: COLORS.fwdOrange,
    background: COLORS.white,
    border: `2px solid ${COLORS.fwdOrange}`,
    "&:hover": {
      background: COLORS.white,
    },
    "&:disabled": {
      background: COLORS.white,
      color: COLORS.fwdOrange50,
      border: `2px solid ${COLORS.fwdOrange50}`,
    },
  },
  buttonRounder: {
    borderRadius: 8,
  },
  iconLeft: {
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
  },
  spinner: {
    animationName: '$spin',
    animationDuration: '1000ms',
    animiationIterationCount: 'infinite',
    animationTimingFunction: 'linear'

  }, "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  }
};

export {
  buttonTypes,
  buttonRadiusTypes,
};

export default withStyles(styles)(Button);
