import React from "react";
import { withStyles } from "@material-ui/core";

const HeaderSpacer = ({ classes }) => (
  <div className={classes.headerbanner}></div>
);

const styles = {
  headerbanner: {
    height: 75,
    width: "100%",
  },
};

export default withStyles(styles)(HeaderSpacer);
