import env from "../env";

export const saveLead = async ({ firstName, lastName, email, countryCode, cellPhone, language }) => {

  const requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");
  requestHeaders.append("Connection", "keep-alive");


  const envLanguageVariableForLeadAPI = JSON.parse(process.env.REACT_APP_LEAD_FORM_API_LANGUAGE_LOCAL);

  const languageValue = envLanguageVariableForLeadAPI[language]['language'] ?? "en";
  const localeValue = envLanguageVariableForLeadAPI[language]['locale'] ?? "en-us";
  const commentValue = envLanguageVariableForLeadAPI[language]['comment'] ?? "Mind Strength";

  const commonParams = {
    queryReason: commentValue,
    salutation: "",
    firstName,
    lastName,
    email,
    countryCode,
    cellPhone,
    comment: commentValue,
  };


  const body = JSON.stringify({
    user: {
      profile: {
        contact: {
          email: {
            parameters: {
              ...commonParams,
              existingCustomer: "No",
              dTCCampaignId: "",
              requireULLicense: "",
              expectedInsuranceType: "",
              ...(env.salesforceNameCode && { Campaign_Name__c: env.salesforceNameCode }),
              ...(env.salesforceCampaignCode && { Campaign_Code__c: env.salesforceCampaignCode }),
            },
          },
        },
      },
      lead: {
        ...commonParams,
        dTCCampaignId: "",
        partnerName: "NexGen",
        source: "4",
        requireULLicense: "",
        expectedInsuranceType: "",
        ...(env.salesforceNameCode && { Campaign_Name__c: env.salesforceNameCode }),
        ...(env.salesforceCampaignCode && { Campaign_Code__c: env.salesforceCampaignCode }),
      },
    },
    "isMindStrength": true,
    language: languageValue,
    locale: localeValue,
    country: process.env.REACT_APP_LEAD_FORM_API_COUNTRY,
    emailTemplates: JSON.parse(process.env.REACT_APP_LEAD_FORM_API_EMAIL_TEMPLATE_STRING),
    services: process.env.REACT_APP_LEAD_FORM_API_EMAIL_SERVICE_STRING.split(","),
  });

  const requestOptions = {
    method: "POST",
    headers: requestHeaders,
    body: body
  };

  const response = await fetch(
    process.env.REACT_APP_LEAD_FORM_API_URL,
    requestOptions
  )

  if (response.ok) {
    return await response.json();
  }

  throw new Error(response.status);
};
