import { makeStyles } from "@material-ui/core";
import Questions from "../../../constant/etriage/questions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import QuestionImage from "./QuestionImage";
import DesktopFrequencySelectGroups from "./DesktopFrequencySelectGroups";
import DesktopAmplitudeSelectGroups from "./DesktopAmplitudeSelectGroups";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  questionBox: {
    padding: "15px 0px",
    // paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyItems: "flex-start",
    minHeight: 180,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    flexGrow: 1,
  },
  shortText: {
    color: COLORS.fwdDarkGreen,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
    zIndex: 20,
  },
  mainText: {
    color: COLORS.fwdDarkGreen,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    zIndex: 20,
    "& span": {
      whiteSpace: "nowrap"
    }
  },
  questionImage: {
    width: "100%",
  },
  questionButtonSection: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
  },
  questionButtonSectionForAmplitude: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    alignItems: "center",
  },
  optionSection: {
    // flex: 1,
    width: 329,
  },
  questionImageSection: {
    flex: 1,
    flexGrow: 1,
    padding: 20,
  },
  amplitudeQuestionImageSection: {
    width: "auto",
    maxHeight: 320,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 260,
    marginTop: -80,
    "& img": {
      height: "100%",
    },
  },
  amplitudeOptionSection: {
    // width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
  hint: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    marginTop: 16,
    zIndex: 20,
  },
}));

const DesktopQuestionsBannerWithOptions = ({
  questionIndex,
  questionsDetail,
  onAnswerSelect,
  selectedAnswer,
  nextPage,
  previousPage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.questionBox}>
      <div className={classes.shortText}>
        {/* {Questions[questionIndex].shortText} */}
        {t(`eTriageQuestionsPage.questions.q${questionIndex + 1}.shortText`)}
      </div>
      <div className={classes.mainText}>
        {/* {Questions[questionIndex].text} */}
        {ReactHtmlParser(
          t(`eTriageQuestionsPage.questions.q${questionIndex + 1}.text`)
        )}
      </div>
      <div className={classes.hint}>
        {t(`eTriageQuestionsPage.pleaseSelectOneAnswer`)}
      </div>

      {questionsDetail.type == "frequency" ? (
        <div className={classes.questionButtonSection}>
          <div className={classes.optionSection}>
            <DesktopFrequencySelectGroups
              key={questionIndex}
              selectedAnswer={selectedAnswer[questionIndex]}
              onAnswerSelect={(answerIndex) => {
                onAnswerSelect(answerIndex);
              }}
            />
          </div>
          <div className={classes.questionImageSection}>
            <img
              className={classes.questionImage}
              src={QuestionImage({
                questionIndex: questionIndex,
              })}
            />
          </div>
        </div>
      ) : (
        <div className={classes.questionButtonSectionForAmplitude}>
          {" "}
          <div className={classes.amplitudeQuestionImageSection}>
            <img
              src={QuestionImage({
                questionIndex: questionIndex,
              })}
            />
          </div>
          <div className={classes.amplitudeOptionSection}>
            <DesktopAmplitudeSelectGroups
              key={questionIndex}
              questionsDetail={questionsDetail}
              selectedAnswer={selectedAnswer[questionIndex]}
              onAnswerSelect={(answerIndex) => {
                onAnswerSelect(answerIndex);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopQuestionsBannerWithOptions;
