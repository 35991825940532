import logo from "./logo.svg";
import "./App.css";

import "./i18n.js";
import { useTranslation } from "react-i18next";
import Landing from "./view/landing/Landing";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Survey from "./view/Survey";
import { Fragment } from "react";
import NavigationFrame from "./view/components/NavigationFrame";

import Page from "./view/components/Page";
import Admin from "./view/Admin";
import ETriagePageWithPopup from "./view/eTriageWithPopup/ETriagePageWithPopup";
import RedeemFullPage from "./view/eTriageWithPopup/Result/RedeemFullPage";
import _TempTestPopupPage from './view/eTriageWithPopup/_TempTestPopupPage';
import _TempTestRedeemPage from './view/eTriageWithPopup/_TempTestRedeemPage';

function App() {
  return (
    <Router>
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Fragment>
        <NavigationFrame>
          <Routes>
            {process.env.REACT_APP_NEW_ETRIAGE_LANDING_WITH_POPUP == 'true' && (
              <>
                <Route exact path="/survey" element={<Survey />} />
                {/* {process.env.REACT_APP_ENV != 'prod' && <Route exact path="/admin" element={<Admin />} />} */}
                <Route exact path="/redeem/:purpose" element={<RedeemFullPage />} />
                <Route
                  path={`/result/:surveyId`}
                  element={<Page child={<ETriagePageWithPopup />} />}
                />
                <Route
                  path={`/eTriage/ref/:referralKey`}
                  element={<Page child={<ETriagePageWithPopup />} />}
                />
                <Route
                  exact
                  path="/:lang/result/:surveyId"
                  element={<Page child={<ETriagePageWithPopup />} />}
                />
                <Route path="/:lang/redeem/:purpose" element={<RedeemFullPage />} />
                <Route path="/:lang/" element={<Page child={<ETriagePageWithPopup />} />} />
                <Route path="/:lang" element={<Page child={<ETriagePageWithPopup />} />} />
                <Route path="*" element={<Page child={<ETriagePageWithPopup />} />} />
              </>
            )}

            {process.env.REACT_APP_NEW_ETRIAGE_LANDING_WITH_POPUP != 'true' &&
              (
                <>
                  <Route exact path="/survey" element={<Survey />} />
                  {/* {process.env.REACT_APP_ENV != 'prod' && <Route exact path="/admin" element={<Admin />} />} */}


                  <Route
                    path={`/eTriage/result/:surveyId`}
                    element={<Page child={<ETriagePageWithPopup />} />}
                  />

                  <Route
                    path={`/eTriage/ref/:referralKey`}
                    element={<Page child={<ETriagePageWithPopup />} />}
                  />
                  {/* <Route
                    path="/eTriage/:path"
                    element={<Page child={<ETriagePageWithPopup />} />}
                  /> */}

                  <Route
                    exact
                    path="/:lang/eTriage/result/:surveyId"
                    element={<Page child={<ETriagePageWithPopup />} />}
                  />
                  <Route path="/:lang/eTriage" element={<Page child={<ETriagePageWithPopup />} />} />
                  <Route
                    exact
                    path="/eTriage"
                    element={<Page child={<ETriagePageWithPopup />} />}
                  />
                  <Route path="/video/:videoKey" element={<Page child={<Landing />} />} />
                  <Route path="/:lang/" element={<Page child={<Landing />} />} />
                  <Route path="/:lang" element={<Page child={<Landing />} />} />
                  <Route path="/" element={<Page child={<Landing />} />} />
                  <Route path="*" element={<Page child={<Landing />} />} />
                </>
              )}

            { // DEV: Component showcases
              process.env.REACT_APP_ENV === 'dev' &&
              <>
                <Route exact path="/dev/redeem-popup" element={<Page child={<_TempTestPopupPage />} />} />
                <Route exact path="/dev/redeem-page" element={<Page child={<_TempTestRedeemPage />} />} />
              </>
            }

          </Routes>

        </NavigationFrame>
      </Fragment>
    </Router>
  );
}

export default App;
