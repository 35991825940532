const validateInputName = (name) => {
  if (name && typeof name === 'string' && name.length <= 100) return true;
  return false;
};

const validateInputEmail = (email) => {
  if (email && typeof email === 'string' && email.length <= 100 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return true;
  return false;
};

const validateInputMobile = (mobile) => {
  if (mobile && typeof mobile === 'string' && mobile.length <= 20 && /^\d+$/.test(mobile)) return true;
  return false;
};

const maskPhoneNumber = (phoneNumber) => {
  const length = phoneNumber.length - 2
  const maskedStr = '*'.repeat(length)
  return maskedStr + phoneNumber[phoneNumber.length - 2] + phoneNumber[phoneNumber.length - 1]
}

export {
  validateInputName,
  validateInputEmail,
  validateInputMobile,
  maskPhoneNumber
};
