import { makeStyles } from "@material-ui/core";
import Questions from "../../../constant/etriage/questions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import QuestionImage from "./QuestionImage";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  questionBox: {
    padding: "15px 20px",
    // paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyItems: "flex-start",
    minHeight: 330,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    flexGrow: 1,
    backgroundSize: 375,
  },
  shortText: {
    color: COLORS.fwdDarkGreen,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
  },
  mainText: {
    color: COLORS.fwdDarkGreen,
    fontSize: 25,
    fontWeight: 700,
    lineHeight: "31px",
    "& span": {
      whiteSpace: "nowrap"
    }
  },
  questionImage: {
    width: "100%",
    maxWidth: 450,
  },
  questionImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 1,
  },
}));

const QuestionBannerAndLabelSection = ({ questionIndex }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={classes.questionBox}
      style={{
        backgroundImage: `url(${QuestionImage({
          questionIndex: questionIndex,
        })})`,
      }}
    >
      <div className={classes.questioNImageBox}>
        <div className={classes.shortText}>
          {/* {Questions[questionIndex].shortText} */}
          {t(`eTriageQuestionsPage.questions.q${questionIndex + 1}.shortText`)}
        </div>
        <div className={classes.mainText}>
          {/* {Questions[questionIndex].text} */}
          {ReactHtmlParser(
            t(`eTriageQuestionsPage.questions.q${questionIndex + 1}.text`)
          )}
        </div>
      </div>

      {/* <div className={classes.questionImageContainer}>
        <img
          className={classes.questionImage}
          src={QuestionImage({
            questionIndex: questionIndex,
          })}
        />
      </div> */}
    </div>
  );
};

export default QuestionBannerAndLabelSection;
