import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import { MaxWidth } from "../../../constant/stylesSize";
import checkedBoxSvg from "../../../assets/landing/checkbox_checked.svg";
import uncheckedBoxSvg from "../../../assets/landing/checkbox_unchecked.svg";
import dentistBackgroundImage from "../../../assets/landing/dentistDesktopBackground.png";
import dentistMobileBackgroundImage from "../../../assets/landing/dentistMobileBackground.png";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  JustLikeDentistContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    background: COLORS.white,
    minHeight: 600,
    paddingTop: 350,
    backgroundImage: `url(${dentistMobileBackgroundImage})`,
    backgroundPosition: "center top",
    backgroundSize: "cover",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 40,
      alignItems: "center",
      backgroundImage: `url(${dentistBackgroundImage})`,
      backgroundPosition: "center bottom",
      backgroundSize: "cover",
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "center top",
    }

  },
  contentContainer: {
    maxWidth: MaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "flex-start",
      flexDirection: "row",
      // paddingBottom: 100,
    },
  },
  spaceFlexContainer: {
    flex: 1,
  },
  caption: {
    color: COLORS.white,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    marginBottom: 15,
    paddingTop: 20,
    [theme.breakpoints.up("md")]: {
      width: "90%",
      paddingTop: 0,
      color: COLORS.fwdDarkGreen,
    },
  },
  details: {
    color: COLORS.white,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    [theme.breakpoints.up("md")]: {
      width: "90%",
      color: COLORS.fwdDarkGreen,
    },
  },
  captionContainer: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
  },
  todoListContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flex: 1,
      display: "flex",
      paddingLeft: 95,
      paddingTop: 0,
      flexDirection: "row-reverse"
      // justifyContent: "center",
    },
    padding: "20px 0px",
  },
  todoList: {
    width: "100%",
    backgroundColor: COLORS.fwdOrange20,
    borderRadius: 16,
    padding: 16,
    [theme.breakpoints.up("md")]: {
      maxWidth: 335,
    },
  },
  todoRow: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    background: COLORS.white,
    borderRadius: 4,
    marginTop: 15,
  },
  actNow: {
    cursor: "pointer",
    border: `2px solid ${COLORS.fwdOrange}`,
    boxShadow: `0 2px 2px rgb(0 0 0 / 0.2);`,
    // "&:hover": {
    //   background: COLORS.fwdOrange20,
    // },
  },
  todoCheckboxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: 10,
  },
  todoCheckbox: {
    width: 24,
    height: 24,
  },
  todoLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
  },
  todoLabelRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  eTriageTodo: {
    cursor: "pointer",
  },
  todoLabel: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
  },
  todoStatusLabel: {
    background: COLORS.fwdYellow20,
    borderRadius: 4,
    padding: 5,
    color: COLORS.fwdDarkGreen,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    whiteSpace: "nowrap",
  },
  todoStatusDoneLabel: {
    background: COLORS.fwdLightGreen50,
    borderRadius: 4,
    padding: 5,
    color: COLORS.fwdDarkGreen,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    whiteSpace: "nowrap",
  },
  todoFrequencyLabel: {
    color: COLORS.fwdGreyDarkest,
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 450,
  },
}));

function JustLikeDentistContainer({ }) {
  const classes = useStyles();
  let navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  return (
    <>
      <div className={classes.JustLikeDentistContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.captionContainer}>
            <div className={classes.caption}>
              <div>{t("productLanding.justLikeDentist.title")}</div>
              <div>{t("productLanding.justLikeDentist.subtitle")}</div>
            </div>

            <div className={classes.details}>
              {t(`productLanding.justLikeDentist.caption.${currentBu}`)}
            </div>
          </div>
          <div className={classes.spaceFlexContainer}> </div>
          <div className={classes.todoListContainer}>
            <div className={classes.todoList}>
              <div> {t("productLanding.justLikeDentist.todoList.todo")}</div>

              <div
                className={clsx(classes.todoRow, classes.actNow)}
                onClick={() => {
                  navigate("/eTriage");
                }}
              >
                <div className={classes.todoCheckboxContainer}>
                  <img className={classes.todoCheckbox} src={uncheckedBoxSvg} />
                </div>
                <div className={classes.todoLabelContainer}>
                  <div
                    className={clsx(classes.todoLabelRow, classes.eTriageTodo)}
                  >
                    <div className={classes.todoLabel}>
                      {t(
                        "productLanding.justLikeDentist.todoList.mindStrengthAssessment"
                      )}
                    </div>
                    <div className={classes.todoStatusLabel}>
                      {t("productLanding.justLikeDentist.todoList.dueToday")}
                    </div>
                  </div>

                  <div className={classes.todoFrequencyLabel}>
                    {t(
                      "productLanding.justLikeDentist.todoList.onceEvery6Month"
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.todoRow}>
                <div className={classes.todoCheckboxContainer}>
                  <img className={classes.todoCheckbox} src={checkedBoxSvg} />
                </div>
                <div className={classes.todoLabelContainer}>
                  <div className={classes.todoLabelRow}>
                    <div className={classes.todoLabel}>
                      {t(
                        "productLanding.justLikeDentist.todoList.dentistAppointment"
                      )}
                    </div>
                    <div className={classes.todoStatusDoneLabel}>
                      {t("productLanding.justLikeDentist.todoList.done")}
                    </div>
                  </div>

                  <div className={classes.todoFrequencyLabel}>
                    {t(
                      "productLanding.justLikeDentist.todoList.onceEvery6Month"
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.todoRow}>
                <div className={classes.todoCheckboxContainer}>
                  <img className={classes.todoCheckbox} src={checkedBoxSvg} />
                </div>
                <div className={classes.todoLabelContainer}>
                  <div className={classes.todoLabelRow}>
                    <div className={classes.todoLabel}>
                      {t(
                        "productLanding.justLikeDentist.todoList.physicalExam"
                      )}
                    </div>
                    <div className={classes.todoStatusDoneLabel}>
                      {t("productLanding.justLikeDentist.todoList.done")}
                    </div>
                  </div>

                  <div className={classes.todoFrequencyLabel}>
                    {t("productLanding.justLikeDentist.todoList.onceEveryYear")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JustLikeDentistContainer;
