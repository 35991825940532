import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import SectionHeader, { sectionHeaderSizes, sectionHeaderAlignDirections } from "../../components/core-elements/SectionHeader";
import ActionIcon from "../../components/core-elements/ActionIcon";

import { fontSizes } from "../../../constant/font";
import ReactHTMLParser from "react-html-parser";

import Button from "../../components/core-elements/Button";
import { fontWeight } from "@material-ui/system";
import Spacer from "../../components/core-elements/Spacer";

const SendOTPErrorPopup = ({ classes, onGoBack, isFullPage = false, isEmbedComponent = false }) => {
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";
  return (
    <div className={classes.container}>
      <SectionHeader
        text={t(`eTriageLanding.otpValidation.${currentBu}.sendOtpErrorPopupTitle`)}
        size={isFullPage || isEmbedComponent ? sectionHeaderSizes.large : sectionHeaderSizes.medium}
        alignDirection={isFullPage ? sectionHeaderAlignDirections.center : sectionHeaderAlignDirections.left}
      />
      <Spacer y={20} />
      <div className={classes.caption}>
        {ReactHTMLParser(t(`eTriageLanding.otpValidation.${currentBu}.sendOtpErrorPopupCaption`))}
      </div>
      <Spacer y={20} />
      <Button title={t(`eTriageLanding.otpValidation.${currentBu}.ok`)} onClick={onGoBack} />
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  caption: {

    fontSize: fontSizes.contentMedium,
    fontWeight: 450,
    lineHeight: "24px",
    color: COLORS.fwdDarkGreen,
    "& a": {
      color: COLORS.fwdOrange,
      fontWeight: 700,
    }
  },
};

export default withStyles(styles)(SendOTPErrorPopup);
