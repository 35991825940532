import { Checkbox, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { COLORS } from "../../../../constant/colors";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./progressProviders";
import DialogCloseButtonRow from "../../../components/DialogCloseButtonRow";

const useStyles = makeStyles((theme) => ({
  resultCircularContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& .CircularProgressbar ": {
      maxWidth: 150,
      maxHeight: 150,
    },
    "& .CircularProgressbar .CircularProgressbar-trail": {
      strokeWidth: 5,
    },
  },

  resultCategoryColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 250,
  },
  resultCategoryColumnTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 150,
    width: "100%",
    flex: 1,
  },
  resultCategoryColumnIcon: {
    width: "68%",
    // width: '100px'
  },
  resultCategoryColumnLabel: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdDarkGreen,
    margin: "8px 8px",
    textAlign: "center",
    flex: 1,
  },
  resultCategoryColumnScoreLabel: {
    background: COLORS.fwdLightGreen20,
    padding: "8px 16px",
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    color: COLORS.fwdDarkGreen,
    marginBottom: 20,
    animation: "fadeIn 5s",
    flex: 1,
  },
  resultCategoryColumnScoreLabelLevel: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "18px",
  },
  learnMoreButton: {
    color: COLORS.fwdOrange,
    fontSize: 14,
    fontWeight: 17,
    lineHeight: "18px",
    cursor: "pointer",
  },
  dialogContent: {
    color: COLORS.fwdDarkGreen,
  },
}));

function CategoryResultItemComponent({ item, scores, i18n }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "resultPageLongText"]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSymptomsScorePercentage = (category) => {
    const score = scores.get(category).score / scores.get(category).maxScore;
    return score;
  };

  const getSymptomsLevel = (category) => {
    return scores.get(category).level.levelScore;
    // return scores.get(category).level.level;
  };

  const symptomsLevelLabel = {
    0: {
      text: "eTriageResultPage.level.noRisk",
      backgroundColor: COLORS.fwdLightGreen20,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdLightGreen50,
    },
    1: {
      text: "eTriageResultPage.level.mild",
      backgroundColor: COLORS.fwdBlue50,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdBlue50,
    },
    2: {
      text: "eTriageResultPage.level.moderate",
      backgroundColor: COLORS.fwdYellow,
      textColor: COLORS.fwdDarkGreen,
      circularPathRailColor: COLORS.fwdYellow,
    },
    3: {
      text: "eTriageResultPage.level.severe",
      backgroundColor: COLORS.fwdAlertRed,
      textColor: COLORS.white,
      circularPathRailColor: COLORS.fwdAlertRed,
    },
  };

  return (
    <>
      <div className={classes.resultCategoryColumn}>
        <div className={classes.resultCategoryColumnTop}>
          <div className={classes.resultCircularContainer}>
            <ProgressProvider valueStart={0} valueEnd={getSymptomsScorePercentage(item.category) * 100}>
              {(value) => (
                <CircularProgressbarWithChildren
                  value={value}
                  strokeWidth={9}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 1.0,
                    // Colors
                    pathColor: `${symptomsLevelLabel[getSymptomsLevel(item.category)].circularPathRailColor}`,
                    textColor: "#f88",
                    trailColor: "#DBDFE1",
                  })}>
                  <img src={item.icon} className={classes.resultCategoryColumnIcon} />
                </CircularProgressbarWithChildren>
              )}
            </ProgressProvider>
          </div>

          <div className={classes.resultCategoryColumnLabel}>{t(`eTriageResultPage.category.${item.category}`)}</div>
          {item.learnMore && (
            <>
              <div className={classes.learnMoreButton} onClick={handleClickOpen()}>
                {t(`eTriageResultPage.learnMore`)}
              </div>
              <Dialog open={open} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogCloseButtonRow handleClose={handleClose} />
                <DialogTitle id="scroll-dialog-title">
                  {t(`${item.category}LearnMore.title`, {
                    ns: "resultPageLongText",
                  })}
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    // ref={descriptionElementRef}
                    tabIndex={-1}>
                    {" "}
                    <div className={classes.dialogContent}>
                      {ReactHtmlParser(
                        t(`${item.category}LearnMore.detail`, {
                          ns: "resultPageLongText",
                        })
                      )}
                    </div>
                  </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
              </Dialog>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(CategoryResultItemComponent);
