import {
  ThemeProvider,
  createTheme,
  withStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { COLORS } from "../../../constant/colors";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ErrorMessage from "./ErrorMessage";
import ActionIcon from "./ActionIcon";
import CommonIcon, { commonIconTypes } from "./CommonIcon";

const keyboardDatePickerTheme = createTheme({
  palette: {
    primary: { main: COLORS.fwdOrange, contrastText: "#fff" },
    secondary: { main: COLORS.fwdOrange20 },
  },
});

// By default, calendar icon is only available in KeyboardDatePicker component but not DatePicker
// So we customize the TextField to add FWD calendar icon
const customDatePickerTextField = (props) => (
  <TextField
    {...props} // Keep parent props
    InputProps={
      {
        ...props.InputProps, // Keep parent props
        endAdornment: <InputAdornment position="end" style={{cursor: 'pointer'}} ><CommonIcon type={commonIconTypes.calendarOrange} /></InputAdornment>,
      }
    }
  />
);

const DatePicker = ({ label, placeholder = label, locale, dateFormat = 'DD/MM/yyyy', value, onChange, isError, errorMessage, initialFocusedDate }) => (
  <>
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <ThemeProvider theme={keyboardDatePickerTheme}>
        <MuiDatePickerStyled
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          label={value && label} // Only show top-up label when value not empty
          emptyLabel={placeholder} // Show label on input field when empty
          format={dateFormat}
          disableFuture
          openTo="year"
          views={["year", "month", "date"]}
          value={value}
          TextFieldComponent={customDatePickerTextField}
          initialFocusedDate={initialFocusedDate}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={
            (!value && !isError) ? { style: { color: COLORS.fwdGreyDarker } } // Placeholder
            : (!value && isError) ? { style: { color: COLORS.fwdAlertRed } } // Placeholder upon error
            : null
          }
          slots={{
            OpenPickerIcon: <ActionIcon type='arrowLeft' />
          }}
          onChange={onChange}
          error={isError}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
    {!!errorMessage && <ErrorMessage text={errorMessage} />}
  </>
);

const MuiDatePickerStyled = withStyles({
  root: {
    "& label.Mui-focused": {
      color: COLORS.fwdGreyDarker,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.fwdGrey,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.white,
      "& fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&:hover fieldset": {
        borderColor: COLORS.fwdGrey,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.fwdGrey,
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'unset', // Prevent being overriden by default MUI fonts
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: COLORS.fwdAlertRed,
    },
    "& .MuiInputBase-input.Mui-error": {
      color: COLORS.fwdAlertRed,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.fwdAlertRed,
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.fwdOrange,
    },
  },
})(MuiDatePicker);

export default DatePicker;
