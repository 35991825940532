/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      surveyKey
      question
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyEmail = /* GraphQL */ `
  mutation CreateSurveyEmail(
    $input: CreateSurveyEmailInput!
    $condition: ModelSurveyEmailConditionInput
  ) {
    createSurveyEmail(input: $input, condition: $condition) {
      id
      email
      surveyKey
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyRecord = /* GraphQL */ `
  mutation CreateSurveyRecord(
    $input: CreateSurveyRecordInput!
    $condition: ModelSurveyRecordConditionInput
  ) {
    createSurveyRecord(input: $input, condition: $condition) {
      id
      surveyKey
      answers
      clientId
      isCustomer
      policy
      productCode
      policyIssueDate
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      clientId
      coupon
      isUsed
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      clientId
      coupon
      isUsed
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      clientId
      coupon
      isUsed
      createdAt
      updatedAt
    }
  }
`;
