import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import NotAtAllSvg from "../../../assets/eTriage/questions/options/notAtAll.svg";
import SeveralDaysSvg from "../../../assets/eTriage/questions/options/severalDays.svg";
import MoreThanHalfTheDaysSvg from "../../../assets/eTriage/questions/options/moreThanHalfTheDays.svg";
import NearlyEverydaysSvg from "../../../assets/eTriage/questions/options/nearlyEverydays.svg";
import { FrequencyOptions } from "../../../constant/etriage/options";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  frequencyButtonGroups: {
    // marginTop: 20,
  },
  frequencyButtonContainer: {
    padding: "5px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  frequencyButton: {
    border: `1px solid ${COLORS.fwdGrey}`,
    borderRadius: 8,
    flex: 1,
    height: 67,
    display: "flex",
    flexDirection: "row",
    background: COLORS.white,
    padding: "0px 40px",
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    alignItems: "center",
    // boxShadow: "0px 2px rgb(0 0 0 / 20%)",
    "&:hover": {
      backgroundColor: COLORS.fwdOrange20,
      border: `2px solid ${COLORS.fwdOrange}`,
      color: COLORS.fwdOrange,
    },
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    "& span": {
      whiteSpace: "nowrap"
    }
  },
  buttonImage: {
    width: 40,
    marginRight: 30,
  },
  selected: {
    backgroundColor: COLORS.fwdOrange20,
    border: `2px solid ${COLORS.fwdOrange}`,
    color: COLORS.fwdOrange,
  },
}));

function DesktopFrequencySelectGroups({ onAnswerSelect, selectedAnswer }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [answerSelected, setAnswerSelected] = useState(null);

  const iconSvg = {
    notAtAll: NotAtAllSvg,
    severalDays: SeveralDaysSvg,
    moreThanHalfTheDays: MoreThanHalfTheDaysSvg,
    nearlyEveryday: NearlyEverydaysSvg,
  };

  useEffect(() => {
    setAnswerSelected(selectedAnswer);
  }, [selectedAnswer]);

  return (
    <>
      <div className={classes.frequencyButtonGroups}>
        {FrequencyOptions.map((item, itemIndex) => {
          return (
            <div className={classes.frequencyButtonContainer} key={itemIndex}>
              <div
                className={clsx(
                  classes.frequencyButton,
                  answerSelected == item.value && classes.selected
                )}
                onClick={() => {
                  onAnswerSelect(item.value);
                }}
              >
                <img src={iconSvg[item.icon]} className={classes.buttonImage} />
                <div>
                  {ReactHtmlParser(
                    t(item.label)
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default DesktopFrequencySelectGroups;
