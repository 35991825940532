import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";

const Page = (props) => {
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  useEffect(() => {
    document.title = t(`tabTitle.programme.${currentBu}`) + " | "+ t(`tabTitle.${currentBu}`);
  },[]);



  const {  child } = props;
  return <>{props.child}</>;
};

export default Page;
