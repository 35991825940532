export const landingVideoJson = {
  assessmentVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blte01479f677d30f1d/6390630e4c3a2b1b59fa2d2d/FWD_Mind_Strength_Assessment_Test_Eng_for_MY_20221206_(1).mp4",
  },
  learningVideo: {
    videoUrl:
      "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
  },
  learningCenterVideoList: [
    {
      presenterName: "Saguguru",
      thumbnail:
        "https://mc-1ba1fb3b-6c1c-4deb-94a0-831522-cdn-endpoint.azureedge.net/-/media/01---website-imagery/chinachem/live-images/people-pages/people/final/people-rino-ko.jpg?rev=29d543389e2d4d8899fb9d8f88dc15ba",
      caption: "Engineer with your inner energy",
      videoLength: "3:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
    },
    {
      presenterName: "Keith Ferrazzi",
      thumbnail:
        "https://imageio.forbes.com/specials-images/imageserve/5f64397931669e167fc57eaf/0x0.jpg?format=jpg&width=1200",
      caption: "Engineer with your inner energy",
      videoLength: "12:03",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
    },
    {
      presenterName: "abcccc",
      thumbnail:
        "https://www.betterup.com/hubfs/Imported_Blog_Media/networking%20blog%20image-1.png",
      caption: "Engineer with your inner energy",
      videoLength: "20:22",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
    },
    {
      presenterName: "Saguguru",
      thumbnail:
        "https://mc-1ba1fb3b-6c1c-4deb-94a0-831522-cdn-endpoint.azureedge.net/-/media/01---website-imagery/chinachem/live-images/people-pages/people/final/people-rino-ko.jpg?rev=29d543389e2d4d8899fb9d8f88dc15ba",
      caption: "Engineer with your inner energy",
      videoLength: "3:02",
      videoUrl:
        "https://assets.contentstack.io/v3/assets/blt982dbc2422e29419/blt604d789c6936b302/6331616ed1176a0e3a5f7d67/Video_2.mp4",
    },
  ],
};
