export const getTriageResult = (phq9Score, gad7Score, wsasScore) => {
  const phq9 = {
    0: "low",
    1: "mild",
    2: "moderate",
    3: "moderately severe",
    ">=4": "severe",
  };
  const gad7 = {
    0: "low",
    1: "mild",
    2: "moderate",
    ">=3": "severe",
  };
  const wsas = {
    0: "low",
    1: "moderate",
    ">=2": "severe",
  };
  const phq9Status =
    phq9Score / 5 >= 4 ? phq9[">=4"] : phq9[Math.trunc(phq9Score / 5)];
  const gad7Status =
    gad7Score / 5 >= 3 ? gad7[">=3"] : gad7[Math.trunc(gad7Score / 5)];
  const wsasStatus =
    wsasScore / 10 >= 2 ? wsas[">=2"] : wsas[Math.trunc(wsasScore / 10)];
  return etriageData.filter(
    (it) =>
      it["PHQ-9"] === phq9Status &&
      it["GAD-7"] === gad7Status &&
      it["WSAS"] === wsasStatus
  )[0];
};

export const signPostingScenario = {
  scenario1: {
    label: 1,
    order: [
      "emergency",
      "psychiatry",
      "psychotherapy",
      "counselling",
      "coaching",
      "selfCare",
    ],
  },
  scenario2: {
    label: 2,
    order: [
      "psychiatry",
      "psychotherapy",
      "counselling",
      "coaching",
      "selfCare",
      "emergency",
    ],
  },
  scenario3: {
    label: 3,
    order: [
      "psychotherapy",
      "counselling",
      "coaching",
      "selfCare",
      "psychiatry",
      "emergency",
    ],
  },
  scenario4: {
    label: 4,
    order: [
      "psychotherapy",
      "psychiatry",
      "counselling",
      "coaching",
      "selfCare",
      "emergency",
    ],
  },
  scenario5: {
    label: 5,
    order: [
      "selfCare",
      "coaching",
      "counselling",
      "psychotherapy",
      "psychiatry",
      "emergency",
    ],
  },
  scenario6: {
    label: 6,
    order: [
      "selfCare",
      "psychotherapy",
      "coaching",
      "counselling",
      "psychiatry",
      "emergency",
    ],
  },
};

const etriageData = [
  {
    "PHQ-9": "low",
    "GAD-7": "low",
    WSAS: "low",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario5,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "low",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "low",
    WSAS: "severe",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "mild",
    WSAS: "low",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario5,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "mild",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "mild",
    WSAS: "severe",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "moderate",
    WSAS: "low",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "moderate",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "moderate",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "severe",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "severe",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "low",
    "GAD-7": "severe",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "low",
    WSAS: "low",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario5,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "low",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "low",
    WSAS: "severe",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "mild",
    WSAS: "low",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario5,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "mild",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "mild",
    WSAS: "severe",
    "Result:": "",
    "Total level": "sub-clinical",
    Signposting: signPostingScenario.scenario6,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "moderate",
    WSAS: "low",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "moderate",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "moderate",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "severe",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "severe",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "mild",
    "GAD-7": "severe",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "low",
    WSAS: "low",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "low",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "low",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "mild",
    WSAS: "low",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "mild",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "mild",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "moderate",
    WSAS: "low",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "moderate",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Mild-Moderate",
    Signposting: signPostingScenario.scenario3,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "moderate",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "severe",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "severe",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderate",
    "GAD-7": "severe",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "low",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "low",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "low",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "mild",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "mild",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "mild",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "moderate",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "moderate",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "moderate",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "severe",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "severe",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "moderately severe",
    "GAD-7": "severe",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "low",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "low",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "low",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "mild",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "mild",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "mild",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "moderate",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "moderate",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "moderate",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario4,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "severe",
    WSAS: "low",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario2,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "severe",
    WSAS: "moderate",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario2,
  },
  {
    "PHQ-9": "severe",
    "GAD-7": "severe",
    WSAS: "severe",
    "Result:": "",
    "Total level": "Moderately severe-Severe",
    Signposting: signPostingScenario.scenario2,
  },
];
