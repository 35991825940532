export async function triggerHKApi() {
  // const response = await fetch('https://10.14.60.62/api/V1/HK_UAT/get_Customer',{
  //     method:'POST',
  //     headers:{"apiKey": "yPXZK122cALG20PyX7cVTHoclhVPa5hP", }
  // })

  var myHeaders = new Headers();
  // myHeaders.append("X-HTTP-Method-Override", "GET");
  myHeaders.append("apiKey", "yPXZK122cALG20PyX7cVTHoclhVPa5hP");
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json;charset=UTF-8");
  myHeaders.append("X-HTTP-Method-Override", "GET");

  var raw = ' {"$filter":"FirstName =\'Ie+4qGt+lFH+jsMde0MfDQ==\'"}';

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  debugger;

  const response = await fetch(
    "https://api-kong-uat.service.fwd.com:1443/api/V1/HK_UAT/get_Customer",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  return await response.json();
}
