import React from "react";
import { withStyles } from "@material-ui/core";

import { COLORS } from "../../../constant/colors";
import Spacer from "../../components/core-elements/Spacer";
import AppIcon from "../../components/core-elements/AppIcon";
import CopyTextButton from "../../components/core-elements/CopyTextButton";
import hurrayIconSrc from "../../../assets/general/hurray-2x.png";
import Button from "../../components/core-elements/Button";
import CommonIcon, { commonIconTypes } from "../../components/core-elements/CommonIcon";
import { color, height, textAlign } from "@material-ui/system";

const redeemSuccessViewShowTypes = {
  downloadLink: "downloadLink",
  copyCode: "copyCode",
  downloadLinkWithQrCode: "downloadLinkWithQRCode",
};

const RedeemSuccessView = ({ classes, title, titleContent, description, showType, buttonTitle, copyCode, onClickButton, qrCodeURL, qrCodeDescription, separatorLabel }) => (

  <div className={classes.container}>

    {
      (showType === redeemSuccessViewShowTypes.downloadLink || showType === redeemSuccessViewShowTypes.copyCode) && (
        <div className={classes.iconContainer}>
          <AppIcon imageSrc={hurrayIconSrc} additionalClass={classes.hurrayIcon} />
        </div>
      )
    }

    <Spacer y={24} />
    <div className={classes.textTitle}>{title}</div>
    {!!titleContent && <>{titleContent()}</>}
    {!!description && (
      <>
        <Spacer y={8} />
        <div className={classes.textDescription}>{description}</div>
      </>
    )}
    <Spacer y={24} />

    {showType === redeemSuccessViewShowTypes.downloadLink && (
      <Button title={buttonTitle} iconLeft={<CommonIcon type={commonIconTypes.downloadWhite} />} onClick={onClickButton} />
    )}
    {showType === redeemSuccessViewShowTypes.copyCode && <CopyTextButton text={copyCode} isShortText buttonTitle={buttonTitle} onClickButton={onClickButton} />}
    {showType === redeemSuccessViewShowTypes.downloadLinkWithQrCode && (
      <>
        <div className={classes.qrCodeContainer}>
          <img src={qrCodeURL} className={classes.qrCode} />

        </div>
        <Spacer y={12} />
        <div className={classes.qrCodeDescription}>{qrCodeDescription}</div>
        <Spacer y={16} />
        <div className={classes.orSeparator}>
          <div className={classes.greySeparatorLine}></div>
          <div className={classes.separatorLineLabel}>{separatorLabel}</div>
          <div className={classes.greySeparatorLine}></div>
        </div>
        <Spacer y={16} />
        <Button title={buttonTitle} iconLeft={<CommonIcon type={commonIconTypes.downloadWhite} />} onClick={onClickButton} />
      </>
    )}
  </div>
);

const styles = {
  container: {
    width: "100%",
    maxWidth: 344,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hurrayIcon: {
    height: 150,
    width: "auto",
  },
  // Text
  textTitle: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "32px",
    color: COLORS.fwdDarkGreen,
  },
  textDescription: {
    textAlign: "center",
    lineHeight: "24px",
  },
  qrCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  qrCode: {
    width: 133,
    height: 133,
  },
  qrCodeDescription: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: '20px',
    color: COLORS.fwdDarkGreen
  },
  orSeparator: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  greySeparatorLine: {
    backgroundColor: COLORS.borderLightGrey,
    height: 1,
    width: "40%",
  },
  separatorLineLabel: {
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    color: COLORS.black,
    textAlign: "center"
  }
};

export { redeemSuccessViewShowTypes };

export default withStyles(styles)(RedeemSuccessView);
