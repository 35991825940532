import React from "react";
import { withStyles, Checkbox as MuiCheckBox } from "@material-ui/core";
import { COLORS } from "../../../constant/colors";

const CheckBox = ({ classes, isChecked, onCheckUncheck }) => (
  <MuiCheckBox
    checked={isChecked}
    className={classes.checkbox}
    onChange={(e) => {
      onCheckUncheck(e.target.checked);
    }}
  />
);

const styles = {
  checkbox: {
    width: 24,
    height: 24,
    color: COLORS.fwdOrange,
    borderColor: COLORS.fwdOrange,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
    "&.Mui-checked": {
      color: COLORS.fwdOrange,
    },
  },
};

export default withStyles(styles)(CheckBox);
