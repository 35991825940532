import { makeStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import landingBannerImagePng from "../../../assets/landing/landingBanner.png";
import landingBannerImageDesktopPng from "../../../assets/landing/landingBannerDesktop.png";
import bannerOverlaySvg from "../../../assets/landing/bannerOverlay.svg";
import bannerOverlayDesktopSvg from "../../../assets/landing/bannerOverlayDesktop.svg";
import nextButtonSvg from "../../../assets/landing/nextButton.svg";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import { MaxWidth } from "../../../constant/stylesSize";
import TagManager from "react-gtm-module";
import koaHealthLogoLight from "../../../assets/KoaStacked_WhiteMed.png";
import DialogCloseButtonRow from "../../components/DialogCloseButtonRow";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  heroBannerContainer: {
    backgroundSize: "cover",
    minHeight: 680,
    backgroundImage: `url(${bannerOverlaySvg}),url(${landingBannerImagePng}) `,
    backgroundRepeat: "repeat-x, no-repeat",
    display: "flex",
    flexDirection: "column",
    padding: 15,
    backgroundPosition: "center, center top",
    justifyContent: "flex-end",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      aspectRatio: "auto",
      // height: 560,
      backgroundImage: `url(${bannerOverlayDesktopSvg}),url(${landingBannerImageDesktopPng}) `,
      backgroundRepeat: "no-repeat",
      alignItems: "center",
      backgroundPosition: "left, right center",
      paddingLeft: 70,
      paddingRight: 70,
      justifyContent: "center",
      paddingTop: 72
    },
  },
  contentContainer: {
    maxWidth: MaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "flex-start",
    },
  },
  programmeTitle: {
    color: COLORS.white,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    flexGrow: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      // marginLeft: 70,
    },
  },

  exclusiveForAgent: {
    color: COLORS.fwdDarkGreen,
    fontSize: 24,
    fontWeight: 700,
    maxWidth: 200,
    textAlign: "right",
    position: "absolute",
    right: "20px",
    top: "85px",
  },

  programmeCaption: {
    color: COLORS.white,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    textAlign: "center",
    padding: "20px 0px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      // marginLeft: 70,
      width: "40%",
      textAlign: "left",
    },
  },
  tryItButtonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  tryItButtonText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    display: "flex",
    flexDirection: "row",
  },
  tryItButton: {
    width: "100%",
    background: COLORS.fwdOrange,
    color: COLORS.white,
    height: 40,
    minWidth: 120,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: COLORS.fwdOrange20,
    },
    "&:disabled": {
      background: COLORS.fwdOrange20,
      color: COLORS.white,
    },

    [theme.breakpoints.up("md")]: {
      width: "auto",
      // marginLeft: 70,
      marginBottom: 40,
      padding: 15,
    },
  },
  tryItButtonIcon: {
    marginRight: 10,
  },
  poweredBy: {
    display: "flex",
    flexDirection: "row",
    color: COLORS.white,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
    alignItems: "center",
    paddingTop: 20,
  },
  koaHealthLogo: {
    height: 40,
    marginLeft: 5,
    cursor: "pointer",
  },
  koaHealthLogoImg: {
    height: "100%",
  },
  dialogContent: {
    color: COLORS.fwdDarkGreen,
  },
}));

function BannerContainer({ }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tagStartNowArgs = {
    dataLayer: {
      event: "buttonClick",
      buttonTitle: "try it now",
      pageUrl: window.location.href,
      componentName: "banner cta",
      language: i18n.language,
    },
  };

  let navigate = useNavigate();
  return (
    <>
      <div className={classes.heroBannerContainer}>
        <div className={classes.contentContainer}>
          {process.env.REACT_APP_ENV == "agent" && <div className={classes.exclusiveForAgent}> {t(`productLanding.exclusiveForAgent.${currentBu}`)}</div>}

          <div className={classes.programmeTitle}>{t("productLanding.programmeTitle")}</div>
          <div className={classes.poweredBy}>
            {t("poweredBySection.poweredBy")}
            <div className={classes.koaHealthLogo} onClick={handleClickOpen}>
              <img src={koaHealthLogoLight} className={classes.koaHealthLogoImg} />
            </div>
            {t("poweredBySection.poweredBy_2")}
          </div>
          {/* <div className={classes.programmeTitle}></div> */}
          <div className={classes.programmeCaption}>{ReactHtmlParser(t(`productLanding.programmeCaption.${currentBu}`))}</div>
          <div className={classes.tryItButtonRow}>
            <Button
              className={classes.tryItButton}
              onClick={() => {
                // validateClient();
                TagManager.dataLayer(tagStartNowArgs);
                navigate("/eTriage");
              }}>
              <div className={classes.tryItButtonText}>
                <img src={nextButtonSvg} className={classes.tryItButtonIcon} />
                {t("productLanding.tryItNowButton")}
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogCloseButtonRow handleClose={handleClose} />
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}>
            <div className={classes.dialogContent}> {ReactHtmlParser(t(`poweredBySection.koaHealthIntro.${currentBu}`))}</div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BannerContainer;
