import { Dialog, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";

import ReactPlayer from "react-player";
import videoPlaySvg from "../../../assets/landing/videoPlay.svg";
import { Trans } from "react-i18next";
import { MaxWidth } from "../../../constant/stylesSize";
import personalisedTreatmentSvg from "../../../assets/landing/advantage_personalisedTreatment.svg";
import complimentaryCoachingSvg from "../../../assets/landing/advantage_complimentaryCoaching.svg";
import getYourScoreSvg from "../../../assets/landing/advantage_getYourScore.svg";
import ReactHtmlParser from "react-html-parser";
import DialogCloseButtonRow from "../../components/DialogCloseButtonRow";

const useStyles = makeStyles((theme) => ({
  assessmentServiceContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    background: COLORS.fwdOrange20,

    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      alignItems: "center",
    },
  },
  contentContainer: {
    maxWidth: MaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "flex-start",
    },
  },
  sectionHeaderRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: "center",
  },
  sectionHeader: {
    display: "flex",
    flexGrow: 1,
    color: COLORS.fwdDarkGreen,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
    marginLeft: 20,
  },
  caption: {
    color: COLORS.fwdDarkGreen,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    marginBottom: 15,
    paddingTop: 20,
  },
  details: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
    marginBlock: 20,
    // [theme.breakpoints.up("md")]: {
    //   flex: 3,
    //   padding: 20,
    //   paddingLeft: 40,
    // },
  },
  videoContainer: {
    marginTop: 20,
    marginBottom: 20,
    position: "relative",

    "& .videoClass video": {
      borderRadius: 16,
      overflow: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      flex: 3,
    },
  },
  videoPlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  videoWithDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
    },
  },
  pointTable: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    flex: 2,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 80,
    },
  },
  pointRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  pointText: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "20px",
    marginLeft: 15,
    flexGrow: 1,
  },
  landingTncButton: {
    marginTop: 12,
    color: COLORS.fwdOrange,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "20px",
    cursor: "pointer",
    paddingBottom: 32,
  },
  remarksSection: {
    marginTop: 12,
    fontSize: 14,
    fontWeight: 450,
    lineHeight: "21px",
  },
  linkText: {
    color: COLORS.fwdOrange,
    cursor: "pointer",
  },
  dialogContent: {
    color: COLORS.fwdDarkGreen,
  },
}));

function AssessmentServiceContainer({ }) {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openKoa, setOpenKoa] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenKoa = () => {
    setOpenKoa(true);
  };

  const handleCloseKoa = () => {
    setOpenKoa(false);
  };

  const currentBu = process.env.REACT_APP_BU ?? "hk";

  const displayParamsFile = require(`../../../constant/landingJson/landingComponentJson_${currentBu}`);

  const displayParams = displayParamsFile["landingComponentJson"];

  const paramsFile = require(`../../../constant/landingJson/landingVideoJson_${currentBu}_${i18n.language}`);

  const params = paramsFile["landingVideoJson"];

  const currentVideo = params.assessmentVideo;

  const [videoPlaying, setVideoPlaying] = useState(false);
  return (
    <>
      <div className={classes.assessmentServiceContainer}>
        <div className={classes.contentContainer}>
          {/* <div className={classes.sectionHeaderRow}>
            <img src={assessmentIconSvg} />
            <div className={classes.sectionHeader}>
              {t("productLanding.assessmentSectionHeader")}
            </div>
          </div> */}
          <div className={classes.caption}>{t(`productLanding.assessmentSectionHeader.${currentBu}`)}</div>
          <div className={classes.details}>
            <Trans i18nKey={`productLanding.assessmentSectionCaption.${currentBu}`}></Trans>
          </div>
          <div className={classes.videoWithDetails}>
            <div className={classes.pointTable}>
              <div className={classes.pointRow}>
                <div>
                  <img src={getYourScoreSvg} />
                </div>
                <div className={classes.pointText}>{ReactHtmlParser(t(`productLanding.assessmentAdvantage.getYourScore.${currentBu}`))}</div>
              </div>
              <div className={classes.pointRow}>
                <div>
                  <img src={personalisedTreatmentSvg} />
                </div>
                <div className={classes.pointText}>{ReactHtmlParser(t(`productLanding.assessmentAdvantage.personalisedTreatment.${currentBu}`))}</div>
              </div>
              <div className={classes.pointRow}>
                <div>
                  <img src={complimentaryCoachingSvg} />
                </div>
                <div className={classes.pointText}>{ReactHtmlParser(t(`productLanding.assessmentAdvantage.complimentaryCoaching.${currentBu}`))}</div>
              </div>
            </div>

            <div className={classes.videoContainer}>
              <ReactPlayer
                className={"videoClass"}
                url={currentVideo.videoUrl}
                controls={true}
                width={"100%"}
                height={"auto"}
                playsinline={true}
                playing={videoPlaying}
                onPlay={() => {
                  setVideoPlaying(true);
                }}
              />
              {!videoPlaying && (
                <img
                  src={videoPlaySvg}
                  className={classes.videoPlayIcon}
                  onClick={() => {
                    setVideoPlaying(true);
                  }}
                />
              )}
            </div>
          </div>

          {displayParams.LearningCenterContainer == false && (
            <div className={classes.remarksSection}>
              <div>{t("productLanding.learningCenter.remarksTitle")}</div>
              <ol>
                <li>
                  <span>{t(`productLanding.learningCenter.remark1_1.${currentBu}`)}</span>

                  <span className={classes.linkText} onClick={handleClickOpenKoa}>
                    {t("productLanding.learningCenter.remark1_2")}
                  </span>
                  <span>{t("productLanding.learningCenter.remark1_3")}</span>
                </li>
                <li>
                  <span>{t(`productLanding.learningCenter.remark2_1.${currentBu}`)}</span>
                </li>
              </ol>
            </div>
          )}
          {displayParams.AssessmentServiceContainerLandingPageTnCButton && (
            <div className={classes.landingTncButton} onClick={handleClickOpen()}>
              {ReactHtmlParser(t(`productLanding.assessmentAdvantage.landingTncButton.${currentBu}`))}
            </div>
          )}
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogCloseButtonRow handleClose={handleClose} />
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}>
            <div className={classes.dialogContent}> {ReactHtmlParser(t(`productLanding.assessmentAdvantage.landingTncContent.${currentBu}`))}</div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={openKoa} onClose={handleCloseKoa} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogCloseButtonRow handleClose={handleCloseKoa} />
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}>
            <div className={classes.dialogContent}>{ReactHtmlParser(t(`poweredBySection.koaHealthIntro.${currentBu}`))}</div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AssessmentServiceContainer;
