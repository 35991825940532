import React from "react";
import { Dialog, withStyles } from "@material-ui/core";

import { COLORS } from "../../constant/colors";
import DialogCloseButton from "../components/core-elements/DialogCloseButton";

const PopupContainer = ({ classes, isOpen, onClose = () => { }, onClickClose, children }) => (
  <Dialog classes={{ paper: classes.dialogPaper }} open={isOpen} onClose={onClose} disableEscapeKeyDown={true}>
    <DialogCloseButton onClick={onClickClose} />
    <div className={classes.popupContentContainer}>
      <>{children}</>
    </div>
  </Dialog>
);

const styles = (theme) => ({
  dialogPaper: {
    overflow: 'unset',
    borderRadius: 10,
    maxWidth: 400,
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
      margin: 10,
      width: "calc(100% - 70px)"
    },
  },
  popupContentContainer: {
    backgroundColor: COLORS.white,
    borderRadius: 20,
    padding: "32px 32px",
  }
});

export default withStyles(styles)(PopupContainer);
