import { makeStyles } from "@material-ui/core";
import React from "react";
import closeButtonSvg from "../../assets/closeButton.svg";

const useStyles = makeStyles((theme) => ({
  dialogCloseButtonRow: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: 5,
  },
  dialogCloseButton: {
    padding: 10,
    cursor: "pointer",
  },
  dialogCloseButtonImg: {
    width: 24,
  },
}));

function DialogCloseButtonRow({ handleClose }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.dialogCloseButtonRow}>
        <div className={classes.dialogCloseButton} onClick={handleClose}>
          <img className={classes.dialogCloseButtonImg} src={closeButtonSvg} />
        </div>
      </div>
    </>
  );
}

export default DialogCloseButtonRow;
