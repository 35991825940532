import React from "react";
import { withStyles } from "@material-ui/core";
import nextIconSvg from "../../../assets/eTriage/result/nextIcon.svg";
import chevronRightSvg from "../../../assets/eTriage/result/chevronRight.svg";
import { COLORS } from "../../../constant/colors";
import clsx from "clsx";

const ClickableText = ({ classes, text, isShowArrow, isShowChevronRight, onClick, isCenter = false }) => (
  <div className={clsx(classes.buttonContainer, isCenter && classes.centerContent)}>
    {!!isShowArrow && <img className={classes.nextIcon} src={nextIconSvg} />}
    <div className={classes.buttonText} onClick={onClick}>{text}
      {!!isShowChevronRight && <img className={classes.nextIcon} src={chevronRightSvg} />}
    </div>
  </div>
);

const styles = {
  buttonContainer: {
    cursor: "pointer",
    // padding: 20,
    // width: "100%",
    // maxWidth: 570,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: 'center',
  },
  centerContent: {
    justifyContent: "center",
  },
  nextIcon: {
    marginRight: 4,
    // display: 'inline',
  },
  buttonText: {
    // display: 'inline',
    color: COLORS.fwdOrange,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
};

export default withStyles(styles)(ClickableText);

