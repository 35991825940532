import { SymptomsLevel, SystomsLevelDto } from "./symptomsLevel";

const noDepresion: SystomsLevelDto = {
  min: 0,
  max: 4,
  description: "eTriageResultPage.description.depression.noDepresion",
  isEmergency: false,
  levelScore: 0,
  level: "no",
};
const mildDepresion: SystomsLevelDto = {
  min: 5,
  max: 9,
  description: "eTriageResultPage.description.depression.mildDepresion",
  isEmergency: false,
  levelScore: 1,
  level: "mild",
};
const moderateDepresion: SystomsLevelDto = {
  min: 10,
  max: 14,
  description: "eTriageResultPage.description.depression.moderateDepresion",
  isEmergency: false,
  levelScore: 2,
  level: "moderate",
};
const moderateToSevereDepresion: SystomsLevelDto = {
  min: 15,
  max: 19,
  description:
    "eTriageResultPage.description.depression.moderateToSevereDepresion",
  isEmergency: false,
  levelScore: 2,
  level: "moderateToSevere",
};
const severeDepresion: SystomsLevelDto = {
  min: 20,
  description: "eTriageResultPage.description.depression.severeDepresion",
  isEmergency: true,
  levelScore: 3,
  level: "severe",
};

export const depressionLevels: SymptomsLevel[] = [
  new SymptomsLevel(noDepresion),
  new SymptomsLevel(mildDepresion),
  new SymptomsLevel(moderateDepresion),
  new SymptomsLevel(moderateToSevereDepresion),
  new SymptomsLevel(severeDepresion),
];
