import { Checkbox, makeStyles } from "@material-ui/core";
import Questions, {
  Categories,
  Category,
} from "../../../constant/etriage/questions";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import DotSvgComponent from "../../../assets/eTriage/questions/progressBar/DotSvgComponent";
import StarSvgComponent from "../../../assets/eTriage/questions/progressBar/StarSvgComponent";
import FrequencySelectGroups from "./FrequencySelectGroups";
import AmplitudeSelectGroups from "./AmplitudeSelectGroups";
import BackButtonSvgComponent from "../../../assets/eTriage/questions/pagination/backButton";
import NextButtonSvgComponent from "../../../assets/eTriage/questions/pagination/nextButton";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    backgroundColor: COLORS.white,

    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: "20px 20px",
  },
  backAndNextButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    position: "relative",
    height: 40,
  },
  backAndNextButtonGroupRight: {
    display: "flex",
    flexDirection: "row",
  },
  iconImage: {
    width: 17,
    height: 19,
    cursor: "pointer",
  },
  nextIconImage: {
    position: "absolute",
    right: 0,
    marginRight: 10,
  },
  finishLabel: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: COLORS.fwdOrange,
    marginRight: 10,
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18px",
    color: COLORS.fwdDardGreen50,
    padding: "0px 10px",
  },
  progressBar: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 15px 0px 15px",
    justifyContent: "space-between",
  },
  nextButton: {
    background: COLORS.fwdOrange,
    color: COLORS.white,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    cursor: "pointer",
    textAlign: "center",
    padding: 10,
    marginBottom: 20,
    
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
}));

function OptionsModal({
  questionIndex,
  questionsDetail,
  onAnswerSelect,
  selectedAnswer,
  nextPage,
  previousPage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [amplitudeSelectedAnswer, setAmplitudeSelectedAnswer] = useState(0);
  const categoriesData = Categories.get(questionsDetail.category);

  const shouldShowNextButton =
    categoriesData.type != "amplitude" && selectedAnswer[questionIndex] >= 0;

  useEffect(() => {
    // console.log("questionIndex = " + questionIndex + ", " + selectedAnswer);
    // debugger;
    setAmplitudeSelectedAnswer(selectedAnswer[questionIndex] ?? 0);
  }, [questionIndex]);


  return (
    <>
      <div component="modal" className={classes.modalContainer}>
        <div className={classes.backAndNextButtonGroup}>
          {questionsDetail.showBackButton && (
            <div className={classes.iconImage} onClick={previousPage}>
              <BackButtonSvgComponent />
            </div>
          )}
          {shouldShowNextButton && (
            <div
              className={clsx(classes.iconImage, classes.nextIconImage)}
              onClick={() => {
                onAnswerSelect(selectedAnswer[questionIndex]);
              }}
            >
              <NextButtonSvgComponent />
            </div>
          )}
        </div>
        <div className={classes.sectionTitle}>
          {t(`eTriageQuestionsPage.${categoriesData.label}`)} (
          {t(`eTriageQuestionsPage.pleaseSelectOneAnswer`)})
        </div>
        <div className={classes.progressBar}>
          {Questions.map((item: Question, index) => {
            const filledColor =
              questionIndex == index
                ? COLORS.fwdOrange
                : questionIndex > index
                  ? COLORS.fwdOrange50
                  : COLORS.fwdGrey;
            return (
              <div key={index}>
                {item.starIndicator == true ? (
                  <StarSvgComponent fill={filledColor} key={index} />
                ) : (
                  <DotSvgComponent fill={filledColor} key={index} />
                )}
              </div>
            );
          })}
        </div>

        {questionsDetail.type == "frequency" ? (
          <FrequencySelectGroups
            key={questionIndex}
            selectedAnswer={selectedAnswer[questionIndex]}
            onAnswerSelect={(answerIndex) => {
              onAnswerSelect(answerIndex);
            }}
          />
        ) : (
          <AmplitudeSelectGroups
            key={questionIndex}
            selectedAnswer={selectedAnswer[questionIndex]}
            onAnswerSelect={(answerIndex) => {
              setAmplitudeSelectedAnswer(answerIndex);
            }}
          />
        )}

        {questionsDetail.showNextButton && (
          //For AmplitudeQuestion, only save answer and go to next page by next page button
          <>
            <div
              className={classes.nextButton}
              // onClick={nextPage}
              onClick={() => {
                onAnswerSelect(amplitudeSelectedAnswer);
              }}
            >
              {questionsDetail.showFinishButton
                ? t(`eTriageQuestionsPage.finish`)
                : t(`eTriageQuestionsPage.next`)}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default OptionsModal;
