import React from "react";
import { withStyles, Checkbox } from "@material-ui/core";

import { COLORS } from "../../../constant/colors";
import CheckBox from "./CheckBox";
import { fontSizes } from "../../../constant/font";
import ReactHtmlParser from "react-html-parser";

const AcknowledgeCheckBox = ({ classes, isChecked, onCheckUncheck, description }) => (
  <div className={classes.container}>
    <CheckBox isChecked={isChecked} onCheckUncheck={onCheckUncheck} />
    <div className={classes.descriptionBox}>
      {ReactHtmlParser(description)}
    </div>
  </div>
);

const styles = {
  container: {
    display: 'flex',
  },
  descriptionBox: {
    marginLeft: 8,
    backgroundColor: COLORS.fwdGrey,
    borderRadius: 8,
    height: 65,
    overflowY: 'scroll',
    fontSize: fontSizes.contentSmall,
    padding: '8px 4px 8px 10px', // For right side, expect scroll bar created some padding already
    lineHeight: '21px',
    '& a': {
      color: COLORS.fwdOrange,
      textDecoration: 'none',
      fontWeight: 700
    }
    // '*::-webkit-scrollbar': {
    //   borderRadius: 8,
    // },
    // '*::-webkit-scrollbar-track': {
    //   borderRadius: 8,
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   borderRadius: 8,
    //   backgroundClip: 'padding-box',
    // },
  },
};

export default withStyles(styles)(AcknowledgeCheckBox);
