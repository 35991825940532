import React from "react";
import iconSrcArrowLeftOrange from '../../../assets/general/arrow-left-orange.svg';
import iconSrcArrowRightOrange from '../../../assets/general/arrow-right-orange.svg';
import iconSrcdownloadWhite from '../../../assets/general/download-white.svg';
import iconSrcCalendarOrange from '../../../assets/general/calendar-orange.svg';
import iconRefreshOrange from '../../../assets/general/refresh.svg';

const commonIconTypes = {
  arrowLeftOrange: 'arrowLeftOrange',
  arrowRightOrange: 'arrowRightOrange',
  downloadWhite: 'downloadWhite',
  calendarOrange: 'calendarOrange',
  refreshOrange: 'refreshOrange'
};

const commonIconImageSrc = {
  [commonIconTypes.arrowLeftOrange]: iconSrcArrowLeftOrange,
  [commonIconTypes.arrowRightOrange]: iconSrcArrowRightOrange,
  [commonIconTypes.downloadWhite]: iconSrcdownloadWhite,
  [commonIconTypes.calendarOrange]: iconSrcCalendarOrange,
  [commonIconTypes.refreshOrange]: iconRefreshOrange
};

const CommonIcon = ({ size = 24, type }) => (
  <img style={{ height: size, width: size, objectFit: 'contain' }} src={commonIconImageSrc[type]}></img>
);

export {
  commonIconTypes,
};

export default CommonIcon;
