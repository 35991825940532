/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSurveyRecord = /* GraphQL */ `
  query GetSurveyRecord($id: ID!) {
    getSurveyRecord(id: $id) {
      id
      surveyKey
      answers
      clientId
      isCustomer
      policy
      productCode
      policyIssueDate
      createdAt
      updatedAt
    }
  }
`;
export const listSurveyRecords = /* GraphQL */ `
  query ListSurveyRecords(
    $filter: ModelSurveyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyKey
        answers
        clientId
        isCustomer
        policy
        productCode
        policyIssueDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      clientId
      coupon
      isUsed
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        coupon
        isUsed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersForSurvey = /* GraphQL */ `
  query AnswersForSurvey(
    $surveyKey: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersForSurvey(
      surveyKey: $surveyKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyKey
        question
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const surveyRecordsForSurveyKey = /* GraphQL */ `
  query SurveyRecordsForSurveyKey(
    $surveyKey: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyRecordsForSurveyKey(
      surveyKey: $surveyKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyKey
        answers
        clientId
        isCustomer
        policy
        productCode
        policyIssueDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const couponByClientId = /* GraphQL */ `
  query CouponByClientId(
    $clientId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    couponByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        coupon
        isUsed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const couponByCoupon = /* GraphQL */ `
  query CouponByCoupon(
    $coupon: String
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    couponByCoupon(
      coupon: $coupon
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        coupon
        isUsed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
