import { makeStyles } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { COLORS } from "../../../constant/colors";
import familyStoryImage from "../../../assets/landing/familyStory.png";
import { MaxWidth } from "../../../constant/stylesSize";

const useStyles = makeStyles((theme) => ({
  MindStrengthForFamilyContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 15,
    paddingBottom: 40,
    background: COLORS.white,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      alignItems: "center",
    },
  },
  contentContainer: {
    maxWidth: MaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "flex-start",
    },
  },
  caption: {
    color: COLORS.fwdDarkGreen,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: "39px",
    marginBottom: 15,
    paddingTop: 20,
  },
  details: {
    color: COLORS.fwdDarkGreen,
    fontSize: 16,
    fontWeight: 450,
    lineHeight: "24px",
  },
  card: {
    background: COLORS.fwdOrange20,
    borderRadius: 16,
    marginTop: 10,
    padding: "30px 20px 0px 20px",

    fontSize: 20,
    lineHeight: "25px",
    fontWeight: 700,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginTop: 40,
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
  },
  cardSignature: {
    marginTop: 20,
  },
  cardCaption: {
    padding: 10,
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  cardImageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    [theme.breakpoints.up("md")]: {
      width: "25%",
      marginRight: 30,
    },
  },
  cardImage: {
    width: "100%",
  },
}));

function MindStrengthForFamilyContainer() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.MindStrengthForFamilyContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.caption}>
            <Trans
              i18nKey={"productLanding.mindStrengthForFamily.forFamilyCaption"}
            ></Trans>
          </div>
          <div className={classes.details}>
            <Trans
              i18nKey={"productLanding.mindStrengthForFamily.forFamilyDetails"}
            ></Trans>
          </div>
          <div className={classes.card}>
            <div className={classes.cardCaption}>
              <Trans
                i18nKey={"productLanding.mindStrengthForFamily.cardStatement"}
              ></Trans>

              <div className={classes.cardSignature}>
                {" "}
                <Trans
                  i18nKey={"productLanding.mindStrengthForFamily.cardSignature"}
                ></Trans>
              </div>
            </div>
            <div className={classes.cardImageContainer}>
              <img
                src={familyStoryImage}
                className={classes.cardImage}
                alt={"Card"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MindStrengthForFamilyContainer;
