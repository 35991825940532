import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FWDHeaderContainer from "./FWDHeaderContainer";

const useStyles = makeStyles((theme) => ({
  frame: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > *": {
      flexShrink: 0,
    },
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const NavigationFrame = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.frame}>
      {/* <Header /> */}
      <FWDHeaderContainer />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default NavigationFrame;
