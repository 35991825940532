import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import SectionHeader, { sectionHeaderSizes, sectionHeaderAlignDirections } from '../../components/core-elements/SectionHeader';
import FormTextField from '../../components/core-elements/FormTextField';
import RevealButton from '../../components/core-elements/RevealButton';
import Spacer from '../../components/core-elements/Spacer';
import ActionIcon from '../../components/core-elements/ActionIcon';
import AcknowledgeCheckBox from '../../components/core-elements/AcknowledgeCheckBox';
import tfIconSrc from '../../../assets/thoughtfull/TF_Trademark_Stacked.png';
import daIconSrc from '../../../assets/doctorAnywhere/DoctorAnywhere_Icon.png';
import { validateInputName, validateInputEmail, validateInputMobile } from '../../../util/form-validation';
import { saveLead } from "../../../api/lead-form";
import { useTranslation } from 'react-i18next';
import { triggerGAOnLeadFormSubmitClick } from '../../../util/ga';
import { leadFormType } from '../../../constant/redeem';
import AppIcon from '../../components/core-elements/AppIcon';
import { COLORS } from '../../../constant/colors';
import TagManager from 'react-gtm-module';

const countryCode = process.env.REACT_APP_LEAD_FORM_API_COUNTRY_CALLING_CODE;

const NewCustomerLeadForm = ({ classes, onGoBack, onSubmitSuccess, isFullPage = false, leadFormFor = leadFormType.leadFormForTF, isEmbedComponent = false, componentName = '', title, buttonTitle, providedBySection = () => null }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [mobileErrorMessage, setMobileErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedTnc1, setIsCheckedTnc1] = useState(false);
  const { t, i18n } = useTranslation();
  const currentBu = process.env.REACT_APP_BU ?? "hk";


  const clearErrorMessages = () => {
    setNameErrorMessage('');
    setEmailErrorMessage('');
    setMobileErrorMessage('');
  };

  const onSubmit = async () => {
    setIsLoading(true);
    clearErrorMessages();

    // Validate inputs
    let isValidationSuccess = true;
    if (!validateInputName(name)) { isValidationSuccess = false; setNameErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidName`)); }
    if (!validateInputEmail(email)) { isValidationSuccess = false; setEmailErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidEmail`)); }
    if (!validateInputMobile(mobile)) { isValidationSuccess = false; setMobileErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidMobile`)); }
    if (!isValidationSuccess) { setIsLoading(false); return; }

    // Send to Lead Form API
    triggerGAOnLeadFormSubmitClick({ language: i18n.language, componentName: isFullPage ? 'lead form full page' : isEmbedComponent ? componentName == "" ? 'lead from from result card' : componentName : 'lead form popup' });
    try {
      const response = await saveLead({
        firstName: name,
        lastName: currentBu === 'ph' ? 'from MS' : '',
        email,
        countryCode,
        cellPhone: mobile,
        language: i18n.language
      })

      if (!response.success || !response.result === true) {

        throw new Error('Internal Error Occur');
      }
      onSubmitSuccess();
    } catch (err) {
      // TODO: Confirm the error behavior
      console.debug(err);
      setNameErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidName`));
      setEmailErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidEmail`));
      setMobileErrorMessage(t(`eTriageResultPage.${leadFormFor}.enterValidMobile`));
      setIsLoading(false);
    }

  };

  const DAIntroOnClicked = () => {
    const doctorAnywhereIntroLink = `${process.env.REACT_APP_DA_INTRO_LINK}`;

    const tagResultEventArgs = {
      dataLayer: {
        event: "buttonClick",
        buttonTitle: "doctor anywhere",
        pageUrl: window.location.href,
        language: i18n.language,
        componentName: `redeem lead form ${componentName}`,
        destinationURL: doctorAnywhereIntroLink,
      },
    };

    TagManager.dataLayer(tagResultEventArgs);

    window.open(doctorAnywhereIntroLink);

  };

  const TFIntroOnClicked = () => {
    const thoughtFulleIntroLink = `${process.env.REACT_APP_TF_INTRO_LINK}`;
    const tagResultEventArgs = {
      dataLayer: {
        event: "buttonClick",
        buttonTitle: "thoughtfull intro",
        pageUrl: window.location.href,
        language: i18n.language,
        componentName: `Thoughtfull coach card intro`,
        destinationURL: thoughtFulleIntroLink,
      },
    };
    TagManager.dataLayer(tagResultEventArgs);
    window.open(thoughtFulleIntroLink);
  };

  const renderProvidedBySection = () => {
    if (providedBySection() !== null) {
      return (<>{providedBySection()}</>);
    } else {
      return (
        <>
          {leadFormFor === leadFormType.leadFormForDA && (
            <div className={classes.providedByDARow}>
              <AppIcon imageSrc={daIconSrc} /><div className={classes.providedByDATitle}>{t(`eTriageResultPage.${leadFormFor}.providedBy`)}</div>
              <div className={classes.providedByDAButton} onClick={() => {
                DAIntroOnClicked();
              }}>{t(`eTriageResultPage.${leadFormFor}.doctorAnywhere`)}</div>
            </div>
          )}
          {leadFormFor === leadFormType.leadFormForTF && (
            <div className={classes.providedByDARow}>
              <AppIcon imageSrc={tfIconSrc} /><div className={classes.providedByTitle}>{t(`eTriageResultPage.${leadFormFor}.providedBy`)}</div>

              <div
                className={classes.providedByButton}
                onClick={() => {
                  TFIntroOnClicked();
                }}>
                {t(`eTriageResultPage.${leadFormFor}.thoughtfull`)}
              </div>
              <div className={classes.providedByTitle}>{t(`eTriageResultPage.${leadFormFor}.providedBy_2`)}</div>
            </div>
          )}</>
      )
    }



  }


  return (
    <div className={classes.container}>
      {
        (!!onGoBack && !isFullPage && !isEmbedComponent) &&
        <div className={classes.backIconContainer}>
          <ActionIcon type='arrowLeft' onClick={onGoBack} />
        </div>
      }
      <SectionHeader
        text={title && title !=
          "" ? title : t(`eTriageResultPage.${leadFormFor}.title`)}
        size={(isFullPage || isEmbedComponent) ? sectionHeaderSizes.large : sectionHeaderSizes.medium}
        alignDirection={(isFullPage || isEmbedComponent) ? sectionHeaderAlignDirections.center : sectionHeaderAlignDirections.left}
        onGoBack={!!(isFullPage || isEmbedComponent) && onGoBack}
      />

      {renderProvidedBySection()}
      <div className={classes.formContainer}>
        <Spacer y={24} />
        <FormTextField title={t(`eTriageResultPage.${leadFormFor}.name`)} type={'text'} value={name} onValueChange={(value) => { setName(value); setNameErrorMessage('') }} isError={!!nameErrorMessage} errorMessage={nameErrorMessage} />
        <Spacer y={16} />
        <FormTextField title={t(`eTriageResultPage.${leadFormFor}.email`)} type={'email'} value={email} onValueChange={(value) => { setEmail(value); setEmailErrorMessage('') }} isError={!!emailErrorMessage} errorMessage={emailErrorMessage} />
        <Spacer y={16} />
        <FormTextField title={`${t(`eTriageResultPage.${leadFormFor}.mobileNumber`)} (${countryCode})`} type={'tel'} value={mobile} onValueChange={(value) => { setMobile(value); setMobileErrorMessage('') }} inputPrefix={countryCode} isError={!!mobileErrorMessage} errorMessage={mobileErrorMessage} />
        <Spacer y={16} />
        <AcknowledgeCheckBox isChecked={isCheckedTnc1} onCheckUncheck={setIsCheckedTnc1} description={t(`eTriageResultPage.${leadFormFor}.consent.${currentBu}`)} />
        <Spacer y={16} />
        <RevealButton title={buttonTitle ? buttonTitle : t(`eTriageResultPage.${leadFormFor}.getTrialCode`)} isDisabled={!isCheckedTnc1 || isLoading} onClick={onSubmit} />
      </div>
    </div>
  )
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 16,
  },
  formContainer: {
    width: '100%',
    maxWidth: 344,
    "& a": { color: COLORS.fwdOrange }
  },
  providedByDARow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 450,
    color: COLORS.fwdDardGreen,
    lineHeight: '24px'
  },
  providedByDATitle: {
    fontSize: 13,
    fontWeight: 450,
    color: COLORS.fwdDardGreen,
    lineHeight: '24px',
    marginLeft: '5px'

  },
  providedByDAButton: {
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 700,
    color: COLORS.fwdOrange,
    lineHeight: '24px',
    marginLeft: '5px'

  },

  providedByTitle: {
    fontSize: 16,
    fontWeight: 450,
    color: COLORS.fwdDardGreen,
    lineHeight: "24px",
    marginLeft: "5px",
  },
  providedByButton: {
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.fwdOrange,
    lineHeight: "24px",
    marginLeft: "5px",
  },
};

export default withStyles(styles)(NewCustomerLeadForm);
